import BaseService from "services/base";
import _ from "lodash";

class ItemSearchService extends BaseService {

    /**
     * sample query object
     */

    sampleQuery = () => {

        var query = {
            info: "", //search text name, barcode
        }

    }


    /**
     * 
     * @param {object refer sampleQuery} query 
     * @param {*} sort 
     * @param {*} option 
     * @returns 
     */
    search = async (query = {}, sort = {}, option = { group_item: false, paging: { page: 1, page_size: 10 } }) => {

        var data = await this._api.post("item/search", { query, sort, option });
        return data;
    };



}

export default ItemSearchService;
