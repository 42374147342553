import { Button, Checkbox, Col, Divider, Flex, Form, Input, Row, Select, Space, Switch } from "antd";
import { useEffect, useState } from "react";
import * as exportConstants from "./constants";
import CustomFieldService from "services/custom_field";
import { useLoading } from "context/loading";
import { useAppContext } from "context/app";

function SettingsAndSelect({ items, form, selectedExportType, selectedLayoutType }) {
  const { showLoading, dismissLoading } = useLoading();
  const { customFieldList } = useAppContext();

  const fields_export = Form.useWatch("fields_export", { form });
  const has_title_page = Form.useWatch("has_title_page", { form });
  const has_summary_page = Form.useWatch("has_summary_page", { form });
  const has_include_labels = Form.useWatch("has_include_labels", { form });

  const [lstFieldsExport, setLstFieldsExport] = useState([]);
  const [selectedAll, setSelectedAll] = useState(true);
  const [hasFolder, setHasFolder] = useState(false);

  const lstFields = [
    { value: "id", label: "ID" },
    { value: "name", label: "Name" },
    { value: "photos", label: "Photos" },
    { value: "quantity", label: "Quantity" },
    { value: "min_level", label: "Min Level" },
    { value: "price", label: "Price" },
    { value: "total_value", label: "Total Value" },
    { value: "tags", label: "Tags" },
    { value: "notes", label: "Notes" },
  ];

  useEffect(() => {
    // Kiểm tra trong items có item nào là folder, nếu có hiển thị options Include Sub-Folders and Items
    const hasFolder = items.some((item) => item.entity_type === "folder");
    setHasFolder(hasFolder);

    // Lấy tất cả custom field của người dùng
    async function fetchCustomFields() {
      showLoading();

      customFieldList.forEach((item) => {
        lstFields.push({ value: item.id, label: item.name });
      });

      setLstFieldsExport(lstFields);
      const allValues = lstFields.map((item) => item.value);
      form.setFieldsValue({ fields_export: allValues });
      dismissLoading();
    }

    fetchCustomFields();
  }, [items]);

  useEffect(() => {
    if (fields_export?.length < lstFieldsExport?.length) {
      setSelectedAll(false);
    } else {
      setSelectedAll(true);
    }
  }, [fields_export]);

  const showSettingsExport = () => {
    const exportSettingsTypeMap = {
      [exportConstants.SPREADSHEET]: (
        <>
          {hasFolder && (
            <Form.Item label="Include Sub-Folders and Items" name="has_subFolders_items" valuePropName="checked" layout="horizontal" initialValue={true}>
              <Switch />
            </Form.Item>
          )}

          <Form.Item label="Export as .xlsx (Excel)" name="has_export_excel" valuePropName="checked" layout="horizontal" initialValue={true}>
            <Switch />
          </Form.Item>
        </>
      ),
      [exportConstants.INTEGRATIONS]: "",
      [exportConstants.PAGE]: {
        [exportConstants.PAGE_ALBUM]: (
          <>
            {hasFolder && (
              <Form.Item label="Include Sub-Folders and Items" name="has_subFolders_items" valuePropName="checked" layout="horizontal" initialValue={true}>
                <Switch />
              </Form.Item>
            )}
            <Form.Item label="Add Title Page" name="has_title_page" valuePropName="checked" layout="horizontal">
              <Switch />
            </Form.Item>
            <Form.Item label="Title Page" name="title_page" style={{ marginTop: "-24px" }} hidden={!has_title_page}>
              <Input placeholder="Eg. First Report, Sample, etc" maxLength={100} />
            </Form.Item>

            <Form.Item label="Add Summary Page" name="has_summary_page" valuePropName="checked" layout="horizontal">
              <Switch />
            </Form.Item>
            <Form.Item label="Summary Page" name="summary_page" valuePropName="checked" style={{ marginTop: "-24px" }} hidden={!has_summary_page}>
              <Select
                defaultValue={"summarize_name"}
                options={[
                  {
                    label: "Summarize by Name",
                    value: "summarize_name",
                  },
                  {
                    label: "Summarize by Sortly ID",
                    value: "summarize_id",
                  },
                ]}
              />
            </Form.Item>

            <Form.Item label="Include Labels" name="has_include_labels" valuePropName="checked" layout="horizontal">
              <Switch />
            </Form.Item>
          </>
        ),
        [exportConstants.PAGE_LIST]: (
          <>
            {hasFolder && (
              <Form.Item label="Include Sub-Folders and Items" name="has_subFolders_items" valuePropName="checked" layout="horizontal" initialValue={true}>
                <Switch />
              </Form.Item>
            )}
            <Form.Item label="Add Title Page" name="has_title_page" valuePropName="checked" layout="horizontal">
              <Switch />
            </Form.Item>
            <Form.Item label="Title Page" name="title_page" style={{ marginTop: "-24px" }} hidden={!has_title_page}>
              <Input placeholder="Eg. First Report, Sample, etc" maxLength={100} />
            </Form.Item>

            <Form.Item label="Add Summary Page" name="has_summary_page" valuePropName="checked" layout="horizontal">
              <Switch />
            </Form.Item>
            <Form.Item label="Summary Page" name="summary_page" valuePropName="checked" style={{ marginTop: "-24px" }} hidden={!has_summary_page}>
              <Select
                defaultValue={"summarize_name"}
                options={[
                  {
                    label: "Summarize by Name",
                    value: "summarize_name",
                  },
                  {
                    label: "Summarize by Sortly ID",
                    value: "summarize_id",
                  },
                ]}
              />
            </Form.Item>

            <Form.Item label="Include Labels" name="has_include_labels" valuePropName="checked" layout="horizontal">
              <Switch />
            </Form.Item>

            <Form.Item label="Include Photos" name="has_include_photo" valuePropName="checked" layout="horizontal">
              <Switch />
            </Form.Item>
          </>
        ),
        [exportConstants.PAGE_COMPACT]: (
          <>
            {hasFolder && (
              <Form.Item label="Include Sub-Folders and Items" name="has_subFolders_items" valuePropName="checked" layout="horizontal" initialValue={true}>
                <Switch />
              </Form.Item>
            )}
            <Form.Item label="Add Title Page" name="has_title_page" valuePropName="checked" layout="horizontal">
              <Switch />
            </Form.Item>
            <Form.Item label="Title Page" name="title_page" style={{ marginTop: "-24px" }} hidden={!has_title_page}>
              <Input placeholder="Eg. First Report, Sample, etc" maxLength={100} />
            </Form.Item>

            <Form.Item label="Add Summary Page" name="has_summary_page" valuePropName="checked" layout="horizontal">
              <Switch />
            </Form.Item>
            <Form.Item label="Summary Page" name="summary_page" valuePropName="checked" style={{ marginTop: "-24px" }} hidden={!has_summary_page}>
              <Select
                defaultValue={"summarize_name"}
                options={[
                  {
                    label: "Summarize by Name",
                    value: "summarize_name",
                  },
                  {
                    label: "Summarize by Sortly ID",
                    value: "summarize_id",
                  },
                ]}
              />
            </Form.Item>

            <Form.Item label="Include Labels (will replace photo)" name="has_include_labels" valuePropName="checked" layout="horizontal">
              <Switch />
            </Form.Item>

            <Form.Item label="Include Photos" name="has_include_photo" valuePropName="checked" layout="horizontal">
              <Switch />
            </Form.Item>
          </>
        ),
      },
    };

    const exportSettingsComponent = exportSettingsTypeMap[selectedExportType.type];

    if (selectedExportType.type === exportConstants.PAGE) {
      return exportSettingsComponent[selectedLayoutType.type];
    }

    return exportSettingsComponent;
  };

  const handleSelectAll = () => {
    if (selectedAll) {
      form.setFieldsValue({ fields_export: [] });
    } else {
      const allValues = lstFieldsExport.map((item) => item.value);
      form.setFieldsValue({ fields_export: allValues });
    }
    setSelectedAll(!selectedAll);
  };

  const showExportSettings = () => {};

  return (
    <Form form={form} style={{ flex: 1, maxHeight: "568px", overflowY: "scroll" }} layout="vertical">
      <Flex vertical>
        <p>EXPORT SETTINGS</p>
        {showSettingsExport()}

        <Divider />

        <Flex justify="space-between" align="center">
          <p>SELECT FIELDS TO EXPORT</p>
          <Button type="link" onClick={handleSelectAll}>
            {selectedAll ? "Unselect All" : "Select All"}
          </Button>
        </Flex>

        <Form.Item name="fields_export" label="">
          <Checkbox.Group>
            <Row>
              {lstFieldsExport.map((item) => (
                <Col span={12} key={item.value}>
                  <Checkbox
                    value={item.value}
                    style={{
                      lineHeight: "32px",
                    }}
                  >
                    {item.label}
                  </Checkbox>
                </Col>
              ))}
            </Row>
          </Checkbox.Group>
        </Form.Item>
      </Flex>
    </Form>
  );
}

export default SettingsAndSelect;
