import { Button, Card, Divider, Flex, Form, Image, Input, message, Space, theme } from "antd";
import { useForm, useWatch } from "antd/es/form/Form";
import { EyeTwoTone, EyeInvisibleOutlined, GoogleOutlined } from "@ant-design/icons";
import useNotification from "antd/es/notification/useNotification";
import { useLoading } from "context/loading";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useMemo, useState, useCallback } from "react";
import AppLoader from "components/app-loader"
import PublicService from "services/public";
import AuthService from "services/auth";
import { useAuth } from "context/auth"
import { debounce } from "lodash"
const publicService = new PublicService();
const authService = new AuthService();

function Login() {
    const { token } = theme.useToken();
    const [form] = useForm();
    const [form2] = useForm();
    const { success, error } = useNotification();
    const { showLoading, dismissLoading } = useLoading();
    const [loading, setLoading] = useState(true);
    const [loginError, setLoginError] = useState(null);

    const navigate = useNavigate();
    const [workSpace, setWorkSpace] = useState(null);
    const { login } = useAuth();


    const load = async (id) => {
        if (!id) {
            setWorkSpace(null);
            return;
        }
        showLoading();
        var wp = await publicService.getWorkSpace(id);
        setWorkSpace(wp)
        if (!wp) {
            setLoginError({ message: "Workspace not found" })
        } else {
            setLoginError(null)
        }
        dismissLoading();

    }



    const handleContinue = () => {
        form.submit();
    };

    const onFinish = async () => {
        setLoginError(null);
        showLoading("Login");
        const data = { ...form.getFieldsValue() };

        var user = await authService.workSpaceLogin({ w_id: workSpace.id, user_name: data.user_name, pass: data.pass });
        if (user) {
            login(user);
        } else {
            setLoginError({ message: "Incorrect username or password" })


        }
        dismissLoading();

        // try {
        //     //const result = userService.add(data);
        //     // if (result) {
        //     //     success(`User ${data.name} has been successfully added`);
        //     // }
        // } catch (err) {
        //     error("Failed user. Please try again later.");
        // } finally {
        //     form.resetFields();
        //     dismissLoading();
        // }
    };

    const selectWp = async () => {
        const data = { ...form2.getFieldsValue() };
        await load(data.w_id);
    }



    return (
        <Card style={{ width: "500px", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
            <Flex vertical gap={14}>
                <Space direction="vertical" align="center">
                    <img width={200} style={{ borderRadius: "8px" }} src="https://storage.googleapis.com/cubebio/data/4aa8c176-4c15-4a94-b6bf-83ac71462f10.png" />
                    {workSpace &&

                        <span style={{ fontSize: token.fontSizeHeading3, fontWeight: "500" }}>
                            Welcome to {workSpace.name}
                        </span>
                    }

                    <span>Log in to your workspace</span>
                </Space>

                {!workSpace &&


                    <Form layout="vertical" form={form2} initialValues={{}} onFinish={onFinish}>

                        <Form.Item
                            label="Workspace ID"
                            name="w_id"
                            rules={[
                                {
                                    required: true,
                                }

                            ]}
                        >
                            <Input placeholder="Workspace ID" ></Input>
                        </Form.Item>




                        <Space direction="vertical" style={{ width: "100%" }} size={"large"}>
                            {loginError &&
                                <div style={{ float: "right" }}>
                                    <span style={{ color: token.colorErrorText }}>
                                        {loginError.message}
                                    </span>
                                </div>
                            }

                            <Button type="primary" style={{ width: "100%" }} onClick={() => selectWp()}>
                                Continute
                            </Button>


                        </Space>
                    </Form>



                }


                <div style={{ display: workSpace ? "block" : "none" }}>

                    <Form layout="vertical" form={form} initialValues={{}} onFinish={onFinish}>



                        <Form.Item
                            label="User Name"
                            name="user_name"
                            rules={[
                                {
                                    required: true,

                                },
                            ]}
                        >
                            <Input placeholder="User Name"></Input>
                        </Form.Item>

                        <Form.Item
                            label="Password"
                            name="pass"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input.Password placeholder="Password" iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
                        </Form.Item>

                        <Space direction="vertical" style={{ width: "100%" }} size={"large"}>
                            {loginError &&
                                <div style={{ float: "right" }}>
                                    <span style={{ color: token.colorErrorText }}>
                                        {loginError.message}
                                    </span>
                                </div>
                            }

                            <div style={{ float: "right" }}>
                                <Button size="small" type="link" onClick={() => navigate("/reset-password")}>
                                    Forgot password?
                                </Button>
                            </div>

                            <Button type="primary" style={{ width: "100%" }} onClick={() => handleContinue()}>
                                Login
                            </Button>


                        </Space>
                    </Form>
                </div>

            </Flex>
        </Card>
    );
}

export default Login;
