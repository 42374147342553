import { useEffect, memo, useState } from "react";
import { Button, Flex, Space, Divider, Input, Switch, theme, Card, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useItemsScreenContext } from "pages/items";
import { useAppContext } from "context/app";
import { CustomButtonLink } from "components/Buttons";
const { useToken } = theme;

const ItemSelectedMenuBar = () => {
    const { token } = useToken();
    const { selectedItems, setSelectedItems, currentFolder } = useItemsScreenContext();
    const { showExportModal, showCreateLabelModal, showUpdateMultiQuantityModal, showMoveMultiQuantityModal, showMoveFolderModal, showSetTagModal, showAlertModal, showDeleteModal, showCloneModal } =
        useAppContext();

    const [disableUpdateQuantity, setDisableUpdateQuantity] = useState(false);
    const [disableClone, setDisableClone] = useState(false);

    useEffect(() => {
        if (selectedItems?.length > 0) {
            const hasFolder = selectedItems.some((item) => item.entity_type === "folder");
            setDisableUpdateQuantity(hasFolder);

            const hasVariants = selectedItems.some((item) => item.has_variants === true);
            setDisableClone(hasVariants);
        }
    }, [selectedItems]);

    if (selectedItems.length <= 0) return null;

    const options = [
        {
            key: "add_tag",
            label: <div>Add tags</div>,
        },
        {
            key: "remove_tag",
            label: <div>Remove tags</div>,
        },
    ];

    const menuClick = ({ item, key, keyPath, domEvent }) => {
        console.log({ item, key, keyPath });

        switch (key) {
            case "add_tag":
                showSetTagModal(selectedItems, currentFolder, "Add");
                break;
            case "remove_tag":
                showSetTagModal(selectedItems, currentFolder, "Remove");
                break;
            default:
                break;
        }
    };

    return (
        <div style={{ padding: token.padding, backgroundColor: token.colorBgContainer, marginBottom: token.marginLG, borderRadius: token.borderRadius, display: "flex" }}>
            <div style={{ flexGrow: 1 }}>
                {selectedItems.length} Items selected
                <CustomButtonLink type="link" onClick={(e) => setSelectedItems([])}>
                    Clear
                </CustomButtonLink>
            </div>
            <div>
                <Space style={{ color: token.colorPrimary }}>
                    <CustomButtonLink type="link">Edit</CustomButtonLink>
                    <CustomButtonLink
                        type="link"
                        onClick={() => {
                            showUpdateMultiQuantityModal(selectedItems, currentFolder);
                        }}
                        disabled={disableUpdateQuantity}
                    >
                        Update Quantity
                    </CustomButtonLink>
                    <CustomButtonLink
                        type="link"
                        onClick={() => {
                            const checkHasFolder = selectedItems.some((item) => item.entity_type === "folder");
                            if (checkHasFolder) {
                                showMoveFolderModal(selectedItems, currentFolder);
                            } else {
                                showMoveMultiQuantityModal(selectedItems, currentFolder);
                            }
                        }}
                    >
                        Move
                    </CustomButtonLink>
                    <CustomButtonLink
                        type="link"
                        onClick={() => {
                            showExportModal(selectedItems, currentFolder);
                        }}
                    >
                        Export
                    </CustomButtonLink>
                    <CustomButtonLink
                        type="link"
                        onClick={() => {
                            showCreateLabelModal(selectedItems, currentFolder);
                        }}
                    >
                        Create Label
                    </CustomButtonLink>
                    <Dropdown
                        menu={{
                            items: options,
                            onClick: menuClick,
                        }}
                    >
                        <CustomButtonLink type="link">
                            <span>Tags</span>
                            <DownOutlined style={{ marginTop: "4px" }} />
                        </CustomButtonLink>
                    </Dropdown>
                    <CustomButtonLink
                        type="link"
                        onClick={() => {
                            showAlertModal(selectedItems, currentFolder);
                        }}
                        disabled={disableUpdateQuantity}
                    >
                        Alert
                    </CustomButtonLink>
                    <CustomButtonLink
                        type="link"
                        onClick={() => {
                            showCloneModal(selectedItems, currentFolder);
                        }}
                        disabled={disableClone}
                    >
                        Clone
                    </CustomButtonLink>
                    <CustomButtonLink
                        onClick={() => {
                            showDeleteModal(selectedItems, currentFolder);
                        }}
                    >
                        Delete
                    </CustomButtonLink>
                </Space>
            </div>
        </div>
    );
};

export default memo(ItemSelectedMenuBar);
