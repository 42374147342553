import { Modal, Divider, Form, Flex, Select, Image, InputNumber, Input } from "antd";
import { PlusSquareOutlined, MinusSquareOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { SaveButton } from "components/Buttons";
import ItemService from "services/item";
import { useLoading } from "context/loading";
import { useAppContext } from "context/app";
import InputPlusMinusNumber from "components/Input/InputPlusMinusNumber";
import { getQuantityItem } from "common/get_quantity_item";
import { useNotification } from "context/notification";
import * as Number  from "common/number_format"

const itemService = new ItemService();

function UpdateSingleQuantityModal({ item, folderId, visible, onClose }) {
  const { showLoading, dismissLoading } = useLoading();
  const { success, error } = useNotification();
  const [lstReason, setLstReason] = useState([
    { value: "consumed", label: "Consumed" },
    { value: "damaged", label: "Damaged" },
    { value: "restocked", label: "Restocked" },
    { value: "returned", label: "Returned" },
    { value: "sold", label: "Sold" },
    { value: "stocktake", label: "Stocktake" },
    { value: "stolen", label: "Stolen" },
  ]);
  const [disableSave, setDisableSave] = useState(true);
  const [form] = Form.useForm();
  const { eventBus } = useAppContext();

  useEffect(() => {
    if (item) {
      form.setFieldsValue({
        quantity: 0,
        new_quantity: getQuantityItem(item, folderId),
        reason: lstReason[0].value,
        notes: "",
      });
    }
  }, [item]);

  const handleChangeQuantity = async (value) => {
    await checkErrors();
    if (value === undefined || value === null) return;

    const updatedNewQuantity = getQuantityItem(item, folderId) + value;
    form.setFieldsValue({ new_quantity: updatedNewQuantity });

    if (updatedNewQuantity < 0) {
      form.setFields([
        {
          name: "new_quantity",
          errors: ["New Quantity can't be below 0"],
        },
      ]);
      setDisableSave(true);
    } else {
      setDisableSave(false);
    }
  };

  const handleChangeNewQuantity = async (value) => {
    await checkErrors();
    if (value === undefined || value === null) return;

    const updatedQuantity = value - getQuantityItem(item, folderId);
    form.setFieldsValue({ quantity: updatedQuantity });
  };

  const checkErrors = async () => {
    try {
      await form.validateFields();
      setDisableSave(false);
    } catch (error) {
      setDisableSave(true);
    }
  };

  const handleSave = async () => {
    showLoading();
    const data = { item_id: item.id, folder_id: folderId || "", notes: form.getFieldValue("notes"), quantity: form.getFieldValue("quantity"), reason_id: form.getFieldValue("reason") };

    try {
      const result = await itemService.updateQuantity(data);
      if (result) {
        item.stock.forEach((stock) => {
          if(stock.folder_id === folderId) {
            stock.quantity += result.quantity;
            stock.total_amount = item.price * stock.quantity;
          }
        })

        eventBus.emit("item_changed", { folder_id: folderId });

        success(`Update quantity has been successfully updated`);
      }
    } catch (err) {
      error("Failed to update quantity. Please try again later.");
      console.log(err);
    } finally {
      resetValue();
      dismissLoading();
    }
  };

  const resetValue = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      width={500}
      title={<p style={{ fontSize: "18px", textAlign: "center" }}>Update Quantity</p>}
      open={visible}
      onCancel={resetValue}
      maskClosable={false}
      footer={<SaveButton disabled={disableSave} onClick={handleSave} />}
    >
      {item && (
        <div style={{ display: "flex" }}>
          <div style={{ display: "flex", gap: "20px" }}>
            <Image
              style={{ width: "100px", height: "100px", objectFit: "contain", borderRadius: "8px" }}
              src={item.photos ? item.photos[0].url : "https://storage.googleapis.com/cubebio/default/nodata_2.svg"}
            />
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
              <span style={{ fontSize: "20px", fontWeight: 500 }}>{item.name}</span>
              <span>{item.variant_title}</span>
              <span>Quantity: {Number.formatInt(getQuantityItem(item, folderId))}</span>
            </div>
          </div>
        </div>
      )}

      <Divider />

      <Form form={form} layout="vertical">
        <Form.Item name="quantity" label="Quantity" rules={[{ required: true, message: "Quantity can't be blank" }]}>
          <InputPlusMinusNumber value={form.getFieldValue("quantity")} onChange={handleChangeQuantity} />
        </Form.Item>

        <Form.Item
          name="new_quantity"
          label="New Quantity"
          rules={[
            { required: true, message: "New Quantity can't be blank" },
            { type: "number", min: 0, message: "New Quantity can't be below 0" },
          ]}
        >
          <InputNumber style={{ width: "100%" }} onChange={handleChangeNewQuantity} />
        </Form.Item>

        <Divider />

        <Form.Item name="reason" label="Reason (optional)">
          <Select showSearch optionFilterProp="label" options={lstReason} />
        </Form.Item>

        <Form.Item name="notes" label="Transaction Note (optional)">
          <Input.TextArea style={{ width: "100%" }} />
        </Form.Item>

        <Divider />
      </Form>
    </Modal>
  );
}

export default UpdateSingleQuantityModal;
