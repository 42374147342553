import BaseService from "services/base";
import _ from "lodash";

class AuthService extends BaseService {
  workSpaceLogin = async ({ w_id, user_name, pass }) => {
    var data = await this._api.post("workspace/login", { w_id, user_name, pass });
    return data;
  }


  userLogin = async ({ email, pass }) => {
    var data = await this._api.post("login", { email, pass });
    return data;
  }


  loginWithGoogle = async (token) => {
    var data = await this._api.post("loginwithgoogle", { token });
    return data;
  }

}

export default AuthService;
