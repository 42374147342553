import { useEffect, memo, useState } from "react";
import { Button, Flex, Space, Divider, Col, Row, Card, Pagination, Spin } from "antd";
import Item from "./Item";
import _ from "lodash"
import CreateLabelsModal from "components/Label/CreateLabelsModal";

const Gird = ({ data = [], loading }) => {
    if (loading) {
        return (
            <div style={{ padding: 18, justifyContent: "center" }}>


                <Spin size="large" />

            </div>

        )
    }

    return (

        <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
            {
                data.map(item => {
                    return (

                        <Item data={item}></Item>

                    )
                })
            }
        </div>


    )
}




export default memo(Gird);