import { Flex, Image, Space, theme } from "antd";
import { FileExcelOutlined, FilePdfOutlined, FileAddOutlined, FileTextOutlined } from "@ant-design/icons";
import SpreadsheetSVG from "./svgs/AlbumSVG";
import CompactSVG from "./svgs/CompactSVG";
import ListSVG from "./svgs/ListSVG";
import { useState } from "react";
import * as exportConstants from "./constants";

function ExportAndLayoutType({ lstExportType, selectedExportType, setSelectedExportType, lstLayoutType, selectedLayoutType, setSelectedLayoutType }) {
    const { useToken } = theme;
    const { token } = useToken();

    const showIcon = (type) => {
        switch (type) {
            case exportConstants.SPREADSHEET:
                return <FileExcelOutlined />;
            case exportConstants.PAGE:
                return <FilePdfOutlined />;
            case exportConstants.INTEGRATIONS:
                return <FileAddOutlined />;
        }
    };

    const showSVG = (type) => {
        switch (type) {
            case exportConstants.PAGE_ALBUM:
                return <SpreadsheetSVG />;
            case exportConstants.PAGE_LIST:
                return <ListSVG />;
            case exportConstants.PAGE_COMPACT:
                return <CompactSVG />;
        }
    };

    return (
        <Flex vertical>
            <p>EXPORT TYPE</p>

            {lstExportType.map((item) => (
                <Flex
                    key={item.id}
                    align="center"
                    gap={12}
                    style={{
                        boxShadow: token.boxShadow,
                        padding: `${token.paddingSM}px ${token.padding}px`,
                        borderRadius: token.borderRadiusLG,
                        cursor: "pointer",
                        border: item === selectedExportType ? `1px solid ${token.colorPrimary}` : "none",
                        marginBottom: token.margin,
                    }}
                    onClick={() => {
                        setSelectedExportType(item);
                    }}
                >
                    <Space style={{ fontSize: "35px" }}>{showIcon(item.type)}</Space>
                    <Space direction="vertical" size={0}>
                        <span style={{ fontWeight: 500, fontSize: token.fontSizeHeading5 }}>{item.name}</span>
                        <span>{item.file}</span>
                    </Space>
                </Flex>
            ))}

            {selectedExportType.type === exportConstants.PAGE && (
                <>
                    <p>LAYOUT TYPE</p>
                    <Flex justify="space-between">
                        {lstLayoutType.map((item) => (
                            <Space
                                direction="vertical"
                                align="center"
                                style={{
                                    boxShadow: token.boxShadow,
                                    padding: `${token.paddingSM}px ${token.marginXL}px`,
                                    borderRadius: token.borderRadiusLG,
                                    cursor: "pointer",
                                    border: item === selectedLayoutType ? `1px solid ${token.colorPrimary}` : "",
                                    marginBottom: token.margin,
                                }}
                                onClick={() => {
                                    setSelectedLayoutType(item);
                                }}
                            >
                                {showSVG(item.type)}
                                <span>{item.name}</span>
                            </Space>
                        ))}
                    </Flex>
                </>
            )}
        </Flex>
    );
}

export default ExportAndLayoutType;
