import { ColorPicker, Flex, Form, Input, Modal, Select, Space } from "antd";
import { EyeTwoTone, EyeInvisibleOutlined } from "@ant-design/icons";
import React, { useState, useMemo, useEffect, useContext } from "react";
import { useNotification } from "context/notification";
import { useLoading } from "context/loading";
import UserService from "services/user";

const userService = new UserService();

function AddOrEditUserModal({ visible, value = null, onChange, onClose, typeEdit = null }) {
    const [form] = Form.useForm();
    const { success, error } = useNotification();
    const { showLoading, dismissLoading } = useLoading();

    useEffect(() => {
        if (!visible) return;

        if (value) {
            form.setFieldsValue({
                id: value.id,
                first_name: value.first_name,
                last_name: value.last_name,
                user_name: value.work_spaces[0].user_name,
                email: value.work_spaces[0].email,
                role: value.work_spaces[0].role,
                pass: value.work_spaces[0].pass,
                re_pass: value.work_spaces[0].pass,
            });
        } else {
            form.resetFields();
        }
    }, [visible]);

    const onOk = () => {
        form.submit();
    };

    const onFinish = async () => {
        showLoading("Saving");
        const data = { ...form.getFieldsValue() };
        try {
            if (value) {
                if (typeEdit === "update_role") {
                    const result = await userService.updateRole({ id: data.id, role: data.role });
                    console.log({ result });
                    if (result) {
                        console.log("chạy vào đây");

                        success(`Update role has been successfully`);
                        onChange();
                        form.resetFields();
                    }
                }
            } else {
                const result = await userService.add(data);

                if (result) {
                    success(`User ${data.name} has been successfully added`);
                    onChange();
                    form.resetFields();
                }
            }
        } catch (err) {
            error(err);
        } finally {
            dismissLoading();
        }
    };

    return (
        <Modal
            title={<p style={{ fontSize: "18px", textAlign: "center" }}>{value ? "Update User" : "Add User"}</p>}
            open={visible}
            onOk={onOk}
            onCancel={onClose}
            maskClosable={false}
            okText={value ? "Update" : "Add"}
        >
            <Form layout="vertical" form={form} initialValues={{}} onFinish={onFinish}>
                <Form.Item name="id" hidden>
                    <Input />
                </Form.Item>

                {typeEdit !== "update_role" && (
                    <>
                        <Space direction="vertical" size="large" style={{ width: "100%" }}>
                            <Flex justify="space-between" gap={12}>
                                <Form.Item
                                    label="First Name"
                                    name="first_name"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                    style={{ flex: 1 }}
                                >
                                    <Input placeholder="First Name"></Input>
                                </Form.Item>

                                <Form.Item
                                    label="Last Name"
                                    name="last_name"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                    style={{ flex: 1 }}
                                >
                                    <Input placeholder="Last Name"></Input>
                                </Form.Item>
                            </Flex>

                            <Form.Item
                                label="User Name"
                                name="user_name"
                                rules={[
                                    { required: true },
                                    {
                                        pattern: /^[a-zA-Z0-9_]+$/,
                                        message: "Username must not contain special characters or spaces",
                                    },
                                ]}
                            >
                                <Input placeholder="User Name" />
                            </Form.Item>

                            <Form.Item label="Email" name="email" rules={[{ required: true, type: "email" }]}>
                                <Input placeholder="Email" />
                            </Form.Item>

                            <Form.Item label="Password" name="pass" rules={[{ required: true }]}>
                                <Input.Password placeholder="Password" iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
                            </Form.Item>

                            <Form.Item
                                label="RePassword"
                                name="re_pass"
                                dependencies={["pass"]}
                                rules={[
                                    { required: true },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue("pass") === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error("The two passwords do not match!"));
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password placeholder="RePassword" iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
                            </Form.Item>
                        </Space>
                    </>
                )}

                <Form.Item
                    label="Role"
                    name="role"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select
                        placeholder="Select a role"
                        optionFilterProp="label"
                        options={[
                            {
                                value: "admin",
                                label: "Admin",
                            },
                            {
                                value: "user",
                                label: "User",
                            },
                        ]}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default AddOrEditUserModal;
