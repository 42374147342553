function getQuantityItem(item, folderId) {
    for (const stock of item.stock) {
        if (stock.folder_id === folderId) {
            return stock.quantity;
        }
    }

    return 0;
}

export { getQuantityItem };
