import { Modal, Divider, Form, Flex, Select, Image, InputNumber, Input, Button, Switch, Popconfirm, theme } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { SaveButton } from "components/Buttons";
import ItemService from "services/item";
import { useLoading } from "context/loading";
import { useAppContext } from "context/app";
import { useNotification } from "context/notification";
import * as Picker from "components/pickers";
import { getQuantityItem } from "common/get_quantity_item";
import FolderService from "services/folder";
import ItemAlertService from "services/item/ItemAlert";

const itemService = new ItemAlertService();
const itemAlertService = new ItemAlertService();

function LowStockAlertModal({ items, folderId, visible, onClose }) {
    const [lstItems, setLstItems] = useState([]);
    const { showLoading, dismissLoading } = useLoading();
    const { success, error } = useNotification();
    const [form] = Form.useForm();
    const { eventBus } = useAppContext();
    const [disableSave, setDisableSave] = useState(true);

    const { useToken } = theme;
    const { token } = useToken();

    useEffect(() => {
        if (items) {
            const handleItems = async () => {
                showLoading();

                const itemPromises = items.map(async (item) => {
                    if (item.entity_type === "item" && item.has_variants) {
                        const folderStockPromises = item.folder_stock.variant_stock.map(async (variantStock) => {
                            const result = await itemService.getByID(variantStock.item_id);
                            result.entity_type = "item";
                            return result;
                        });

                        return await Promise.all(folderStockPromises);
                    }

                    return item;
                });

                const result = await Promise.all(itemPromises);
                const lstItems = result.flat();

                setLstItems(lstItems);

                form.setFieldsValue({
                    notes: "",
                    to_folder_id: folderId,
                });

                dismissLoading();
            };

            handleItems();
        }
    }, [items]);

    const handleChangeMinLevel = (value) => {
        if (value === null || value === undefined) {
            setDisableSave(true);
        } else {
            setDisableSave(false);
        }
    };
    const handleSave = async () => {
        showLoading();

        try {
            let data;
            let result;
            const min_level = form.getFieldValue("min_level");

            if (lstItems.length === 1) {
                data = {
                    item_id: lstItems[0].id,
                    folder_id: folderId,
                    quantity: min_level,
                };
                result = await itemAlertService.setLowStockAlert(data);
            } else {
                data = lstItems.map((item) => ({
                    item_id: item.id,
                    alert: {
                        folder_id: folderId,
                        quantity: min_level,
                    },
                }));
                result = await itemAlertService.bulkSetLowStockAlert(data);
            }

            if (result) {
                if (lstItems.length === 1) {
                    const findItem = items[0].quantity_alerts.find((x) => x.folder_id === folderId);
                    findItem.quantity = result.alert.quantity;
                }

                eventBus.emit("item_changed", { folder_id: folderId });
                success(`Alert has been successfully updated`);
            }
        } catch (err) {
            error(`Failed to alert. Please try again later.`);
            console.log(err);
        } finally {
            resetValue();
            dismissLoading();
        }
    };

    const confirm = async (e) => {
        showLoading();

        try {
            let data;
            const min_level = form.getFieldValue("min_level");
            if (lstItems.length === 1) {
                data = {
                    item_id: lstItems[0].id,
                    folder_id: folderId,
                };
                await itemAlertService.removeLowStockAlert(data);

                const result = items[0].quantity_alerts.find((x) => x.folder_id === folderId);
                result.quantity = 0;
            } else {
                data = lstItems.map((item) => ({
                    item_id: item.id,
                    folder_id: folderId,
                }));
                await itemAlertService.bulkRemoveLowStockAlert(data);
            }

            eventBus.emit("item_changed", { folder_id: folderId });
            success(`Remove Alert has been successfully`);
        } catch (err) {
            error(`Failed to remove alert. Please try again later.`);
            console.log(err);
        } finally {
            resetValue();
            dismissLoading();
        }
    };

    const cancel = (e) => {};

    const resetValue = () => {
        form.resetFields();
        setLstItems([]);
        setDisableSave(true);
        onClose();
    };

    const renderListItem = () => {
        const itemCounts = lstItems.reduce(
            (counts, item) => {
                if (item.entity_type === "item") {
                    counts.items += 1;
                } else if (item.entity_type === "folder") {
                    counts.folders += 1;
                }
                return counts;
            },
            { items: 0, folders: 0 }
        );

        const { items, folders } = itemCounts;

        if (items > 0 && folders > 0) {
            return (
                <>
                    <span style={{ display: "block", fontSize: token.fontSizeHeading5, fontWeight: 500 }}>
                        {folders} Folders & {items} Items
                    </span>
                    <Divider style={{ margin: `${token.marginXS}px 0` }} />
                </>
            );
        }

        if (items > 0) {
            return (
                <>
                    <span style={{ display: "block", fontSize: token.fontSizeHeading5, fontWeight: 500 }}>{items} Items</span>
                    <Divider style={{ margin: `${token.marginXS}px 0` }} />
                </>
            );
        }

        if (folders > 0) {
            return (
                <>
                    <span style={{ display: "block", fontSize: token.fontSizeHeading5, fontWeight: 500 }}>{folders} Folders</span>
                    <Divider style={{ margin: `${token.marginXS}px 0` }} />
                </>
            );
        }
    };

    return (
        <Modal
            width={500}
            title={<p style={{ fontSize: token.fontSizeHeading4, textAlign: "center" }}>Set Low Stock Alert</p>}
            open={visible}
            onCancel={resetValue}
            maskClosable={false}
            footer={
                <Flex justify="space-between">
                    <Popconfirm title="Remove alert" description="Are you sure to delete this alert?" onConfirm={confirm} onCancel={cancel} okText="Yes" cancelText="No">
                        <Button type="link" danger style={{ paddingLeft: 0 }}>
                            Remove alert
                        </Button>
                    </Popconfirm>
                    <SaveButton onClick={handleSave} disabled={disableSave} />
                </Flex>
            }
        >
            <Divider style={{ margin: `${token.marginXS}px 0` }} />

            {lstItems.length > 1 && renderListItem()}

            <Form form={form} layout="vertical">
                <Form.Item label="Min Level" name="min_level" rules={[{ required: true, message: "Min Level can't be blank" }]}>
                    <InputNumber placeholder="Min Level" min={1} style={{ width: "100%" }} onChange={handleChangeMinLevel} />
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default LowStockAlertModal;
