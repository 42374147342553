import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
dayjs.extend(localizedFormat);

function format(value) {
    return dayjs(value).format("L");
}

function formatDateTime(value) {
    return dayjs(value).format("YYYY-MM-DD HH:mm:ss");
}

export { format, formatDateTime };
