import { Button, Dropdown, theme } from "antd";
import * as Icon from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "context/app";
import { useItemsScreenContext } from "pages/items";
import { CustomButtonLink } from "components/Buttons";

function ListOptionFolder({ data }) {
    const { token } = theme.useToken();
    const navigate = useNavigate();
    const { showExportModal, showCreateLabelModal, showMoveFolderModal, showDeleteModal, showCloneModal } = useAppContext();
    const { currentFolder, currentFolderPath } = useItemsScreenContext();

    const iconStyle = { fontSize: 18 };
    const options = [
        {
            key: "move",
            label: <div>Move to Folder</div>,
            icon: <Icon.FolderOutlined style={{ ...iconStyle }}></Icon.FolderOutlined>,
        },

        {
            key: "create_label",
            label: <div>Create Label</div>,
            icon: <Icon.BarcodeOutlined style={{ ...iconStyle }}></Icon.BarcodeOutlined>,
        },

        {
            key: "export",
            label: <div>Export</div>,
            icon: <Icon.UploadOutlined style={{ ...iconStyle }}></Icon.UploadOutlined>,
        },

        {
            key: "transaction",
            label: <div>Transactions</div>,
            icon: <Icon.AccountBookOutlined style={{ ...iconStyle }}></Icon.AccountBookOutlined>,
        },

        {
            type: "divider", // Must have
        },

        {
            key: "clone",
            label: <div>Clone</div>,
            icon: <Icon.CopyOutlined style={{ ...iconStyle }}></Icon.CopyOutlined>,
        },
        {
            key: "edit",
            label: <div>Edit</div>,
            icon: <Icon.EditOutlined style={{ ...iconStyle }}></Icon.EditOutlined>,
        },
        {
            key: "delete",
            label: <div>Delete</div>,
            icon: <Icon.DeleteOutlined style={{ ...iconStyle }}></Icon.DeleteOutlined>,
        },
    ].filter((option) => {
        if (data?.is_root) {
            return !["move", "clone", "edit", "delete"].includes(option.key);
        }

        return true;
    });

    const menuClick = ({ item, key, keyPath, domEvent }) => {
        console.log({ item, key, keyPath });

        switch (key) {
            case "edit":
                navigate(`/folder-edit/${data.id}`);
                break;
            case "create_label":
                const listItemsLabel = [{ ...data, entity_type: "folder" }];
                showCreateLabelModal(listItemsLabel, currentFolder);
                break;
            case "export":
                const listItemsExport = [{ ...data, entity_type: "folder" }];
                showExportModal(listItemsExport, currentFolder);
                break;
            case "move":
                const listItemsMove = [{ ...data, entity_type: "folder" }];
                showMoveFolderModal(listItemsMove, currentFolder);
                break;
            case "delete":
                const listItemsDelete = [{ ...data, entity_type: "folder" }];
                showDeleteModal(listItemsDelete, currentFolder);
                break;
            case "clone":
                const listItemsClone = [{ ...data, entity_type: "folder" }];
                showCloneModal(listItemsClone, currentFolder);
                break;
            default:
                break;
        }
    };

    return (
        <Dropdown
            menu={{
                items: options,
                onClick: menuClick,
            }}
        >
            <CustomButtonLink type="link" style={{ padding: 0 }}>
                <Icon.MoreOutlined />
            </CustomButtonLink>
        </Dropdown>
    );
}

export default ListOptionFolder;
