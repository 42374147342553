import React, { useState } from "react";
import { Button, theme } from "antd";
import * as Icon from "@ant-design/icons";
import "./index.css";

const CustomButtonLink = ({ children, style, disabled = false, ...props }) => {
    const { token } = theme.useToken();

    return (
        <Button type="link" className={!disabled && "custom_button_link"} {...props} style={{ color: !disabled && token.colorPrimary, ...style }} disabled={disabled}>
            {children}
        </Button>
    );
};

const SaveButton = (props) => {
    return (
        <Button {...props} type="primary">
            <Icon.SaveFilled></Icon.SaveFilled> Save
        </Button>
    );
};

const CancelButton = (props) => {
    return (
        <Button {...props} type="default">
            Cancel
        </Button>
    );
};

export { SaveButton, CancelButton, CustomButtonLink };
