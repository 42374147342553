import BaseService from "services/base";
import _ from "lodash";

class SignUpService extends BaseService {
  emailSignUp = async ({ email, first_name, last_name, phone, pass }) => {
    var data = await this._api.post("signup", { email, first_name, last_name, phone, pass });
    return data;
  };


  googleSignUp = async ({ access_token, first_name, last_name, phone }) => {
    var data = await this._api.post("signupwithgoogle", {access_token, first_name, last_name, phone});
    return data;
  };





}

export default SignUpService;
