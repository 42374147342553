import { DatePicker, Flex, Radio } from "antd";
import { useEffect } from "react";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

function FilterDate({ value, onChange }) {
    const radioMap = {
        today: [dayjs().startOf("day"), dayjs().endOf("day")],
        yesterday: [dayjs().subtract(1, "day").startOf("day"), dayjs().subtract(1, "day").endOf("day")],
        this_week: [dayjs().startOf("week"), dayjs().endOf("week")],
        last_week: [dayjs().subtract(1, "week").startOf("week"), dayjs().subtract(1, "week").endOf("week")],
        this_month: [dayjs().startOf("month"), dayjs().endOf("month")],
        last_month: [dayjs().subtract(1, "month").startOf("month"), dayjs().subtract(1, "month").endOf("month")],
        last_3_months: [dayjs().subtract(3, "month").startOf("month"), dayjs().endOf("month")],
        last_year: [dayjs().subtract(1, "year").startOf("year"), dayjs().endOf("year")],
    };

    useEffect(() => {
        if (value?.radioChecked && value.radioChecked !== "custom") {
            const selectedDates = radioMap[value.radioChecked];
            handleFilterDate(selectedDates, value.radioChecked);
        }
    }, [value?.radioChecked]);

    const onChangeRadio = (e) => {
        const radio = e.target.value;
        if (radio !== "custom") {
            handleFilterDate(radioMap[radio], radio);
        } else {
            onChange({ ...value, radioChecked: radio });
        }
    };

    const onChangeRangeDate = (dates) => {
        handleFilterDate(dates, "custom");
    };

    const handleFilterDate = (dates, radio) => {
        if (dates && dates.length === 2) {
            onChange({
                time: {
                    gte: dates[0].format("YYYY-MM-DD"), // greater than or equal to
                    lte: dates[1].format("YYYY-MM-DD"), // less than or equal to
                },
                radioChecked: radio,
            });
        }
    };

    const radioMenu = [
        { value: "today", title: "Today" },
        { value: "yesterday", title: "Yesterday" },
        { value: "this_week", title: "This Week" },
        { value: "last_week", title: "Last Week" },
        { value: "this_month", title: "This Month" },
        { value: "last_month", title: "Last Month" },
        { value: "last_3_months", title: "Last 3 Months" },
        { value: "last_year", title: "Last Year" },
        { value: "custom", title: "Custom" },
    ];

    return (
        <Flex gap={6} vertical>
            <Radio.Group onChange={onChangeRadio} value={value?.radioChecked} style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
                {radioMenu.map((radio) => (
                    <Radio key={radio.value} value={radio.value}>
                        {radio.title}
                    </Radio>
                ))}
            </Radio.Group>

            <DatePicker.RangePicker allowClear={false} value={value?.time ? [dayjs(value.time.gte), dayjs(value.time.lte)] : null} onChange={onChangeRangeDate} />
        </Flex>
    );
}

export default FilterDate;
