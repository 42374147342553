import { SearchOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, Input } from "antd";
import { useRef, useState } from "react";

function Search({ placeholder, onChange }) {
    const [textSearch, setTextSearch] = useState("");

    return (
        <div style={{ display: "flex" }}>
            <div style={{ display: "flex", position: "relative", flex: 1 }}>
                <Input
                    placeholder={placeholder}
                    value={textSearch}
                    onChange={(e) => {
                        setTextSearch(e.target.value);
                        onChange(e.target.value);
                    }}
                    addonBefore={<SearchOutlined />}
                />
            </div>
        </div>
    );
}

export default Search;
