import BaseService from "services/base";
import _ from "lodash";

class AttributeService extends BaseService {
  get = async ({ info } = {}) => {
    var data = await this._api.get("attribute/list", { info });
    return data;
  };

  add = async ({ name, options }) => {
    var data = await this._api.post("attribute/add", { name, options });
    return data;
  };

  remove = async (id) => {
    var data = await this._api.post("attribute/delete", { id: id });
    return true;
  };

  update = async ({ id, name, options }) => {
    var data = await this._api.post("attribute/update", { id, name, options });
    return data;
  };
}

export default AttributeService;
