import { ColorPicker, Flex, Form, Input, Modal, Select, Space, theme } from "antd";
import { EyeTwoTone, EyeInvisibleOutlined } from "@ant-design/icons";
import React, { useState, useMemo, useEffect, useContext } from "react";
import { useNotification } from "context/notification";
import { useLoading } from "context/loading";
import AttributeService from "services/attribute";

const attributeService = new AttributeService();

function AddOrEditAttributeModal({ visible, value = null, onChange, onClose, onChangeItemAddAttribute = null }) {
    const { useToken } = theme;
    const { token } = useToken();

    const [form] = Form.useForm();
    const { success, error } = useNotification();
    const { showLoading, dismissLoading } = useLoading();

    useEffect(() => {
        if (!visible) return;
        if (value) {
            form.setFieldsValue(value);
        } else {
            form.resetFields();
        }
    }, [visible]);

    const onOk = () => {
        form.submit();
    };

    const onFinish = async () => {
        showLoading("Saving");
        const data = { ...form.getFieldsValue() };
        let result;

        try {
            if (value) {
                result = await attributeService.update(data);

                if (result) {
                    success(`Update attribute has been successfully`);
                }
            } else {
                result = await attributeService.add(data);

                if (result) {
                    success(`Attribute ${data.name} has been successfully added`);
                }
            }
        } catch (err) {
            error("Failed user. Please try again later.");
        } finally {
            // onChangeItemAddAttribute là trường hợp thêm attribute ở item
            if (onChangeItemAddAttribute) {
                onChangeItemAddAttribute(result);
            } else {
                onChange();
            }
            form.resetFields();
            dismissLoading();
        }
    };

    return (
        <Modal
            title={<p style={{ fontSize: token.fontSizeHeading4, textAlign: "center" }}>{value ? "Update Attribute" : "Add Attribute"}</p>}
            open={visible}
            onOk={onOk}
            onCancel={onClose}
            maskClosable={false}
            okText={value ? "Update" : "Add"}
        >
            <Form layout="vertical" form={form} initialValues={{}} onFinish={onFinish}>
                <Form.Item name="id" hidden>
                    <Input></Input>
                </Form.Item>

                <Form.Item
                    label="Name"
                    name="name"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input placeholder="name"></Input>
                </Form.Item>

                <Form.Item
                    label="Options"
                    name="options"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select allowClear="true" mode="tags" placeholder="options" tokenSeparators={[","]} />
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default AddOrEditAttributeModal;
