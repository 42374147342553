import { Button, Card, Divider, Flex, Form, Image, Input, Space, theme } from "antd";
import { useForm } from "antd/es/form/Form";
import { EyeTwoTone, EyeInvisibleOutlined, GoogleOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import useNotification from "antd/es/notification/useNotification";
import { useLoading } from "context/loading";
import { useNavigate } from "react-router-dom";

function ResetPassword() {
    const { token } = theme.useToken();
    const [form] = useForm();
    const { success, error } = useNotification();
    const { showLoading, dismissLoading } = useLoading();
    const navigate = useNavigate();

    const handleContinue = () => {
        form.submit();
    };

    const onFinish = async () => {
        showLoading("Saving");
        const data = { ...form.getFieldsValue() };

        try {
            //const result = userService.add(data);
            // if (result) {
            //     success(`User ${data.name} has been successfully added`);
            // }
        } catch (err) {
            error("Failed user. Please try again later.");
        } finally {
            form.resetFields();
            dismissLoading();
        }
    };

    return (
        <Card style={{ width: "500px", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
            <Flex vertical gap={14}>
                <Button icon={<ArrowLeftOutlined />} onClick={() => navigate("/login")} />

                <Space direction="vertical" style={{ textAlign: "center" }}>
                    <img width={200} style={{ borderRadius: "8px" }} src="https://storage.googleapis.com/cubebio/data/4aa8c176-4c15-4a94-b6bf-83ac71462f10.png" />
                    <span style={{ fontSize: token.fontSizeHeading3, fontWeight: "500" }}>Reset password!</span>
                    <span>Enter the email address associated with your account and we’ll send you a link to reset your password.</span>
                </Space>
                <Form layout="vertical" form={form} initialValues={{}} onFinish={onFinish}>
                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                            {
                                required: true,
                                type: "email",
                            },
                        ]}
                    >
                        <Input placeholder="Email"></Input>
                    </Form.Item>

                    <Space direction="vertical" style={{ width: "100%" }} size={"large"}>
                        <Button type="primary" style={{ width: "100%" }} onClick={() => handleContinue()}>
                            Send Link
                        </Button>

                        <Flex align="center" justify="center">
                            <span>New here?</span>
                            <Button type="link" onClick={() => navigate("/register")}>
                                Create an account
                            </Button>
                        </Flex>
                    </Space>
                </Form>
            </Flex>
        </Card>
    );
}

export default ResetPassword;
