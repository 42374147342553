import { memo, useCallback, useEffect, useState } from "react";
import { Button, Card, ColorPicker, Divider, Flex, Popconfirm, Space, Table } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import _ from "lodash";
import AttributeService from "services/attribute";
import AddOrEditAttributeModal from "components/Attribute/AddOrEditAttributeModal";
import Search from "components/Search/Search";

const attributeService = new AttributeService();

function AttributePage() {
    const [loading, setLoading] = useState(false);
    const [info, setInfo] = useState();
    const [lstAttribute, setLstAttribute] = useState([]);
    const [visible, setVisible] = useState(false);
    const [valueEditAttribute, setValueEditAttribute] = useState(null);

    useEffect(() => {
        fetchListAttribute();
    }, [info]);

    const fetchListAttribute = async () => {
        setLoading(true);

        const result = await attributeService.get({ info: info });
        setLstAttribute(result);

        setLoading(false);
    };

    const handleSearch = useCallback(
        _.debounce(async (textSearch) => {
            setInfo(textSearch);
        }, 500),
        []
    );

    const onChange = async () => {
        fetchListAttribute();
        resetValue();
    };

    const confirm = async (data) => {
        await attributeService.remove(data.id);
        fetchListAttribute();
    };

    const columns = [
        {
            title: "NAME",
            dataIndex: "name",
            width: "45%",
        },
        {
            title: "OPTIONS",
            dataIndex: "options",
            width: "45%",
            render: (text, record) => <span>{record.options.join(", ")}</span>,
        },
        {
            title: "ACTIONS",
            dataIndex: "action",
            width: "10%",
            render: (text, record) => (
                <>
                    <div>
                        <EditOutlined
                            style={{ cursor: "pointer", marginRight: "16px" }}
                            onClick={() => {
                                setValueEditAttribute(record);
                                setVisible(true);
                            }}
                        />
                        <Popconfirm title="Delete user" description="Are you sure to delete this user ?" onConfirm={() => confirm(record)} okText="Yes" cancelText="No">
                            <DeleteOutlined style={{ cursor: "pointer" }} />
                        </Popconfirm>
                    </div>
                </>
            ),
        },
    ];

    const resetValue = () => {
        setValueEditAttribute(null);
        setVisible(false);
    };

    return (
        <>
            <AddOrEditAttributeModal visible={visible} onClose={() => resetValue()} onChange={onChange} value={valueEditAttribute} />

            <Flex justify="space-between" align="center">
                <h1>Attribute</h1>

                <div>
                    <Button
                        type="primary"
                        onClick={() => {
                            setVisible(true);
                        }}
                    >
                        ADD ATTRIBUTE
                    </Button>
                </div>
            </Flex>

            <Divider />
            <Space direction="vertical" style={{ width: "100%" }}>
                <Card>
                    <div style={{ width: "700px" }}>
                        <Search placeholder={"Search attributes"} onChange={handleSearch} />
                    </div>
                </Card>

                <Card>
                    <Table loading={loading} dataSource={lstAttribute} columns={columns} />
                </Card>
            </Space>
        </>
    );
}

export default memo(AttributePage);
