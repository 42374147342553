import { createContext, useState, useContext, useEffect } from "react";
import { useAuth } from "context/auth";
import { ConfigProvider, theme } from "antd";

const ConfigContext = createContext();

function ConfigUserProvider({ children }) {
    const { user } = useAuth();
    console.log({ user });
    return (
        <ConfigContext.Provider>
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimary: user?.web_setting?.primary_color || "#0284c7",
                        fontFamily: "Inter var,ui-sans-serif,system-ui,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji",
                    },
                    algorithm: user?.web_setting?.darkmode === "on" ? theme.darkAlgorithm : "",
                }}
            >
                {children}
            </ConfigProvider>
        </ConfigContext.Provider>
    );
}

export default ConfigUserProvider;
