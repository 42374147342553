
import BaseService from "services/base";
import _ from "lodash";

class UserSettingService extends BaseService {
    save =async ({key, value})=>{
        var data = await this._api.post("user-setting/save",{key, value});
        return data;
    }
    get =async ({key, value})=>{
        var data = await this._api.get("user-setting/get",{key, value});
        return data;
    }
}


export default UserSettingService;
