import BaseService from "services/base";
import _ from "lodash";

class PublicService extends BaseService {
    getWorkSpace = async (id) => {
        var data = await this._api.post("public/workspace/get", { id: id });
        return data;
    };


}
export default PublicService;
