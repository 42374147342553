import { Button, Checkbox, DatePicker, Input, InputNumber, Select } from "antd";
import { memo, useEffect, useState } from "react";
import * as field_type_const from "components/CustomField/constants";
import { PhoneOutlined, MailOutlined, LinkOutlined, QrcodeOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import MultiDocumentsPicker from "components/pickers/file/MultiDocumentsPicker";
import QrAndBarCode from "./QrAndBarCode";

function InputCustomField({ item, value, onChange }) {
  useEffect(() => {
    onChange(item.default_value || value);
  }, []);

  const showInputByFieldType = (fieldType) => {
    switch (fieldType) {
      case field_type_const.SHORT_TEXT:
        return <Input placeholder={item.placeholder} value={value} onChange={(e) => onChange(e.target.value)} maxLength={190} />;
      case field_type_const.LONG_TEXT:
        return <Input placeholder={item.placeholder} value={value} onChange={(e) => onChange(e.target.value)} maxLength={4000} />;
      case field_type_const.CHECK_BOX:
        return <Checkbox checked={value} onChange={(e) => onChange(e.target.checked)} />;
      case field_type_const.ROUND_NUMBER:
        return (
          <InputNumber
            placeholder={item.placeholder}
            value={value}
            onChange={(e) => onChange(e)}
            onKeyDown={(e) => {
              // Chặn nhập các ký tự không phải số
              if (!/^[0-9]$/.test(e.key) && e.key !== "Backspace" && e.key !== "ArrowLeft" && e.key !== "ArrowRight") {
                e.preventDefault();
              }
            }}
            style={{ width: "100%" }}
            min={0}
          />
        );
      case field_type_const.DECIMAL_NUMBER:
        return (
          <InputNumber
            placeholder={item.placeholder}
            value={value}
            onChange={(e) => onChange(e)}
            onKeyDown={(e) => {
              // Chặn nhập các ký tự không phải số và dấu chấm thập phân
              if (!/^[0-9.]$/.test(e.key) && e.key !== "Backspace" && e.key !== "ArrowLeft" && e.key !== "ArrowRight") {
                e.preventDefault();
              }
            }}
            style={{ width: "100%" }}
            min={0}
            step={0.01}
          />
        );
      case field_type_const.DROPDOWN:
        return (
          <Select placeholder={item.placeholder} value={value} onChange={(e) => onChange(e)}>
            {item.options.map((item) => (
              <Select.Option key={item} value={item}>
                {item}
              </Select.Option>
            ))}
          </Select>
        );
      case field_type_const.PHONE_NUMBER:
        return <Input placeholder={item.placeholder} value={value} onChange={(e) => onChange(e.target.value)} addonAfter={<PhoneOutlined />} />;
      case field_type_const.EMAIL:
        return <Input placeholder={item.placeholder} value={value} onChange={(e) => onChange(e.target.value)} addonAfter={<MailOutlined />} />;
      case field_type_const.LINK:
        return <Input placeholder={item.placeholder} value={value} onChange={(e) => onChange(e.target.value)} addonAfter={<LinkOutlined />} />;
      case field_type_const.DATE:
        return <DatePicker format="DD/MM/YYYY" placeholder={item.placeholder} value={value ? dayjs(value) : null} onChange={(date) => onChange(date)} style={{ width: "100%" }} />;
      case field_type_const.FILE:
        return (
          <>
            <MultiDocumentsPicker value={value} onChange={onChange} />
          </>
        );
      case field_type_const.SCANNER:
        return <QrAndBarCode value={value} onChange={onChange} />;
      default:
        break;
    }
  };

  return showInputByFieldType(item.field_type);
}

export default memo(InputCustomField);
