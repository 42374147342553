import { Row, Table } from "antd";
import { BellFilled, BellOutlined } from "@ant-design/icons";
import { getQuantityItem } from "common/get_quantity_item";
import { getAlertItem } from "common/get_alert_item";
import { getStockItem } from "common/get_stock_item";
import { useAppContext } from "context/app";
import * as Number from "common/number_format";
import { useNavigate } from "react-router-dom";
import ListOptionItem from "pages/items/ListOptionItem";

function ListFolders({ item = {}, lstFolder = [] }) {
    const navigate = useNavigate();

    const columns = [
        {
            title: "Folder Name",
            dataIndex: "name",
            key: "name",
            width: "30%",
            render: (text, record) => {
                return (
                    <span style={{ cursor: "pointer" }} onClick={() => navigate(`/folder/${record.id}`)}>
                        {record.name}
                    </span>
                );
            },
        },
        {
            title: "On Hand Quantity",
            dataIndex: "",
            key: "quantity",
            align: "right",
            width: "20%",
            render: (text, record) => {
                return Number.formatInt(getQuantityItem(item, record.id));
            },
        },
        {
            title: "Total Amount",
            dataIndex: "total_amount",
            key: "total_amount",
            align: "right",
            width: "20%",
            render: (text, record) => {
                const stock = getStockItem(item, record.id);
                return stock ? Number.format(stock.total_amount) : "";
            },
        },
        {
            title: "Min Level",
            dataIndex: "",
            key: "min_level",
            align: "right",
            align: "right",
            width: "20%",
            render: (text, record) => {
                const alert = getAlertItem(item, record.id);
                return alert ? alert.quantity : "";
            },
        },
        // {
        //     title: "Stock Alert",
        //     dataIndex: "",
        //     key: "stock_alert",
        //     width: "10%",
        //     render: (text, record) => {
        //         const alert = getAlertItem(item, record.id);
        //         return <div>{alert ? <BellOutlined /> : <BellFilled />}</div>;
        //     },
        // },
        {
            title: "Actions",
            dataIndex: "",
            key: "action",
            width: "10%",
            render: (text, record) => {
                return <ListOptionItem data={item} folderId={record.id} />;
            },
        },
    ];

    const filteredFolders = lstFolder.filter((x) => {
        return Number.formatInt(getQuantityItem(item, x.id)) > 0;
    });

    return <Table columns={columns} dataSource={filteredFolders}></Table>;
}

export default ListFolders;
