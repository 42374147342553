import BaseService from "services/base";
import _ from "lodash";

class NotificationService extends BaseService {
  list = async () => {
    var data = await this._api.post("notification/list");
    return data;
  }

  read = async (id) => {
    var data = await this._api.post("notification/read", { id: id });
    return data;
  }
  readAll = async (id) => {
    var data = await this._api.post("notification/readall");
    return data;
  }

  countUnread = async (id) => {
    var data = await this._api.post("notification/countunread");
    return data;
  }


  

}

export default NotificationService;



