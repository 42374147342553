import BaseService from "services/base";
import _ from "lodash";

class AccountService extends BaseService {
  me = async () => {
    var data = await this._api.post("account/me",);
    return data;
  }

  update = async ({ first_name, last_name, avatar, web_setting, app_setting }) => {
    var data = await this._api.post("account/update", { first_name, last_name, avatar, web_setting,app_setting });
    return data;
  }

}

export default AccountService;
