import { useEffect, memo, useState } from "react";
import { Button, Flex, Space, Divider, Breadcrumb, Image, Dropdown, Table, theme } from "antd";
import * as Icon from "@ant-design/icons";
import * as Date from "common/date_format";

const RenderCustomField = memo(({ data }) => {
    const { token } = theme.useToken();
    if (data.field_type == "file") {
        return (
            <Space>
                {data.value.map((file) => {
                    return (
                        <a href={file.url} download={file.name} type="application/octet-stream">
                            {file.name}
                        </a>
                    );
                })}
            </Space>
        );
    }

    if (data.field_type == "link") {
        return (
            <Space>
                <a href={data.value} target="_blank">
                    {data.value}
                </a>
            </Space>
        );
    }

    if (data.field_type == "date") {
        return <div>{Date.format(data.value)} </div>;
    }

    if (data.field_type == "check_box") {
        return <div> {data.value ? <Icon.CheckOutlined style={{ color: token.colorPrimary }}></Icon.CheckOutlined> : <></>}</div>;
    }

    return <div>{data.value}</div>;
});

export default RenderCustomField;
