import { Checkbox, DatePicker, Input, InputNumber, Select } from "antd";
import { PhoneOutlined, MailOutlined, LinkOutlined, FileSearchOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

function FilterCustomFields({ value, onChange, data }) {
    const onChangeRangeDate = (dates) => {
        handleFilterDate(dates);
    };

    const handleFilterDate = (dates) => {
        if (dates) {
            onChange({
                gte: dates[0].format("YYYY-MM-DD"), // greater than or equal to
                lte: dates[1].format("YYYY-MM-DD"), // less than or equal to
            });
        }
    };

    const renderFilter = () => {
        const customFieldMap = {
            short_text: <Input value={value} onChange={(e) => onChange(e.target.value)} maxLength={190} placeholder={data.placeholder} style={{ width: "100%" }} />,
            long_text: <Input value={value} onChange={(e) => onChange(e.target.value)}  maxLength={4000} placeholder={data.placeholder} style={{ width: "100%" }} />,
            check_box: <Checkbox checked={value} onChange={(e) => onChange(e.target.checked)} />,
            round_number: (
                <InputNumber
                    placeholder={data.placeholder}
                    value={value}
                    onChange={(e) => onChange(e)}
                    onKeyDown={(e) => {
                        // Chặn nhập các ký tự không phải số
                        if (!/^[0-9]$/.test(e.key) && e.key !== "Backspace" && e.key !== "ArrowLeft" && e.key !== "ArrowRight") {
                            e.preventDefault();
                        }
                    }}
                    style={{ width: "100%" }}
                    min={0}
                />
            ),
            decimal_number: (
                <InputNumber
                    placeholder={data.placeholder}
                    value={value}
                    onChange={(e) => onChange(e)}
                    onKeyDown={(e) => {
                        // Chặn nhập các ký tự không phải số và dấu chấm thập phân
                        if (!/^[0-9.]$/.test(e.key) && e.key !== "Backspace" && e.key !== "ArrowLeft" && e.key !== "ArrowRight") {
                            e.preventDefault();
                        }
                    }}
                    style={{ width: "100%" }}
                    min={0}
                    step={0.01}
                />
            ),
            dropdown: (
                <Select mode="multiple" placeholder={data.placeholder} value={value} onChange={(e) => onChange(e)}>
                    {data.options?.map((item) => (
                        <Select.Option key={item} value={item}>
                            {item}
                        </Select.Option>
                    ))}
                </Select>
            ),
            phone_number: <Input placeholder={data.placeholder} value={value} onChange={(e) => onChange(e.target.value)} addonAfter={<PhoneOutlined />} />,
            email: <Input placeholder={data.placeholder} value={value} onChange={(e) => onChange(e.target.value)} addonAfter={<MailOutlined />} />,
            link: <Input placeholder={data.placeholder} value={value} onChange={(e) => onChange(e.target.value)} addonAfter={<LinkOutlined />} />,
            date: <DatePicker.RangePicker allowClear={false} value={value ? [dayjs(value.gte), dayjs(value.lte)] : null} onChange={onChangeRangeDate} />,
            file: <Input placeholder={data.placeholder} value={value} onChange={(e) => onChange(e.target.value)} addonAfter={<FileSearchOutlined />} />,
        };

        return customFieldMap[data.field_type] || null;
    };

    return renderFilter();
}

export default FilterCustomFields;
