import { useEffect, useState, memo } from "react";
import { Button, Flex, Space, Divider, Col, Row, Card, Pagination, theme, Image, Dropdown, Avatar, Tooltip } from "antd";
import { useAppContext } from "context/app";
import tag from "components/pickers/tag";
import * as Icon from "@ant-design/icons";
const { useToken } = theme;


const Tags = (props) => {

    const tag_ids = props.tag_ids ?? []
    const { token } = useToken();
    const [tags, setTags] = useState([])
    const { tagList } = useAppContext();






    return (


        // <Avatar.Group
        //     max={{
        //         count: 4,
        //         style: { color: '#f56a00', backgroundColor: '#fde3cf' },
        //     }}
        //     size={24}
        //     shape="square"
        // >
        //     {tag_ids.map(id => {
        //         var tag = tagList.find(x => x.id == id);
        //         if (!tag)
        //             return null;
        //         return (
        //             <Tooltip title={tag.title} placement="top">

        //                 <Avatar size={24} style={{ backgroundColor: tag.color }}  gap={8} > {tag.title[0].toUpperCase()}</Avatar>
        //             </Tooltip>
        //         )
        //     })}


        // </Avatar.Group>
        <div>

            <Space wrap={"no-warp"}>


                {tag_ids.map(id => {
                    var tag = tagList.find(x => x.id == id);
                    if (!tag)
                        return null;
                    return (

                        <Tooltip title={tag.title} placement="top">

                            <Tag tag={tag}></Tag>

                        </Tooltip>
                    )
                })}
            </Space>
        </div>

    )
}


const Tag = memo(({ tag }) => {
    return (
        <div style={{ borderColor: tag.color, borderRadius: 4, paddingLeft: 8, paddingRight: 8, borderStyle: "solid", borderWidth: 1, color: tag.color }}>
            <Icon.TagOutlined></Icon.TagOutlined> {tag.title[0].toUpperCase()}
        </div>
    )
})


export default memo(Tags);
{/* <Avatar size={24} style={{ backgroundColor: tag.color }} gap={8} > {tag.title[0].toUpperCase()}</Avatar> */ }