import { memo, useEffect, useState } from "react";
import { Button, Divider, Flex, Input, Switch, Table, Image, Dropdown, Pagination, Card } from "antd";
import * as Icon from "@ant-design/icons";
import * as Number from "common/number_format";
import { getAlertItem } from "common/get_alert_item";
import Tags from "components/tag-list";
import { useAppContext } from "context/app";
import { CustomField } from "pages";
import RenderCustomField from "pages/item-detail/RenderCustomField";
import ItemService from "services/item";
import { useNavigate } from "react-router-dom";

const itemService = new ItemService();

function TableInventory({ visibleColumns, loading, items, paging, setPaging, statistic, setSorts }) {
    const { folderList, customFieldList } = useAppContext();
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        setExpandedRowKeys([]);
    }, [items]);

    const handleExpand = async (expanded, item) => {
        if (expanded && item.children.length === 0) {
            const lstIdChildren = item.stock.variant_stock.map((variantStock) => variantStock.item_id);

            const lstItemChildren = await itemService.getByIDs(lstIdChildren);

            lstItemChildren.forEach((itemChildren) => {
                const stockObject = itemChildren.stock.find((stock) => {
                    if (stock.folder_id == item.stock.folder_id) {
                        return stock;
                    }
                });
                itemChildren.stock = stockObject;
            });

            item.children = lstItemChildren;
        }

        setExpandedRowKeys((prev) => (expanded ? [...prev, item.key] : [...prev.filter((key) => key != item.key)]));
    };

    const renderAllCustomField = () => {
        return customFieldList.map((customField) => ({
            title: customField.name,
            dataIndex: `CustomField_${customField.id}`,
            key: `CustomField_${customField.id}`,
            width: "50px",
            render: (text, record) => {
                const customFieldValue = record.custom_field?.[customField.id];
                if (!customFieldValue) return "";

                const data = {
                    ...customField,
                    value: customFieldValue,
                };
                return <RenderCustomField data={data} />;
            },
        }));
    };

    const handleTableChange = (pagination, filters, sorter) => {
        const { field, order } = sorter;

        const mapSorts = {
            name: "name",
            folder: "stock.folder_id",
            quantity: "stock.quantity",
            price: "price",
            total_amount: "stock.total_amount",
        };

        setSorts(order ? { [mapSorts[field]]: order === "ascend" ? 1 : -1 } : {});
    };

    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            width: "100px",
            fixed: "left",
            render: (text, record) => {
                const img = record?.photos && record.photos.length > 0 ? <Image width={30} src={record.photos[0].url} /> : <Icon.FileImageOutlined style={{ fontSize: "30px" }} />;

                const result = (
                    <Flex gap={10} align="center">
                        {img}
                        <Flex vertical style={{ cursor: "pointer" }} onClick={() => navigate(`/item-detail/${record.id}`)}>
                            <span>{record.name}</span>
                            <span>{record.variant_title}</span>
                        </Flex>
                    </Flex>
                );

                return result;
            },
            sorter: true,
        },
        {
            title: "Folder",
            dataIndex: "folder",
            key: "folder",
            width: "100px",
            fixed: "left",
            render: (text, record) => {
                const folder = folderList.find((folder) => folder.id === record.stock.folder_id);
                return (
                    <div style={{ cursor: "pointer" }} onClick={() => navigate(`/folder/${folder?.id}`)}>
                        {folder?.name}
                    </div>
                );
            },
            sorter: true,
        },
        {
            title: "Quantity",
            dataIndex: "quantity",
            key: "quantity",
            width: "50px",
            align: "right",
            render: (text, record) => <div>{record.stock.quantity}</div>,
            sorter: true,
        },
        {
            title: "Price",
            dataIndex: "price",
            key: "price",
            width: "50px",
            align: "right",
            render: (text, record) => {
                if (record.has_variants) {
                    return <div>-</div>;
                }

                return <div>{Number.format(record.price)}</div>;
            },
            sorter: true,
        },
        {
            title: "Total Amount",
            dataIndex: "total_amount",
            key: "total_amount",
            width: "50px",
            align: "right",
            render: (text, record) => <div>{Number.format(record.stock.total_amount)}</div>,
            sorter: true,
        },
        {
            title: "Tags",
            dataIndex: "tags",
            key: "tags",
            width: "80px",
            render: (text, record) => <Tags tag_ids={record.tags}></Tags>,
        },
        {
            title: "Notes",
            dataIndex: "notes",
            key: "notes",
            width: "80px",
            render: (text, record) => <span style={{ display: "-webkit-box", WebkitBoxOrient: "vertical", WebkitLineClamp: 2, overflow: "hidden", overflowWrap: "anywhere" }}>{text}</span>,
        },
        {
            title: "Lowstock Level ",
            dataIndex: "quantity_alert",
            key: "quantity_alert",
            align: "right",
            width: "50px",
            render: (text, record) => {
                return <span>{record.stock.lowstock_alert ? record.stock.lowstock_alert.quantity : ""}</span>;
            },
        },
        ...renderAllCustomField(),
    ].filter((col) => visibleColumns.includes(col.key));

    return (
        <Card>
            <Table
                loading={loading}
                dataSource={items}
                columns={columns}
                onChange={handleTableChange}
                pagination={{
                    current: paging.page,
                    pageSize: paging.pageSize,
                    total: statistic.rows,
                    onChange: (page, pageSize) => {
                        setPaging({ page, pageSize });
                    },
                    showSizeChanger: true,
                    pageSizeOptions: ["10", "20", "50", "100"],
                }}
                expandable={{
                    expandedRowKeys,
                    onExpand: handleExpand,
                }}
                scroll={{
                    x: visibleColumns.length > 8 ? 2500 : 1300,
                }}
            />
        </Card>
    );
}

export default memo(TableInventory);
