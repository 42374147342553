import React, { useEffect, useState } from "react";
import {
    DesktopOutlined,
    FileOutlined,
    PieChartOutlined,
    TeamOutlined,
    BorderOutlined,
    AppstoreOutlined,
    TagsOutlined,
    AreaChartOutlined,
    SettingOutlined,
    ApartmentOutlined,
    BellOutlined,
} from "@ant-design/icons";
import { Badge, Breadcrumb, Button, Layout, Menu, theme } from "antd";
import PageRoutes from "./PageRoutes";
import { BrowserRouter, useNavigate } from "react-router-dom";
import Account from "./Account";
import _ from "lodash";
import { useAppLayoutContext } from "./index";
import { useAuth } from "context/auth";
import NotificationService from "services/notification";
import { useAppContext } from "context/app";
import "./LeftMenu.css";

const { Header, Content, Footer, Sider } = Layout;
function getItem(label, key, icon, children) {
    return {
        key,
        icon,
        children,
        label,
    };
}

const LeftMenu = () => {
    const { menuCollapsed, setMenuCollapsed } = useAppLayoutContext();

    const navigate = useNavigate();

    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();
    const onClick = ({ item, key, keyPath, selectedKeys, domEvent }) => {
        var path = _.reverse(keyPath).join("/");
        console.log({ path, item, key, keyPath, selectedKeys, domEvent });
        navigate(`/${path}`);
    };

    const { user, logout } = useAuth();

    const { countUnRead } = useAppContext();

    const items = [
        getItem("Dashboard", "dashboard", <PieChartOutlined />),
        getItem("Items", "items-list", <BorderOutlined />),
        getItem("Inventory", "inventory", <AppstoreOutlined />),
        getItem("Folders", "folder", <ApartmentOutlined />),

        // getItem("Tags", "tags", <TagsOutlined />),
        getItem("Reports", "reports", <AreaChartOutlined />, [
            // getItem("Activity History", "activity-history"),
            // getItem("Inventory Summary", "inventory-summary"),
            getItem("Transactions", "transactions"),
            // getItem("Move Summary", "move-summary"),
            // getItem("Report 2", "8"),
        ]),
        getItem("Settings", "setting", <SettingOutlined />, [
            getItem("User Management", "user-management"),
            // getItem("My Profile", "profile"),
            // getItem("Preferences", "preferences"),
            // getItem("User Access Control", "user-access-control"),
            getItem("Custom Fields", "custom-fields"),
            getItem("Attributes", "attributes"),
            // getItem("Create Labels", "create-labels"),
            getItem("Tags", "tags"),
        ]),
        getItem(
            "Notification",
            "notification",
            <div className={menuCollapsed ? "custom-badge2" : "custom-badge"}>
                <Badge size="small" style={{ minWidth: "12px", height: "12px", lineHeight: "inherit" }} count={countUnRead}>
                    <BellOutlined />
                </Badge>
            </div>
        ),
    ];

    return (
        <Sider theme="light" collapsible collapsed={menuCollapsed} onCollapse={(value) => setMenuCollapsed(value)}>
            <div style={{ position: "fixed", width: menuCollapsed ? 80 : 200, transition: `width 0.2s` }}>
                <div className="demo-logo-vertical" />
                <Account menuCollapsed={menuCollapsed}></Account>
                <Menu defaultSelectedKeys={["1"]} mode="inline" items={items} onClick={onClick} />
            </div>
        </Sider>
    );
};

export default LeftMenu;
