import { Modal, Divider, Form, Flex, Select, Image, InputNumber, Input, Button, Switch, theme } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { SaveButton } from "components/Buttons";
import ItemService from "services/item";
import { useLoading } from "context/loading";
import { useAppContext } from "context/app";
import { useNotification } from "context/notification";
import * as Picker from "components/pickers";
import SelectMoveReason from "./SelectMoveReason";
import { getQuantityItem } from "common/get_quantity_item";
import FolderService from "services/folder";

const itemService = new ItemService();
const folderService = new FolderService();

function MoveFoldersModal({ items, folderId, visible, onClose }) {
    const { useToken } = theme;
    const { token } = useToken();

    const [lstItems, setLstItems] = useState([]);
    const { showLoading, dismissLoading } = useLoading();
    const { success, error } = useNotification();
    const [form] = Form.useForm();
    const { eventBus } = useAppContext();
    const [disableSave, setDisableSave] = useState(true);

    const to_folder_id = Form.useWatch("to_folder_id", form);

    useEffect(() => {
        if (items) {
            const handleItems = async () => {
                showLoading();

                const itemPromises = items.map(async (item) => {
                    if (item.entity_type === "item" && item.has_variants) {
                        const folderStockPromises = item.folder_stock.variant_stock.map(async (variantStock) => {
                            const result = await itemService.getByID(variantStock.item_id);
                            result.entity_type = "item";
                            return result;
                        });

                        return await Promise.all(folderStockPromises);
                    }

                    return item;
                });

                const result = await Promise.all(itemPromises);
                const lstItems = result.flat();

                setLstItems(lstItems);

                form.setFieldsValue({
                    notes: "",
                });

                dismissLoading();
            };

            handleItems();
        }
    }, [items]);

    useEffect(() => {
        if (!to_folder_id) {
            setDisableSave(true);
        } else {
            setDisableSave(false);
        }
    }, [to_folder_id]);

    const handleSave = async () => {
        showLoading();

        try {
            const items = lstItems
                .filter((itemFilter) => itemFilter.entity_type === "item")
                .map((item) => ({
                    item_id: item.id,
                    folder_id: folderId,
                    to_folder_id: form.getFieldValue("to_folder_id"),
                    quantity: getQuantityItem(item, folderId),
                    notes: form.getFieldValue("notes"),
                    reason_id: form.getFieldValue("reason"),
                }));

            const folder = lstItems
                .filter((itemFilter) => itemFilter.entity_type === "folder")
                .map((item) => ({
                    folder_id: item.id,
                    to_folder_id: form.getFieldValue("to_folder_id"),
                    notes: form.getFieldValue("notes"),
                    reason_id: form.getFieldValue("reason"),
                }));

            await itemService.bulkMoveQuantity(items);
            await folderService.bulkMove(folder);

            eventBus.emit("item_changed", { folder_id: folderId });
            success(`Move has been successfully updated`);
        } catch (err) {
            error("Failed to move. Please try again later.");
            console.log(err);
        } finally {
            resetValue();
            dismissLoading();
        }
    };

    const resetValue = () => {
        form.resetFields();
        setLstItems([]);
        onClose();
    };

    return (
        <Modal
            width={500}
            title={<p style={{ fontSize: token.fontSizeHeading4, textAlign: "center" }}>Move Folder</p>}
            open={visible}
            onCancel={resetValue}
            maskClosable={false}
            footer={<SaveButton onClick={handleSave} disabled={disableSave} />}
        >
            <Divider style={{ margin: "10px 0" }} />

            <span style={{ display: "block", fontSize: token.fontSizeHeading5, fontWeight: 500 }}>
                {`${lstItems.filter((item) => item.entity_type === "folder").length} Folder ${
                    lstItems.filter((item) => item.entity_type === "item").length > 0 ? `& ${lstItems.filter((item) => item.entity_type === "item").length} Items` : ""
                }`}
            </span>

            <Divider style={{ margin: "10px 0" }} />

            <Form form={form} layout="vertical">
                <Form.Item name="reason" label="Move reason">
                    <SelectMoveReason />
                </Form.Item>

                <Form.Item name="notes" label="Move notes">
                    <Input.TextArea />
                </Form.Item>

                <Divider style={{ margin: "10px 0" }} />

                <Form.Item name="to_folder_id" label="Choose destination folder:">
                    <Picker.Folder style={{ width: "100%" }} lstItemDisable={items}></Picker.Folder>
                </Form.Item>
                <Divider />
            </Form>
        </Modal>
    );
}

export default MoveFoldersModal;
