import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Input, Radio, Row, Col, Checkbox, Space, Divider, Layout, theme, InputNumber, Switch } from "antd";
import * as Icon from "@ant-design/icons";
import * as Picker from "components/pickers";
import * as Buttons from "components/Buttons";
import VariantsPicker from "./variants_picker";
import StickyBar from "components/sticky-bar";
import ItemService from "services/item";
import { useLoading } from "context/loading";
import CustomFieldService from "services/custom_field";
import InputCustomField from "components/CustomField/InputCustomField";
import { useNotification } from "context/notification";
import _ from 'lodash'

const { useToken } = theme;
const service = new ItemService();

const ItemEditor = ({ data }) => {
  const [form] = Form.useForm();
  const { token } = useToken();
  const quantity = Form.useWatch("quantity", { form });
  const price = Form.useWatch("price", { form });
  const has_variants = Form.useWatch("has_variants", { form });
  const has_customField = Form.useWatch("has_customField", { form });
  const total_value = (quantity ?? 0) * (price ?? 0);
  const [step, setStep] = useState(0);
  const itemNameInputRef = useRef();
  const [listCustomField, setListCustomField] = useState([]);

  const { showLoading, dismissLoading } = useLoading();
  const { success, error } = useNotification();
  const [submittable, setSubmittable] = useState(false)

  const values = Form.useWatch([], form);
  const [formErrors, setFormErrors] = useState([]);

  React.useEffect(() => {

    form.setFieldsValue(data);
    console.log("data old",data);

  }, [data])

  React.useEffect(() => {

    form
      .validateFields({ validateOnly: true })
      .then(() => {

        setSubmittable(true);
        setFormErrors([]);
        console.log("ok")
      })
      .catch((error) => {
        console.log("error", error)
        if (error.errorFields && error.errorFields.length > 0) {
          setFormErrors(error.errorFields);
          setSubmittable(false)
        } else {
          setSubmittable(true);
          setFormErrors([]);
        }


      });

  }, [form, values]);








  const onFinish = async (data) => {
    showLoading("Saving");

    if (data.id) {
      var item = await service.updateVariants(data);
      success(`${data.name} has been successfully updated`);
    } 

    dismissLoading();

  };


  const getFieldError = (namePath) => {
    var error = formErrors.find(x => _.isEqual(x.name, namePath));

    return error;
  }

  const getVariantError = () => {
    var error = getFieldError(['variant']);
    if (!error)
      return {};
    var error = {};



    var formVariant = form.getFieldValue(["variant"]);
    // console.log("formVariant",formVariant)
    if (!formVariant)
      formVariant = { attributes: [] }


    if (formVariant.attributes.length == 0) {
      error.title = `Attribute can't be blank`;

    } else {

      var options = [];
      for (let index = 0; index < formVariant.attributes.length; index++) {
        const element = formVariant.attributes[index];
        if (element.options.length == 0) {

          error.title = ``;
          options.push('error');
        } else {
          options.push('none');
        }

      }
      error.options = options;


    }
    console.log("error nè", error)
    return error;

  }


  const variantValidate = (_, value) => {
    try {



      var formVariant = form.getFieldValue(["variant"]);
      if (!formVariant)
        formVariant = { attributes: [] }


      if (formVariant.attributes.length == 0) {
        return Promise.reject(new Error(``));
      } else {
        for (let index = 0; index < formVariant.attributes.length; index++) {
          const element = formVariant.attributes[index];
          if (element.options.length == 0) {
            return Promise.reject(new Error(``));
          }

        }


      }

    } catch (error) {
      console.log("valieat error", error)
    }



    return Promise.resolve();


  }



  const submitForm = () => {
    form.submit();
  };

  return (
    <div>
      <Form layout="vertical" form={form} initialValues={{}} onFinish={onFinish}>
        <StickyBar>
          <div
            style={{
              flexGrow: 1,
            }}
          >
            <h1>Edit Item Variants</h1>
          </div>

          <div
            style={{
              alignContent: "center",
            }}
          >
            <Space size="middle">
              <div style={{ height: "100%", display: "flex", justifyContent: "center" }}>
              </div>
              <Button onClick={e => setStep(0)}>Back</Button>
              <Buttons.SaveButton onClick={submitForm} disabled={!submittable}></Buttons.SaveButton>
            </Space>
          </div>
        </StickyBar>
        <Divider></Divider>




        <Form.Item
          name="id"
          hidden
        >

        </Form.Item>




        <div>
          <Form.Item name="variant" rules={[{ validator: variantValidate }]} className="abcc" shouldUpdate={true} >
            <VariantsPicker error={getVariantError()} showProducts edit={true}></VariantsPicker>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default ItemEditor;
