import { createContext, useState, useContext, useEffect } from "react";
import FolderService from "services/folder";
import TagService from "services/tag";
import ExportModal from "components/Export/ExportModal";
import CreateLabelsModal from "components/Label/CreateLabelsModal";
import PdfViewerModal from "components/Pdf/PdfModal";
import UpdateSingleQuantityModal from "components/item/UpdateSingleQuantityModal";
import UpdateMultiQuantityModal from "components/item/UpdateMultiQuantityModal";
import { useItemsScreenContext } from "pages/items";
import eventBus from "../../bus";
import MoveSingleQuantityItemModal from "components/MoveQuantity/MoveSingleQuantityItemModal";
import MoveMultiQuantityItemsModal from "components/MoveQuantity/MoveMultiQuantityItemsModal";
import { io } from "socket.io-client";
import { socket_url } from "config";
import MoveFoldersModal from "components/MoveQuantity/MoveFoldersModal";
import AddOrRemoveTagItemModal from "components/Tag/AddOrRemoveTagItemModal";
import LowStockAlertModal from "components/Alert/LowStockAlertModal";
import CustomFieldService from "services/custom_field";
import DeleteModal from "components/Delete/DeleteModal";
import AttributeService from "services/attribute";
import AppLoader from "components/app-loader"
import NotificationService from "services/notification";
import CloneModal from "components/Clone/CloneModal";
import { useNotification } from "context/notification";

const socket = io(socket_url, {
    auth: {
        token: localStorage.getItem("token"),
    },
});

const AppContext = createContext();

function AppProvider({ children }) {
    const [folderList, setFolderList] = useState([]);
    const [tagList, setTagList] = useState([]);
    const [customFieldList, setCustomFieldList] = useState([]);
    const [attributeList, setAttributeList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [rootFolder, setRootFolder] = useState({})
    const [countUnRead, setCountUnRead] = useState();

    const { success, error } = useNotification();

    useEffect(() => {
        // loadFolders();
        // loadTags();
        // loadCustomFields();
        // loadAttributes();
        loadData();
    }, []);

    useEffect(() => {
        socket.on("data_changed", (payload) => {
            var { data_type } = payload;
            if (data_type == "folder") {
                loadFolders();
            }
            if (data_type == "tag") {
                loadTags();
            }
            if (data_type == "custom_field") {
                loadCustomFields();
            }
        });

        socket.on("notification", (payload) => {
            console.log("notification", payload)
            var { data_type, message } = payload;
            loadCountUnread();
            error(message)
        });


    }, []);

    const loadData = async () => {

        var array = [
            loadFolders(),
            loadTags(),
            loadCustomFields(),
            loadAttributes(),
            loadCountUnread()
        ]
        await Promise.all(array);

        setIsLoading(false);
    }

    const loadFolders = async () => {
        var service = new FolderService();
        var lst = await service.list();
        setFolderList(lst);
        var root = lst.find(x => x.is_root);
        setRootFolder(root ?? {})

    };

    const loadTags = async () => {
        const tagService = new TagService();
        const lst = await tagService.list();
        setTagList(lst);
    };

    const loadCustomFields = async () => {
        const customFieldService = new CustomFieldService();
        const lst = await customFieldService.get();
        setCustomFieldList(lst);
    };

    const loadAttributes = async () => {
        const attributeService = new AttributeService();
        const lst = await attributeService.get();
        setAttributeList(lst);
    };

    const loadCountUnread = async () => {
        const notificationService = new NotificationService();
        const result = await notificationService.countUnread();
        setCountUnRead(result)
    }
    

    const [itemsCreateLabel, setItemsCreateLabel] = useState([]);
    const [showModalCreateLabel, setShowCreateLabel] = useState(false);

    const [itemsExport, setItemsExport] = useState([]);
    const [showModalExport, setShowModalExport] = useState(false);

    const [pdfUrl, setPdfUrl] = useState(null);
    const [showModalPdf, setShowModalPdf] = useState(false);

    const [folderId, setFolderId] = useState();
    const [itemUpdateSingleQuantity, setItemUpdateSingleQuantity] = useState();
    const [showModalSingleUpdateQuantity, setShowModalSingleUpdateQuantity] = useState(false);
    const [itemsUpdateMultiQuantity, setItemsUpdateMultiQuantity] = useState([]);
    const [showModalMultiUpdateQuantity, setShowModalMultiUpdateQuantity] = useState(false);

    const [itemMoveSingleQuantity, setItemMoveSingleQuantity] = useState();
    const [showModalMoveSingleQuantity, setShowModalMoveSingleQuantity] = useState(false);

    const [itemMoveMultiQuantity, setItemMoveMultiQuantity] = useState();
    const [showModalMoveMultiQuantity, setShowModalMoveMultiQuantity] = useState(false);

    const [foldersMoveQuantity, setFoldersMoveQuantity] = useState();
    const [showModalMoveFolders, setShowModalMoveFolder] = useState(false);

    const [itemsSetTag, setItemSetTag] = useState([]);
    const [showModalSetTag, setShowModalSetTag] = useState(false);
    const [typeSetTag, setTypeSetTag] = useState("");

    const [itemsAlert, setItemAlert] = useState([]);
    const [showModalAlert, setShowModalAlert] = useState(false);

    const [itemsDelete, setItemDelete] = useState([]);
    const [showModalDelete, setShowModalDelete] = useState(false);
    
    const [itemsClone, setItemsClone] = useState([]);
    const [showModalClone, setShowModalClone] = useState(false);

    const showCreateLabelModal = (listItems, folderId) => {
        setItemsCreateLabel(listItems);
        setFolderId(folderId);
        setShowCreateLabel(true);
    };

    const showExportModal = async (listItems, folderId) => {
        setItemsExport(listItems);
        setFolderId(folderId);
        setShowModalExport(true);
    };

    const showPdfViewer = async (url) => {
        setPdfUrl(url);
        setShowModalPdf(true);
    };

    const showUpdateSingleQuantityModal = async (item, folderId) => {
        setItemUpdateSingleQuantity(item);
        setFolderId(folderId);
        setShowModalSingleUpdateQuantity(true);
    };

    const showUpdateMultiQuantityModal = async (listItems, folderId) => {
        setItemsUpdateMultiQuantity(listItems);
        setFolderId(folderId);
        setShowModalMultiUpdateQuantity(true);
    };

    const showMoveSingleQuantityModal = async (item, folderId) => {
        setItemMoveSingleQuantity(item);
        setFolderId(folderId);
        setShowModalMoveSingleQuantity(true);
    };

    const showMoveMultiQuantityModal = async (listItems, folderId) => {
        setItemMoveMultiQuantity(listItems);
        setFolderId(folderId);
        setShowModalMoveMultiQuantity(true);
    };

    const showMoveFolderModal = async (listItems, folderId) => {
        setFoldersMoveQuantity(listItems);
        setFolderId(folderId);
        setShowModalMoveFolder(true);
    };

    // type = "Add" or "Remove"
    const showSetTagModal = async (listItems, folderId, type) => {
        setItemSetTag(listItems);
        setFolderId(folderId);
        setTypeSetTag(type);
        setShowModalSetTag(true);
    };

    const showAlertModal = async (listItems, folderId) => {
        setItemAlert(listItems);
        setFolderId(folderId);
        setShowModalAlert(true);
    };

    const showDeleteModal = async (listItems, folderId) => {
        setItemDelete(listItems);
        setFolderId(folderId);
        setShowModalDelete(true);
    };

    const showCloneModal = async (listItems, folderId) => {
        setItemsClone(listItems);
        setFolderId(folderId);
        setShowModalClone(true);
    };

    if (isLoading) {
        return <AppLoader></AppLoader>
    }

    return (
        <AppContext.Provider
            value={{
                folderList,
                showExportModal,
                showCreateLabelModal,
                showPdfViewer,
                showUpdateSingleQuantityModal,
                showUpdateMultiQuantityModal,
                showMoveSingleQuantityModal,
                showMoveMultiQuantityModal,
                showMoveFolderModal,
                showSetTagModal,
                showAlertModal,
                showDeleteModal,
                showCloneModal,
                eventBus,
                tagList,
                customFieldList,
                attributeList,
                rootFolder,
                countUnRead,
                setCountUnRead
            }}
        >
            <CreateLabelsModal
                items={itemsCreateLabel}
                folderId={folderId}
                visible={showModalCreateLabel}
                onClose={() => {
                    setShowCreateLabel(false);
                    setItemsCreateLabel();
                }}
            />

            <ExportModal
                items={itemsExport}
                folderId={folderId}
                visible={showModalExport}
                onClose={() => {
                    setShowModalExport(false);
                    setItemsExport();
                }}
            />

            <PdfViewerModal
                pdfUrl={pdfUrl}
                visible={showModalPdf}
                onClose={() => {
                    setShowModalPdf(false);
                    setPdfUrl(null);
                }}
            />

            <UpdateSingleQuantityModal
                item={itemUpdateSingleQuantity}
                folderId={folderId}
                visible={showModalSingleUpdateQuantity}
                onClose={() => {
                    setShowModalSingleUpdateQuantity(false);
                    setItemUpdateSingleQuantity();
                }}
            />

            <UpdateMultiQuantityModal
                items={itemsUpdateMultiQuantity}
                folderId={folderId}
                visible={showModalMultiUpdateQuantity}
                onClose={() => {
                    setShowModalMultiUpdateQuantity(false);
                    setItemsUpdateMultiQuantity();
                }}
            />

            <MoveSingleQuantityItemModal
                item={itemMoveSingleQuantity}
                folderId={folderId}
                visible={showModalMoveSingleQuantity}
                onClose={() => {
                    setShowModalMoveSingleQuantity(false);
                    setItemMoveSingleQuantity();
                }}
            />

            <MoveMultiQuantityItemsModal
                items={itemMoveMultiQuantity}
                folderId={folderId}
                visible={showModalMoveMultiQuantity}
                onClose={() => {
                    setShowModalMoveMultiQuantity(false);
                    setItemMoveMultiQuantity();
                }}
            />

            <MoveFoldersModal
                items={foldersMoveQuantity}
                folderId={folderId}
                visible={showModalMoveFolders}
                onClose={() => {
                    setShowModalMoveFolder(false);
                    setFoldersMoveQuantity();
                }}
            />

            <AddOrRemoveTagItemModal
                items={itemsSetTag}
                folderId={folderId}
                type={typeSetTag}
                visible={showModalSetTag}
                onClose={() => {
                    setShowModalSetTag(false);
                    setItemSetTag([]);
                }}
            />

            <LowStockAlertModal
                items={itemsAlert}
                folderId={folderId}
                visible={showModalAlert}
                onClose={() => {
                    setShowModalAlert(false);
                    setItemAlert([]);
                }}
            />

            <DeleteModal
                items={itemsDelete}
                folderId={folderId}
                visible={showModalDelete}
                onClose={() => {
                    setShowModalDelete(false);
                    setItemDelete([]);
                }}
            />

            <CloneModal
                items={itemsClone}
                folderId={folderId}
                visible={showModalClone}
                onClose={() => {
                    setShowModalClone(false);
                    setItemsClone([]);
                }}
            />
            {children}
        </AppContext.Provider>
    );
}

const useAppContext = () => {
    const context = useContext(AppContext);

    return context;
};

export { useAppContext };
export default AppProvider;
