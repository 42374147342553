import * as React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import LoginPage from "pages/account/Login"
import WorkspaceLogin from "pages/account/WorkSpaceLogin"
import RegisterPage from "pages/account/Register"
import GoogleRegisterPage from "pages/account/GoogleRegister"
import ResetPasswordPage from "pages/account/ResetPassword"
import ThankYou from "pages/account/ThankYou"

const PageRoutes = () => {
  return (
    <Routes>
      <Route path="/login" element={<LoginPage></LoginPage>} />
      <Route path="/:id/login" element={<WorkspaceLogin></WorkspaceLogin>} />
      <Route path="/register" element={<RegisterPage></RegisterPage>} />
      <Route path="/signupwithgoogle/:access_token" element={<GoogleRegisterPage></GoogleRegisterPage>} />
      <Route path="/reset-password" element={<ResetPasswordPage></ResetPasswordPage>} />
      <Route path="/thank-you" element={<ThankYou></ThankYou>} />
      <Route path="/workspacelogin" element={<WorkspaceLogin></WorkspaceLogin>} />
      <Route path="*" element={<LoginPage></LoginPage>} />
    </Routes>
  );
};

export default React.memo(PageRoutes);
