import { memo, useCallback, useEffect, useState } from "react";
import AddOrEditTagModal from "components/Tag/AddOrEditTagModal";
import { Button, Card, ColorPicker, Divider, Flex, Popconfirm, Space, Table } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useAppContext } from "context/app";
import Search from "components/Search/Search";
import _ from "lodash";
import TagService from "services/tag";
import UserService from "services/user";
import AddOrEditUserModal from "components/User/AddOrEditUserModal";
import LginUrlModal from "components/User/LginUrlModal";
import { CustomButtonLink } from "components/Buttons";
import { useNotification } from "context/notification";

const userService = new UserService();

function UserManagement() {
    const [loading, setLoading] = useState(false);
    const [info, setInfo] = useState();
    const [lstUser, setLstUser] = useState([]);
    const [visible, setVisible] = useState(false);
    const [modalLoginVisible, setModalLoginVisible] = useState(false);
    const [valueEditUser, setValueEditUser] = useState(null);
    const [typeEdit, setTypeEdit] = useState(null);
    const { success, error } = useNotification();

    useEffect(() => {
        fetchListUser();
    }, [info]);

    const fetchListUser = async () => {
        setLoading(true);

        const result = await userService.list({ info: info });
        setLstUser(result);

        setLoading(false);
    };

    const handleSearch = useCallback(
        _.debounce(async (textSearch) => {
            setInfo(textSearch);
        }, 500),
        []
    );

    const onChange = async () => {
        fetchListUser();
        resetValue();
    };

    const confirm = async (data) => {
        try {
            await userService.delete(data.id);
        } catch (err) {
            error(err);
        } finally {
            fetchListUser();
        }
    };

    const columns = [
        {
            title: "FIRST NAME",
            dataIndex: "first_name",
            width: "20%",
        },
        {
            title: "LAST NAME",
            dataIndex: "last_name",
            width: "20%",
        },
        {
            title: "USER NAME",
            dataIndex: "user_name",
            width: "20%",
            render: (text, record) => <span>{record?.work_spaces[0]?.user_name}</span>,
        },
        {
            title: "EMAIL",
            dataIndex: "email",
            width: "20%",
            render: (text, record) => <span>{record?.work_spaces[0]?.email}</span>,
        },
        {
            title: "ROLE",
            dataIndex: "role",
            width: "10%",
            render: (text, record) => <span>{record?.work_spaces[0]?.role}</span>,
        },
        {
            title: "ACTIONS",
            dataIndex: "action",
            width: "10%",
            render: (text, record) => (
                <>
                    <div>
                        <EditOutlined
                            style={{ cursor: "pointer", marginRight: "16px" }}
                            onClick={() => {
                                setValueEditUser(record);
                                setTypeEdit("update_role");
                                setVisible(true);
                            }}
                        />
                        <Popconfirm title="Delete user" description="Are you sure to delete this user ?" onConfirm={() => confirm(record)} okText="Yes" cancelText="No">
                            <DeleteOutlined style={{ cursor: "pointer" }} />
                        </Popconfirm>
                    </div>
                </>
            ),
        },
    ];

    const resetValue = () => {
        setTypeEdit(null);
        setValueEditUser(null);
        setVisible(false);
    };

    return (
        <>
            <AddOrEditUserModal visible={visible} onClose={() => resetValue()} onChange={onChange} value={valueEditUser} typeEdit={typeEdit} />
            <LginUrlModal visible={modalLoginVisible} onClose={() => setModalLoginVisible(false)} />
            <Flex justify="space-between" align="center">
                <h1>User Management</h1>

                <div>
                    <Space>
                        <CustomButtonLink
                            onClick={() => {
                                setModalLoginVisible(true);
                            }}
                        >
                            Login Url
                        </CustomButtonLink>
                        <Button
                            type="primary"
                            onClick={() => {
                                setVisible(true);
                            }}
                        >
                            ADD USER
                        </Button>
                    </Space>
                </div>
            </Flex>

            <Divider />
            <Space direction="vertical" style={{ width: "100%" }}>
                <Card>
                    <div style={{ width: "700px" }}>
                        <Search placeholder={"Search users"} onChange={handleSearch} />
                    </div>
                </Card>

                <Card>
                    <Table loading={loading} dataSource={lstUser} columns={columns} />
                </Card>
            </Space>
        </>
    );
}

export default memo(UserManagement);
