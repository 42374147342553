import { Button, Col, Input, Modal, Row, Form, Switch, Checkbox, InputNumber, theme, Select, DatePicker, Divider } from "antd";
import {
    FontSizeOutlined,
    CheckSquareOutlined,
    FieldBinaryOutlined,
    DownSquareOutlined,
    PhoneOutlined,
    MailOutlined,
    LinkOutlined,
    CalendarOutlined,
    PlusOutlined,
    QrcodeOutlined,
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import CustomFieldService from "services/custom_field";
import * as field_type_const from "components/CustomField/constants";
import FieldOptionsCustomField from "components/CustomField/FieldOptionsCustomField";
import ListCustomFields from "components/CustomField/ListCustomFields";
import { SaveButton } from "components/Buttons";
import dayjs from "dayjs";
import { useNotification } from "context/notification";
import { useLoading } from "context/loading";

const customFieldService = new CustomFieldService();

function AddCustomFieldModal({ visible, onClose, onChange, value = null }) {
    const [form] = Form.useForm();

    const [page, setPage] = useState(1);
    const [disableSave, setDisableSave] = useState(true);
    const [listField, setListField] = useState([]);
    const [selectedField, setSelectedField] = useState();
    const [listOption, setListOption] = useState([]);

    const { success, error } = useNotification();
    const { showLoading, dismissLoading } = useLoading();

    //typeField = SUGGESTED or OWN
    const [typeField, setTypeField] = useState("SUGGESTED");
    const today = dayjs();

    const { useToken } = theme;
    const { token } = useToken();

    useEffect(() => {
        if (value) {
            if (value.field_type === field_type_const.DATE && value.default_value) {
                value.default_value = dayjs(value.default_value);
            }
            form.setFieldsValue(value);

            if (value.field_type === field_type_const.DROPDOWN) {
                setListOption(value.options);
                form.setFieldsValue({ options: value.options.join("\n") });
            }

            // Gán giá trị trong trường hợp edit custom field
            const setEdit = async () => {
                const [suggestedFields, dataTypes] = await Promise.all([customFieldService.getSuggestedFields(), customFieldService.getDataTypes()]);
                const combinedList = [...suggestedFields, ...dataTypes];

                const selected = combinedList.find((item) => item.data_type == value.field_type);
                setSelectedField(selected);
                setPage(2);
            };

            setEdit();
        }
    }, [value]);

    useEffect(() => {
        fetchListDataTypes();
    }, [typeField]);

    useEffect(() => {
        if (visible) {
            setSelectedField(listField[0]);
        }
    }, [visible]);

    const fetchListDataTypes = async () => {
        let result;
        if (typeField === "SUGGESTED") {
            result = await customFieldService.getSuggestedFields();
        } else if (typeField === "OWN") {
            result = await customFieldService.getDataTypes();
        }

        setListField(result);
        setSelectedField(result[0]);
    };

    const handleSaveCustomField = async () => {
        showLoading("Saving");

        if (value) {
            const data = {
                id: value.id,
                name: form.getFieldValue("name"),
                default_value: form.getFieldValue("default_value"),
                placeholder: form.getFieldValue("placeholder"),

                round_number_unit: form.getFieldValue("round_number_unit"),

                options: listOption.length > 0 ? listOption : null,

                applicable: value.applicable,
            };
            const result = await customFieldService.edit(data);
            if (result) {
                onChange(result);
                resetValue();
                success(`${data.name} has been successfully updated`);
            } else {
                error("Failed to update the custom field. Please try again later");
            }

            dismissLoading();
        } else {
            const applicable = {
                item: form.getFieldValue("item"),
                folder: form.getFieldValue("folder"),
            };

            const data = {
                ...form.getFieldsValue(),
                description: selectedField.description,
                field_type: selectedField.data_type,
                applicable: applicable,
                options: listOption.length > 0 ? listOption : null,
            };

            const result = await customFieldService.add(data);
            if (result) {
                onChange(result);
                resetValue();
                success(`${data.name} has been successfully created`);
            } else {
                error("Failed to create the custom field. Please try again later");
            }
            dismissLoading();
        }
    };

    const resetValue = () => {
        form.resetFields();
        setPage(1);
        setListOption([]);
        setDisableSave(true);
    };

    const showInput = (selectedField) => {
        switch (selectedField.data_type) {
            case field_type_const.SHORT_TEXT:
                return (
                    <Form.Item label={selectedField.name}>
                        <Input placeholder={selectedField.placeholder} readOnly style={{ pointerEvents: "none" }} />
                    </Form.Item>
                );
            case field_type_const.LONG_TEXT:
                return (
                    <Form.Item label={selectedField.name}>
                        <Input placeholder={selectedField.placeholder} readOnly style={{ pointerEvents: "none" }} />
                    </Form.Item>
                );
            case field_type_const.CHECK_BOX:
                return (
                    <Form.Item label={selectedField.name} layout="horizontal">
                        <Checkbox checked readOnly style={{ pointerEvents: "none" }} />
                    </Form.Item>
                );
            case field_type_const.ROUND_NUMBER:
                return (
                    <Form.Item label={selectedField.name}>
                        <Input placeholder={selectedField.placeholder} readOnly style={{ pointerEvents: "none" }} />
                    </Form.Item>
                );
            case field_type_const.DECIMAL_NUMBER:
                return (
                    <Form.Item label={selectedField.name}>
                        <Input placeholder={selectedField.placeholder} readOnly style={{ pointerEvents: "none" }} />
                    </Form.Item>
                );
            case field_type_const.DROPDOWN:
                return (
                    <Form.Item label={selectedField.name}>
                        <Select readOnly placeholder={selectedField.placeholder} style={{ pointerEvents: "none" }} />
                    </Form.Item>
                );
            case field_type_const.PHONE_NUMBER:
                return (
                    <Form.Item label={selectedField.name}>
                        <Input placeholder={selectedField.placeholder} readOnly style={{ pointerEvents: "none" }} addonAfter={<PhoneOutlined />} />
                    </Form.Item>
                );
            case field_type_const.EMAIL:
                return (
                    <Form.Item label={selectedField.name}>
                        <Input placeholder={selectedField.placeholder} readOnly style={{ pointerEvents: "none" }} addonAfter={<MailOutlined />} />
                    </Form.Item>
                );
            case field_type_const.LINK:
                return (
                    <Form.Item label={selectedField.name}>
                        <Input placeholder={selectedField.placeholder} readOnly style={{ pointerEvents: "none" }} addonAfter={<LinkOutlined />} />
                    </Form.Item>
                );
            case field_type_const.DATE:
                return (
                    <Form.Item label={selectedField.name}>
                        <DatePicker defaultValue={today} format="DD/MM/YYYY" placeholder={selectedField.placeholder} readOnly style={{ pointerEvents: "none", width: "100%" }} />
                    </Form.Item>
                );
            case field_type_const.FILE:
                return (
                    <Form.Item label={selectedField.name}>
                        <Button readOnly style={{ pointerEvents: "none" }} icon={<PlusOutlined />}>
                            Click to Upload
                        </Button>
                    </Form.Item>
                );
            case field_type_const.SCANNER:
                return (
                    <Form.Item label={selectedField.name}>
                        <Button readOnly style={{ pointerEvents: "none" }} icon={<QrcodeOutlined />}>
                            Qr/Bar code
                        </Button>
                    </Form.Item>
                );
            default:
                break;
        }
    };

    return (
        <Modal
            width={850}
            title={<p style={{ fontSize: token.fontSizeHeading3, textAlign: "center" }}>{value ? "Edit Custom Field" : "Create Custom Field"}</p>}
            open={visible}
            onCancel={() => {
                onClose();
                resetValue();
            }}
            maskClosable={false}
            footer={null}
        >
            {page === 1 ? (
                <div style={{ display: "flex", marginBottom: token.marginXL, gap: "8px" }}>
                    <div style={{ flex: 1 }}>
                        <ListCustomFields listField={listField} selectedField={selectedField} setSelectedField={setSelectedField} typeField={typeField} setTypeField={setTypeField} />
                    </div>

                    <div style={{ flex: 1, backgroundColor: token.colorBgLayout, borderRadius: token.borderRadiusLG * 2, display: "flex", flexDirection: "column", padding: "20px 10px" }}>
                        <div style={{ backgroundColor: token.colorBgContainer, padding: `0 ${token.padding}px`, borderRadius: token.borderRadiusLG, paddingTop: token.paddingXL }}>
                            {selectedField && <Form layout="vertical">{showInput(selectedField)}</Form>}
                        </div>
                        <div dangerouslySetInnerHTML={{ __html: selectedField?.description }} style={{ padding: token.padding }} />
                    </div>
                </div>
            ) : (
                <FieldOptionsCustomField
                    form={form}
                    selectedField={selectedField}
                    value={value}
                    setDisableSave={setDisableSave}
                    listOption={listOption}
                    setListOption={setListOption}
                    typeField={typeField}
                />
            )}

            <Divider />

            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                {page === 1 ? (
                    <>
                        <div></div>
                        <span>Step {page} of 2</span>
                        <Button type="primary" onClick={() => setPage(2)}>
                            Next
                        </Button>
                    </>
                ) : value ? (
                    <>
                        <div></div>
                        <div></div>
                        <SaveButton onClick={handleSaveCustomField} disabled={disableSave}>
                            Save
                        </SaveButton>
                    </>
                ) : (
                    <>
                        <Button
                            type=""
                            onClick={() => {
                                setPage(1);
                                form.resetFields();
                            }}
                        >
                            Back
                        </Button>
                        <span>Step {page} of 2</span>
                        <SaveButton onClick={handleSaveCustomField} disabled={disableSave}>
                            Save
                        </SaveButton>
                    </>
                )}
            </div>
        </Modal>
    );
}

export default AddCustomFieldModal;
