import { Button, Col, ColorPicker, Divider, Flex, Row } from "antd";
import { presetPalettes } from "@ant-design/colors";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";

function ColorsPicker({ value, onChange }) {
    const [showPanel, setShowPanel] = useState(false);

    useEffect(() => {
        onChange("#2a9d8f");
    }, []);

    const genPresets = (presets = presetPalettes) =>
        Object.entries(presets).map(([label, colors]) => ({
            label,
            colors,
        }));

    const presets = genPresets({
        color: ["264653", "2a9d8f", "e9c46a", "f4a261", "e76f51"],
    });

    const handleColorChange = (value) => {
        const hex = typeof value === "string" ? value : value?.toHexString();
        onChange(hex);
    };

    const customPanelRender = (_, { components: { Picker, Presets } }) => (
        <Row justify="space-between" gutter={[10, 10]}>
            <Col span={24}>
                <Flex gap={10} align="center">
                    {presets[0].colors.map((color) => (
                        <>
                            <div
                                style={{
                                    position: "relative",
                                    width: 24,
                                    height: 24,
                                    backgroundColor: `#${color}`,
                                    cursor: "pointer",
                                    borderRadius: "4px",
                                    border: value === `#${color}` ? "1px solid #ddd" : "",
                                }}
                                onClick={() => handleColorChange(`#${color}`)}
                            >
                                {value === `#${color}` && <CheckOutlined style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", color: "#fff", fontSize: 16 }} />}
                            </div>
                        </>
                    ))}
                    <Button size="small" onClick={() => setShowPanel((prev) => !prev)}>
                        {showPanel ? <CloseOutlined /> : "More"}
                    </Button>
                </Flex>
                {/* <Flex align="center" gap={10}>
                    <Presets />
                    <Button size="small" style={{ marginTop: "20px" }} onClick={() => setShowPanel((prev) => !prev)}>
                        {showPanel ? <CloseOutlined /> : "More"}
                    </Button>
                </Flex> */}
            </Col>

            {showPanel && (
                <Col span={24}>
                    <Picker />
                </Col>
            )}
        </Row>
    );

    return (
        <>
            <ColorPicker value={value} presets={presets} panelRender={customPanelRender} onChange={handleColorChange} showText={value ? true : false} />
        </>
    );
}

export default ColorsPicker;
