import { Button, Card, Divider, Flex, Space, theme } from "antd";
import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as Date from "../../common/date_format";
import * as Number from "../../common/number_format";

function NotificationDetail() {
    const { data } = useLocation().state || {};
    const { token } = theme.useToken();
    const navigate = useNavigate();

    console.log({ data });

    const styleFontSize = { fontSize: token.fontSizeHeading5 };

    return (
        <Card>
            <Flex vertical>
                <Flex justify="space-between">
                    <span style={{ fontSize: token.fontSizeHeading4, fontWeight: 500 }}>{data.message}</span>
                    <Flex gap={20}>
           
                        <Button type="primary" onClick={() => navigate(`/item-detail/${data.data.item_id}`)}>
                            View Item
                        </Button>
                    </Flex>
                </Flex>

                <Divider />

                <span style={styleFontSize}>Item name: {data.data.item_name}</span>
                <span style={styleFontSize}>Folder name: {data.data.folder_name}</span>
                <span style={styleFontSize}>Current stock: {Number.formatInt(data.data.onhand_quantity)}</span>
                <span style={styleFontSize}>Minimum quantity: {Number.formatInt(data.data.min_quantity)}</span>
                <span style={styleFontSize}>Alert on: {Date.formatDateTime(data.update_on)}</span>
            </Flex>
        </Card>
    );
}

export default NotificationDetail;
