import BaseService from "services/base";
import _ from "lodash";

class OTPService extends BaseService {
  verify = async ({ id, otp }) => {
    var data = await this._api.post("otp/verify", { id, otp });
    return data;
  };

}

export default OTPService;
