import { useEffect, useState } from "react";
import { Divider, Flex, Collapse, Button, Form, Switch, InputNumber, Select, theme } from "antd";
import { CheckCircleFilled } from "@ant-design/icons";
import FilterFolder from "./FilterFolders";
import { useForm, useWatch } from "antd/es/form/Form";
import FilterNumber from "./FilterNumber";
import TagPicker from "../../../components/pickers/tag";
import { useAppContext } from "context/app";

function Filter({ filters, setFilters, setShowFilterFolder, collapseExpandKey, setCollapseExpandKey, activeFilters, setActiveFilters }) {
    const { token } = theme.useToken();
    const folders = useAppContext().folderList;
    const [form] = useForm();

    const filter_folder = useWatch("filter_folder", form);

    const quantity_filter_from = useWatch("quantity_filter_from", form);
    const quantity_filter_to = useWatch("quantity_filter_to", form);
    const quantity_filter_exact = useWatch("quantity_filter_exact", form);

    const minLevel_filter_from = useWatch("minLevel_filter_from", form);
    const minLevel_filter_to = useWatch("minLevel_filter_to", form);
    const minLevel_filter_exact = useWatch("minLevel_filter_exact", form);

    const price_filter_from = useWatch("price_filter_from", form);
    const price_filter_to = useWatch("price_filter_to", form);
    const price_filter_exact = useWatch("price_filter_exact", form);

    const filter_alerts = useWatch("filter_alerts", form);

    const filter_stocks = useWatch("filter_stocks", form);

    const filter_tags = useWatch("filter_tags", form);

    useEffect(() => {
        setFilter();
    }, [filters]);

    const setFilter = () => {
        const quantity = filters.quantity || {};
        const minLevel = filters.lowstock_level || {};
        const price = filters.price || {};
        const alert = filters.lowstock_alert || {};
        const stock = filters.lowstock || filters.outof_stock || {};

        form.setFieldsValue({
            filter_folder: filters.folders || [],

            quantity_checked_exact: quantity.hasOwnProperty("exact"),
            quantity_filter_exact: quantity.exact,
            quantity_filter_from: quantity.gte,
            quantity_filter_to: quantity.lte,

            minLevel_checked_exact: minLevel.hasOwnProperty("exact"),
            minLevel_filter_exact: minLevel.exact,
            minLevel_filter_from: minLevel.gte,
            minLevel_filter_to: minLevel.lte,

            price_checked_exact: price.hasOwnProperty("exact"),
            price_filter_exact: price.exact,
            price_filter_from: price.gte,
            price_filter_to: price.lte,

            filter_alerts: alert.has_alert === true ? "with_quantity_alerts_set" : alert.has_alert === false ? "without_quantity_alerts_set" : undefined,

            filter_stocks: stock.is_low_stock === true ? "low_stock" : stock.is_out_of_stock === true ? "out_of_stock" : undefined,

            filter_tags: filters.tags || undefined,
        });
    };

    useEffect(() => {
        const filters = [
            filter_folder?.length > 0,
            quantity_filter_from != null || quantity_filter_to != null || quantity_filter_exact != null,
            minLevel_filter_from != null || minLevel_filter_to != null || minLevel_filter_exact != null,
            price_filter_from != null || price_filter_to != null || price_filter_exact != null,
            filter_alerts,
            filter_tags?.length > 0,
            filter_stocks,
        ];

        const activeCount = filters.filter(Boolean).length;
        setActiveFilters(activeCount);
    }, [
        filter_folder,
        quantity_filter_from,
        quantity_filter_to,
        quantity_filter_exact,
        minLevel_filter_from,
        minLevel_filter_to,
        minLevel_filter_exact,
        price_filter_from,
        price_filter_to,
        price_filter_exact,
        filter_alerts,
        filter_tags,
        filter_stocks,
    ]);

    const handleClearFilter = (filterName) => {
        switch (filterName) {
            case "folders":
                form.setFieldValue("filter_folder", null);
                break;
            case "quantity":
            case "minLevel":
            case "price":
                form.setFieldsValue({
                    [`${filterName}_checked_exact`]: false,
                    [`${filterName}_filter_exact`]: null,
                    [`${filterName}_filter_from`]: null,
                    [`${filterName}_filter_to`]: null,
                });
                break;
            case "alerts":
                form.setFieldValue("filter_alerts", null);
                break;
            case "stocks":
                form.setFieldValue("filter_stocks", null);
                break;
            case "tags":
                form.setFieldValue("filter_tags", null);
                break;
            default:
                break;
        }
    };

    const handleClearAll = () => {
        form.resetFields();
    };

    const items = [
        {
            key: "FOLDERS",
            label: (
                <>
                    <span>FOLDERS</span>

                    {filter_folder && filter_folder.length > 0 && (
                        <>
                            <span style={{ backgroundColor: "#333", color: "#fff", padding: filter_folder.length >= 10 ? "3px 5px" : "3px 9px", borderRadius: "50%", marginLeft: 12 }}>
                                {filter_folder.length}
                            </span>
                            <Button
                                type="link"
                                style={{ padding: 6 }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleClearFilter("folders");
                                }}
                            >
                                Clear
                            </Button>
                        </>
                    )}
                </>
            ),
            children: (
                <Form.Item name="filter_folder">
                    <FilterFolder />
                </Form.Item>
            ),
        },
        FilterSection({
            label: "QUANTITY",
            isActive:
                (quantity_filter_exact !== null && quantity_filter_exact !== undefined) ||
                (quantity_filter_from !== null && quantity_filter_from !== undefined) ||
                (quantity_filter_to !== null && quantity_filter_to !== undefined),
            onClear: () => handleClearFilter("quantity"),
            children: <FilterNumber form={form} filterType="quantity" />,
        }),
        FilterSection({
            label: "MIN LEVEL",
            isActive:
                (minLevel_filter_exact !== null && minLevel_filter_exact !== undefined) ||
                (minLevel_filter_from !== null && minLevel_filter_from !== undefined) ||
                (minLevel_filter_to !== null && minLevel_filter_to !== undefined),
            onClear: () => handleClearFilter("minLevel"),
            children: <FilterNumber form={form} filterType="minLevel" />,
        }),
        FilterSection({
            label: "PRICE",
            isActive:
                (price_filter_exact !== null && price_filter_exact !== undefined) ||
                (price_filter_from !== null && price_filter_from !== undefined) ||
                (price_filter_to !== null && price_filter_to !== undefined),
            onClear: () => handleClearFilter("price"),
            children: <FilterNumber form={form} filterType="price" />,
        }),
        FilterSection({
            label: "QUANTITY ALERTS",
            isActive: filter_alerts !== null && filter_alerts !== undefined && filter_alerts !== "",
            onClear: () => handleClearFilter("alerts"),
            children: (
                <Form.Item name="filter_alerts" style={{ margin: 0 }}>
                    <Select
                        options={[
                            { label: "With Quantity Alerts set", value: "with_quantity_alerts_set" },
                            { label: "Without Quantity Alerts set", value: "without_quantity_alerts_set" },
                        ]}
                    />
                </Form.Item>
            ),
        }),
        FilterSection({
            label: "STOCKS",
            isActive: filter_stocks !== null && filter_stocks !== undefined && filter_stocks !== "",
            onClear: () => handleClearFilter("stocks"),
            children: (
                <Form.Item name="filter_stocks" style={{ margin: 0 }}>
                    <Select
                        options={[
                            { label: "Low Stock", value: "low_stock" },
                            { label: "Out of stock", value: "out_of_stock" },
                        ]}
                    />
                </Form.Item>
            ),
        }),
        FilterSection({
            label: "TAGS",
            isActive: filter_tags && filter_tags.length > 0,
            onClear: () => handleClearFilter("tags"),
            children: (
                <Form.Item name="filter_tags" style={{ margin: 0 }}>
                    <TagPicker placeholder="" hiddenButtonAdd={true} />
                </Form.Item>
            ),
        }),
    ];

    const onChange = (key) => {
        console.log(key);
        setCollapseExpandKey(key);
    };

    const updateFilter = (prev, fieldValue, filterName, checkExactKey, exactKey, rangeKeys) => {
        if (fieldValue[checkExactKey]) {
            if (fieldValue[exactKey] !== undefined && fieldValue[exactKey] !== null) {
                return {
                    ...prev,
                    [filterName]: {
                        exact: fieldValue[exactKey],
                    },
                };
            }
            return removeFilter(prev, filterName);
        }

        const filter = {};
        if (fieldValue[rangeKeys.from] !== undefined && fieldValue[rangeKeys.from] !== null) {
            filter.gte = fieldValue[rangeKeys.from];
        }
        if (fieldValue[rangeKeys.to] !== undefined && fieldValue[rangeKeys.to] !== null) {
            filter.lte = fieldValue[rangeKeys.to];
        }

        if (Object.keys(filter).length > 0) {
            return {
                ...prev,
                [filterName]: filter,
            };
        }

        return removeFilter(prev, filterName);
    };

    const removeFilter = (prev, filterName) => {
        const { [filterName]: removedFilter, ...rest } = prev;
        return rest;
    };

    const handleApply = () => {
        const fieldValue = form.getFieldValue();
        console.log({ fieldValue });

        setFilters((prev) => {
            let updatedFilters = { ...prev };

            // Set filter folder
            if (Array.isArray(fieldValue.filter_folder) && fieldValue.filter_folder.length > 0) {
                updatedFilters = { ...updatedFilters, folders: fieldValue.filter_folder };
            } else {
                const { folders, ...rest } = updatedFilters;
                updatedFilters = rest;
            }

            // Set filter quantity
            updatedFilters = updateFilter(updatedFilters, fieldValue, "quantity", "quantity_checked_exact", "quantity_filter_exact", { from: "quantity_filter_from", to: "quantity_filter_to" });

            // Set filter min level
            updatedFilters = updateFilter(updatedFilters, fieldValue, "lowstock_level", "minLevel_checked_exact", "minLevel_filter_exact", { from: "minLevel_filter_from", to: "minLevel_filter_to" });

            // Set filter price
            updatedFilters = updateFilter(updatedFilters, fieldValue, "price", "price_checked_exact", "price_filter_exact", { from: "price_filter_from", to: "price_filter_to" });

            // Set filter quantity alerts
            updatedFilters = {
                ...updatedFilters,
                lowstock_alert:
                    fieldValue.filter_alerts === "with_quantity_alerts_set" ? { has_alert: true } : fieldValue.filter_alerts === "without_quantity_alerts_set" ? { has_alert: false } : undefined,
            };

            // Set filter stocks
            const stockFilters = {
                lowstock: fieldValue.filter_stocks === "low_stock" ? { is_low_stock: true } : undefined,
                outof_stock: fieldValue.filter_stocks === "out_of_stock" ? { is_out_of_stock: true } : undefined,
            };
            updatedFilters = {
                ...updatedFilters,
                ...stockFilters,
            };

            // Set filter tags
            if (Array.isArray(fieldValue.filter_tags) && fieldValue.filter_tags.length > 0) {
                updatedFilters = { ...updatedFilters, tags: fieldValue.filter_tags };
            } else {
                const { tags, ...rest } = updatedFilters;
                updatedFilters = rest;
            }

            return updatedFilters;
        });

        setShowFilterFolder(false);
    };

    return (
        <Flex vertical style={{ position: "fixed", right: 0, zIndex: 5, backgroundColor: token.colorBgBase, boxShadow: token.boxShadow, width: "350px" }}>
            <div style={{ flex: 1 }}>
                <Flex align="center" gap={4} style={{ borderBottom: "1px solid #ccc" }}>
                    <span style={{ display: "block", fontSize: token.fontSizeHeading4, margin: `${token.margin}px ${token.marginSM}px` }}>Filters</span>
                    {activeFilters > 0 && (
                        <>
                            <Flex align="center" justify="space-between" style={{ flex: 1 }}>
                                <span style={{ fontSize: token.fontSizeHeading5, backgroundColor: "red", color: "white", padding: "2px 8px", borderRadius: "50%" }}>{activeFilters}</span>
                                <Button type="link" onClick={handleClearAll}>
                                    Clear All
                                </Button>
                            </Flex>
                        </>
                    )}
                </Flex>

                <div style={{ height: "calc(100vh - 125px)", overflowY: "scroll" }}>
                    <Form form={form}>
                        <Collapse
                            items={items}
                            expandIconPosition="end"
                            onChange={onChange}
                            style={{ borderRadius: 0, borderLeft: "none", borderRight: "none" }}
                            defaultActiveKey={collapseExpandKey}
                        />
                    </Form>
                </div>
            </div>

            <Flex gap={16} style={{ margin: token.margin }}>
                <Button style={{ flex: 1 }} type="primary" onClick={() => handleApply()}>
                    APPLY
                </Button>
                <Button
                    style={{ flex: 1 }}
                    onClick={() => {
                        setFilter();
                        setTimeout(() => {
                            setShowFilterFolder(false);
                        }, 0);
                    }}
                >
                    CANCEL
                </Button>
            </Flex>
        </Flex>
    );
}

export default Filter;

function FilterSection({ label, isActive, onClear, children }) {
    return {
        key: label.toUpperCase().replace(" ", "_"),
        label: (
            <Flex align="center" gap={16}>
                <span>{label}</span>
                {isActive && (
                    <Flex>
                        <CheckCircleFilled style={{ fontSize: "20px" }} />
                        <Button
                            type="link"
                            style={{ padding: 6 }}
                            onClick={(e) => {
                                e.stopPropagation();
                                onClear();
                            }}
                        >
                            Clear
                        </Button>
                    </Flex>
                )}
            </Flex>
        ),
        children: children,
    };
}
