import { Input } from "antd";
import * as Icon from "@ant-design/icons";
import { CustomButtonLink } from "components/Buttons";

function InputSearchBarcode({ filters, setFilters }) {
    return (
        <Input
            value={filters.info}
            onChange={(e) => setFilters({ ...filters, info: e.target.value })}
            addonBefore={<Icon.SearchOutlined />}
            placeholder="Search All Items"
            addonAfter={
                <CustomButtonLink type="link" size="small">
                    <Icon.BarcodeOutlined />
                </CustomButtonLink>
            }
        />
    );
}

export default InputSearchBarcode;
