import { Flex, Form, Image, theme } from "antd";
import barcodeDemo from "assets/images/barcode_demo.png";
import qrCodeDemo from "assets/images/qrcode_demo.png";
import photoDisplayHere from "assets/images/photo_display_here.png";
import logoDisplayHere from "assets/images/logo_display_here.png";
import { useContext, useEffect, useState } from "react";
import { useCreateLabel } from "context/label";

function PreviewPrintLabel({ form, selectedLabelSize }) {
    const { useToken } = theme;
    const { token } = useToken();

    const { dataLabel, setDataLabel } = useCreateLabel();

    const [hasDetail, setHasDetail] = useState();
    const [hasPhoto, setHasPhoto] = useState();
    const [hasLogoOrIcon, setHasLogoOrIcon] = useState();
    const [hasBorder, setHasBorder] = useState();
    const [labelType, setLabelType] = useState();

    const has_detail = Form.useWatch("has_detail", { form });
    const has_photo = Form.useWatch("has_photo", { form });
    const has_logo_or_icon = Form.useWatch("has_logo_or_icon", { form });
    const has_note = Form.useWatch("has_note", { form });
    const has_border = Form.useWatch("has_border", { form });
    const label_type = Form.useWatch("label_type", { form });

    useEffect(() => {
        setHasDetail(dataLabel.has_detail || has_detail);
        setHasPhoto(dataLabel.has_photo || has_photo);
        setHasLogoOrIcon(dataLabel.has_logo_or_icon || has_logo_or_icon);
        setLabelType(dataLabel.label_type || label_type);
        setHasBorder(dataLabel.has_border || has_border);
    }, [has_border, has_detail, has_photo, has_logo_or_icon, label_type]);

    return (
        <Flex justify="center" align="center" style={{ flex: 1, backgroundColor: token.colorBgLayout, borderRadius: token.borderRadiusLG * 2 }}>
            {selectedLabelSize.layout === "vertical" ? (
                <div
                    style={{
                        display: "flex",
                        gap: "10px",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                        textAlign: "center",
                        width: `${selectedLabelSize.width}px`,
                        height: `${selectedLabelSize.height}px`,
                        backgroundColor: token.colorBgContainer,
                        padding: token.padding,
                        margin: token.margin,
                        borderRadius: token.borderRadiusLG,
                        border: hasBorder && "1px solid",
                    }}
                >
                    {hasLogoOrIcon && <Image preview={false} width={selectedLabelSize.fontSizeImage} src={logoDisplayHere} alt={logoDisplayHere.toString()} />}
                    <span style={{ fontSize: `${selectedLabelSize.fontSizeItem}px`, fontWeight: "700" }}>Item / Folder name displayed here</span>
                    {hasDetail && (
                        <Flex vertical style={{ fontSize: `${selectedLabelSize.fontSizeField}px` }}>
                            <span style={{ fontWeight: "500" }}>Field name</span>
                            <span>Field name display here</span>
                        </Flex>
                    )}
                    <Flex justify="space-evenly" style={{ width: "100%" }}>
                        {hasPhoto && <Image preview={false} width={selectedLabelSize.fontSizeImage} src={photoDisplayHere} alt={photoDisplayHere.toString()} />}
                        <Image preview={false} width={selectedLabelSize.fontSizeImage} src={labelType === "qr" ? qrCodeDemo : barcodeDemo} alt={labelType} />
                    </Flex>
                </div>
            ) : (
                <div
                    style={{
                        display: "flex",
                        gap: "10px",
                        justifyContent: "space-evenly",
                        width: `${selectedLabelSize.width}px`,
                        height: `${selectedLabelSize.height}px`,
                        backgroundColor: token.colorBgContainer,
                        padding: token.padding,
                        borderRadius: token.borderRadiusLG,
                        border: hasBorder && "1px solid",
                    }}
                >
                    <Flex vertical>
                        {hasLogoOrIcon && <Image preview={false} width={selectedLabelSize.fontSizeImage} src={logoDisplayHere} alt={logoDisplayHere.toString()} />}
                        <span style={{ fontSize: `${selectedLabelSize.fontSizeItem}px`, fontWeight: "500", margin: "auto" }}>Item / Folder name displayed here</span>
                        {hasDetail && (
                            <Flex vertical style={{ fontSize: `${selectedLabelSize.fontSizeField}px` }}>
                                <span style={{ fontWeight: "500" }}>Field name</span>
                                <span>Field name display here</span>
                            </Flex>
                        )}
                    </Flex>
                    <Flex justify="space-evenly" vertical>
                        {hasPhoto && <Image preview={false} width={selectedLabelSize.fontSizeImage} src={photoDisplayHere} alt={photoDisplayHere.toString()} />}
                        <Image preview={false} width={selectedLabelSize.fontSizeImage} src={labelType === "qr" ? qrCodeDemo : barcodeDemo} alt={labelType} />
                    </Flex>
                </div>
            )}
        </Flex>
    );
}

export default PreviewPrintLabel;
