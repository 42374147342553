import { Button, Divider, Flex, Input, Switch, Table, Image, Dropdown, Pagination, Badge, Checkbox, Menu, Card, theme, Skeleton, Empty, Radio, Space } from "antd";
import * as Icon from "@ant-design/icons";
import { SaveButton } from "components/Buttons";
import { useCallback, useEffect, useState } from "react";
import ItemInventoryService from "services/item/ItemInventory";
import * as Number from "common/number_format";
import { useAppContext } from "context/app";

import _ from "lodash";
import TableListItem from "./View/TableListItem";
import Filter from "./Filter";
import UserSettingServices from "services/user_setting";
import useToken from "antd/es/theme/useToken";
import InputSearchBarcode from "components/Input/InputSearchBarCode";
import ItemListService from "services/item/ItemList";
import ItemService from "services/item";
import List from "pages/items/List";
import Item from "./View/Item";
import GridListItem from "./View/GridListItem";
import { useNavigate, useParams } from "react-router-dom";
const itemListInventoryService = new ItemListService();
const itemService = new ItemService();
const userSettingServices = new UserSettingServices();

function InventorySummaryPage() {
    const { token } = theme.useToken();
    const [items, setItems] = useState([]);
    const [statistic, setStatistic] = useState({});
    const [paging, setPaging] = useState({ page: 1, pageSize: 10 });
    const [groupItem, setGroupItem] = useState(false);
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState({ info: "" });
    const [sorts, setSorts] = useState({ name: 1 });

    const [collapseExpandKey, setCollapseExpandKey] = useState(["FOLDERS"]);
    const [showFilterFolder, setShowFilterFolder] = useState(false);
    const [activeFilters, setActiveFilters] = useState(0);

    const { showExportModal, folderList, customFieldList,rootFolder } = useAppContext();
    const navigate = useNavigate();

    const debouncedLoadData = useCallback(
        _.debounce(() => {
            loadData();
        }, 500),
        [paging, groupItem, sorts]
    );

    useEffect(() => {
        setPaging((prev) => ({ ...prev, page: 1 }));
        debouncedLoadData();
    }, [filters]);

    useEffect(() => {
        debouncedLoadData();

        return () => {
            debouncedLoadData.cancel();
        };
    }, [debouncedLoadData]);

    const loadData = async (
        query = {
            info: filters.info,
            folders: filters.folders,
            quantity: filters.quantity,
            lowstock_level: filters.lowstock_level,
            price: filters.price,
            lowstock_alert: filters.lowstock_alert,
            lowstock: filters.lowstock,
            outof_stock: filters.outof_stock,
            tags: filters.tags,
        },
        sort = {
            ...sorts,
        },
        option = { group_item: groupItem, paging: { page: paging.page, page_size: paging.pageSize } }
    ) => {
        setLoading(true);

        try {
            let custom_field = {};
            Object.keys(filters).forEach((key) => {
                if (key.startsWith("filter_customField_") && filters[key]) {
                    const newKey = key.slice(19);
                    custom_field[newKey] = filters[key];
                }

                if (key.startsWith("filter_multi_customField_") && filters[key]) {
                    const newKey = key.slice(25);
                    custom_field[newKey] = filters[key];
                }
            });

            const result = await itemListInventoryService.list({ ...query, custom_field }, sort, option);

            if (groupItem) {
                const groupedItems = await handleGroupItems(result.items);

                setItems(groupedItems);
            } else {
                setItems(result.items);
            }
            setStatistic(result.statistic);
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    const handleGroupItems = async (items) => {
        items.forEach((item, index) => {
            if (item.has_variants) {
                item.children = [];
                item.key = index;
            }
        });
        return items;
    };

    const defaultColumns = ["name", "folder", "price", "quantity", "total_amount", "tags"];
    const [visibleColumns, setVisibleColumns] = useState(defaultColumns);

    const [viewType, setViewType] = useState("table");

    useEffect(() => {
        const getTableInventorySetting = async () => {
            try {
                const getSetting = await userSettingServices.get({});
                const screenSetting = getSetting["list_items_setting"];

                // --------- Điều kiện này để tạm để xóa value cũ
                if (Array.isArray(screenSetting)) {
                    const value = {};
                    await userSettingServices.save({ key: "list_items_setting", value });
                }
                // ------------------------------------------------

                console.log({ screenSetting });
                if (screenSetting.column && Array.isArray(screenSetting.column)) {
                    setVisibleColumns([...screenSetting.column]);
                }

                if (screenSetting.view) {
                    setViewType(screenSetting.view);
                }

                if (Object.keys(screenSetting.sort).length > 0) {
                    setSorts({ ...screenSetting.sort });
                }
            } catch (error) {
                console.error("Failed to load user settings:", error);
            }
        };

        getTableInventorySetting();
    }, []);

    const setTableInventorySetting = async (value) => {
        try {
            await userSettingServices.save({ key: "list_items_setting", value });
        } catch (error) {
            console.error("Failed to save user settings:", error);
        }
    };

    const handleColumnToggle = (key) => {
        setVisibleColumns((prev) => {
            let value;
            if (prev.includes(key)) {
                value = prev.filter((col) => col !== key);
            } else {
                value = [...prev, key];
            }

            setTableInventorySetting({
                column: value,
                view: viewType,
                sort: sorts,
            });

            return value;
        });
    };

    const columnMenu = [
        ...customFieldList.map((field) => ({
            key: `CustomField_${field.id}`,
            title: field.name,
        })),
    ].map((column) => ({
        key: column.key,
        label: (
            <Flex
                gap={10}
                onClick={(e) => {
                    e.stopPropagation();
                    handleColumnToggle(column.key);
                }}
            >
                <Checkbox checked={visibleColumns.includes(column.key)} />
                <span>{column.title}</span>
            </Flex>
        ),
    }));

    const handleViewChange = (key) => {
        setViewType(key);
        setTableInventorySetting({
            view: key,
            column: visibleColumns,
            sort: sorts,
        });
    };

    const viewMenu = [
        {
            key: "table",
            title: "Table View",
        },
        {
            key: "grid",
            title: "Grid View",
        },
    ].map((view) => ({
        key: view.key,
        label: (
            <Flex
                gap={10}
                onClick={(e) => {
                    e.stopPropagation();
                    handleViewChange(view.key);
                }}
            >
                <Radio checked={viewType === view.key} />
                <span>{view.title}</span>
            </Flex>
        ),
    }));

    const iconStyle = { fontSize: 14, color: token.colorPrimary };
    const renderIconSort = (currentKey) => {
        if (!sorts) {
            return null;
        }

        // type = 1 || -1
        const renderSort = (type) => {
            if (type === 1) {
                return <Icon.ArrowUpOutlined style={{ ...iconStyle }} />;
            } else if (type === -1) {
                return <Icon.ArrowDownOutlined style={{ ...iconStyle }} />;
            }
        };

        const key = Object.keys(sorts)[0];
        if (key === currentKey) {
            return renderSort(Object.values(sorts)[0]);
        }

        return null;
    };

    const sortMenu = [
        {
            key: "name",
            title: "Name",
        },
        {
            key: "stock.folder_id",
            title: "Folder",
        },
        {
            key: "quantity",
            title: "Quantity",
        },
        {
            key: "price",
            title: "Price",
        },
        {
            key: "stock.total_amount",
            title: "Total Amount",
        },
    ].map((sort) => ({
        key: sort.key,
        label: (
            <Flex gap={10}>
                <span>{sort.title}</span>
                {renderIconSort(sort.key)}
            </Flex>
        ),
    }));

    const sortMenuClick = ({ item, key, keyPath }) => {
        const currentSortValue = Object.values(sorts)[0];
        setSorts({
            [key]: currentSortValue === 1 ? -1 : 1,
        });

        setTableInventorySetting({
            sort: {
                [key]: currentSortValue === 1 ? -1 : 1,
            },
            view: key,
            column: visibleColumns,
        });
    };

    return (
        <div>
            {showFilterFolder && (
                <Filter
                    filters={filters}
                    setFilters={setFilters}
                    setShowFilterFolder={setShowFilterFolder}
                    collapseExpandKey={collapseExpandKey}
                    setCollapseExpandKey={setCollapseExpandKey}
                    activeFilters={activeFilters}
                    setActiveFilters={setActiveFilters}
                />
            )}

            <Flex justify="space-between" align="center">
                <h1>Items</h1>

                <Button type="primary" onClick={e => navigate(`/item/add/${rootFolder.id}`)}>ADD ITEM</Button>
            </Flex>

            <Divider />
            <Card>
                <Flex align="center" justify="space-between">
                    <Flex gap={16} style={{ flexGrow: 1, maxWidth: 700 }}>
                        <InputSearchBarcode filters={filters} setFilters={setFilters} />

                        <Badge count={activeFilters}>
                            <Button icon={<Icon.FilterOutlined />} onClick={() => setShowFilterFolder(true)}>
                                Filter
                            </Button>
                        </Badge>

                        <Dropdown
                            menu={{
                                items: columnMenu,
                            }}
                            trigger={["click"]}
                        >
                            <Button icon={<Icon.FontColorsOutlined />}> Columns</Button>
                        </Dropdown>

                        <Dropdown
                            menu={{
                                items: viewMenu,
                            }}
                            trigger={["click"]}
                        >
                            <Button icon={<Icon.DesktopOutlined />}> View</Button>
                        </Dropdown>

                        <Dropdown
                            menu={{
                                items: sortMenu,
                                onClick: sortMenuClick,
                            }}
                            trigger={["click"]}
                        >
                            <Button icon={<Icon.SortAscendingOutlined />}> Sort</Button>
                            {/* <CustomButtonLink onClick={(e) => e.preventDefault()}>
                                <Space>
                                    Sort
                                    <Icon.DownOutlined />
                                </Space>
                            </CustomButtonLink> */}
                        </Dropdown>
                    </Flex>

                    <Flex gap={10}>
                        <span>Group Items</span>
                        <Switch onChange={(e) => setGroupItem(e)} />

                    </Flex>
                </Flex>

                <Flex gap={50} style={{ marginTop: token.margin }}>
                    {loading ? (
                        <Flex vertical gap={12} style={{ width: "100%" }}>
                            <Skeleton.Input block={true} active size="small" />
                            <Skeleton.Input block={true} active size="small" />
                        </Flex>
                    ) : (
                        statistic &&
                        Object.keys(statistic).length > 0 && (
                            <>
                                <Flex vertical>
                                    <span style={{ fontSize: token.fontSizeHeading5 }}>Total Items</span>
                                    <span style={{ fontSize: token.fontSizeHeading3 }}>{Number.formatInt(statistic.rows)}</span>
                                </Flex>

                                <Flex vertical>
                                    <span style={{ fontSize: token.fontSizeHeading5 }}>Total Quantity</span>
                                    <span style={{ fontSize: token.fontSizeHeading3 }}>{Number.formatInt(statistic.total_quantity)}</span>
                                </Flex>

                                <Flex vertical>
                                    <span style={{ fontSize: token.fontSizeHeading5 }}>Total Value</span>
                                    <span style={{ fontSize: token.fontSizeHeading3 }}>{Number.format(statistic.total_amount)}</span>
                                </Flex>
                            </>
                        )
                    )}
                </Flex>
            </Card>
            <Card style={{ marginTop: token.margin }}>
                {viewType === "table" ? (
                    <TableListItem visibleColumns={visibleColumns} loading={loading} items={items} paging={paging} setPaging={setPaging} statistic={statistic} setSorts={setSorts} />
                ) : (
                    <GridListItem loading={loading} items={items} paging={paging} setPaging={setPaging} statistic={statistic} />
                )}
            </Card>
        </div>
    );
}

export default InventorySummaryPage;
