import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Input, Radio, Row, Col, Checkbox, Space, Divider, Layout, theme, InputNumber, Switch, Card, Modal } from "antd";
import * as Icon from "@ant-design/icons";
import * as Picker from "components/pickers";
import * as Buttons from "components/Buttons";
import VariantsPicker from "./variants_picker";
import StickyBar from "components/sticky-bar";
import ItemService from "services/item";
import { useLoading } from "context/loading";
import CustomFieldService from "services/custom_field";
import InputCustomField from "components/CustomField/InputCustomField";
import { useNotification } from "context/notification";
import _ from "lodash";
import * as field_type_const from "components/CustomField/constants";
import { BrowserRouter, useNavigate } from "react-router-dom";
import FolderService from "services/folder";
import ItemAlertService from "services/item/ItemAlert";
import { useItemsScreenContext } from "pages/items";
import { useAppContext } from "context/app";
import AddCustomFieldModal from "components/CustomField/AddOrEditCustomFieldModal";
const { useToken } = theme;
const service = new ItemService();
const folderService = new FolderService();
const itemAlertService = new ItemAlertService();

const ItemEditor = ({ data }) => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const { token } = useToken();
    const quantity = Form.useWatch("quantity", { form });
    const price = Form.useWatch("price", { form });
    const has_variants = Form.useWatch("has_variants", { form });
    const has_customField = Form.useWatch("has_customField", { form });
    const total_value = (quantity ?? 0) * (price ?? 0);
    const [step, setStep] = useState(0);
    const itemNameInputRef = useRef();
    const [listCustomField, setListCustomField] = useState([]);
    const [showModalCustomField, setShowModalCustomField] = useState(false);

    const { showLoading, dismissLoading } = useLoading();
    const { success, error } = useNotification();
    const [submittable, setSubmittable] = useState(false);

    const values = Form.useWatch([], form);
    const [formErrors, setFormErrors] = useState([]);

    React.useEffect(() => {
        form.setFieldsValue(data);
        console.log(data);
        setStep(0);
    }, [data]);

    React.useEffect(() => {
        form.validateFields({ validateOnly: true })
            .then(() => {
                setSubmittable(true);
                setFormErrors([]);
            })
            .catch((error) => {
                if (error.errorFields && error.errorFields.length > 0) {
                    setFormErrors(error.errorFields);
                    setSubmittable(false);
                } else {
                    setSubmittable(true);
                    setFormErrors([]);
                }
            });
    }, [form, values]);

    useEffect(() => {
        setStep(0);
        itemNameInputRef.current.focus();
    }, []);

    useEffect(() => {
        fetchListCustomField();
    }, []);

    const fetchListCustomField = async () => {
        const customFieldService = new CustomFieldService();
        const result = await customFieldService.get();
        setListCustomField(result);
    };

    const onChange = () => {
        setShowModalCustomField(false);
        fetchListCustomField();
    };

    const onFinish = async (data) => {
        showLoading("Saving");
        console.log("data", data);
        if (data.id) {
            var item = await service.updateGeneralInfomation(data);
            success(`${data.name} has been successfully updated`);
        }

        dismissLoading();
    };

    const submitForm = () => {
        form.submit();
    };

    return (
        <div>
            <AddCustomFieldModal
                visible={showModalCustomField}
                onClose={() => {
                    setShowModalCustomField(false);
                }}
                onChange={onChange}
            />

            <Form layout="vertical" form={form} initialValues={{}} onFinish={onFinish}>
                <StickyBar>
                    <div
                        style={{
                            flexGrow: 1,
                        }}
                    >
                        <h1>Edit Item</h1>
                    </div>

                    <div
                        style={{
                            alignContent: "center",
                        }}
                    >
                        <Space size="middle">
                            <div style={{ height: "100%", display: "flex", justifyContent: "center" }}></div>
                            <Button onClick={(e) => setStep(0)}>Back</Button>
                            <Buttons.SaveButton onClick={submitForm} disabled={!submittable}></Buttons.SaveButton>
                        </Space>
                    </div>
                </StickyBar>
                <Divider></Divider>
                {/* <div>
        {JSON.stringify(formErrors)}
      </div> */}

                <div style={{ display: step == 1 ? "none" : "block" }}>
                    <Row gutter={16}>
                        <Col span={16}>
                            <Form.Item name="id" hidden></Form.Item>

                            <Form.Item
                                label="Name"
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input placeholder="Item Name" size="large" ref={itemNameInputRef} />
                            </Form.Item>

                            <Row gutter={16}>
                                <Col>
                                    <Form.Item label="Stock Alert">
                                        <EditLowStockModal item={data}></EditLowStockModal>
                                    </Form.Item>
                                </Col>
                            </Row>

                            {!data.has_variants && (
                                <>
                                    <Row gutter={16}>
                                        <Col>
                                            <Form.Item label="Price" name="price">
                                                <InputNumber placeholder="Price" min={0} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </>
                            )}

                            <Form.Item label="Tags" name="tags">
                                <Picker.Tags></Picker.Tags>
                            </Form.Item>

                            <Form.Item label="Notes" name="notes">
                                <Input.TextArea placeholder="Notes" />
                            </Form.Item>

                            <Form.Item label="Qr/Bar Code" name="code">
                                <Picker.QrCode></Picker.QrCode>
                            </Form.Item>

                            <Row>
                                <Col>
                                    <Form.Item label="This item has variants" layout="horizontal" name="has_variants" valuePropName="checked">
                                        <Switch disabled></Switch>
                                    </Form.Item>
                                </Col>
                                <Col>
                                    {data.has_variants && (
                                        <Form.Item layout="horizontal">
                                            <Button type="link" onClick={(e) => navigate(`/item/variants/${data.id}`)}>
                                                {" "}
                                                Edit Variants
                                            </Button>
                                        </Form.Item>
                                    )}
                                </Col>
                            </Row>

                            <Form.Item label="Custom Fields" layout="horizontal" name="has_customField" valuePropName="checked">
                                <Switch></Switch>
                            </Form.Item>

                            {listCustomField.map((item) => (
                                <Form.Item
                                    name={["custom_field", item.id]}
                                    hidden={!has_customField}
                                    label={item.field_type === field_type_const.ROUND_NUMBER ? `${item.name}, ${item.round_number_unit}` : item.name}
                                    layout={item.field_type === field_type_const.CHECK_BOX ? "horizontal" : "vertical"}
                                >
                                    <InputCustomField item={item} />
                                </Form.Item>
                            ))}

                            {has_customField && listCustomField.length == 0 && (
                                <>
                                    <Card>
                                        <Space>
                                            <div>
                                                Add custom fields to store specific information for your items, like serial numbers, expiration dates, or any unique attribute your inventory needs.
                                            </div>
                                            <div>
                                                <Button
                                                    type="link"
                                                    onClick={() => {
                                                        setShowModalCustomField(true);
                                                    }}
                                                >
                                                    Add Custom Field
                                                </Button>
                                            </div>
                                        </Space>
                                    </Card>
                                </>
                            )}
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Photos" name="photos">
                                <Picker.MultiImagePicker maxPhotos={2} acceptedFormats={["image/jpeg", "image/png"]} maxFileSize={5242880}></Picker.MultiImagePicker>
                            </Form.Item>
                        </Col>
                    </Row>
                </div>

                <div></div>
            </Form>
        </div>
    );
};

const EditLowStockModal = ({ item }) => {
    const { showLoading, dismissLoading } = useLoading();
    const { success, error } = useNotification();
    const { eventBus, on, folderList } = useAppContext();
    const [open, setOpen] = useState(false);
    const [lstFolders, setLstFolders] = useState([]);
    const [form] = Form.useForm();

    useEffect(() => {
        if (open && item) {
            const handleItem = async () => {
                showLoading();

                //  const result = await Promise.all(folderPromises);
                var result = [];
                var stock = item?.stock || [];
                for (let index = 0; index < stock.length; index++) {
                    const element = stock[index];
                    const folder = await folderList.find((x) => x.id == element.folder_id);

                    if (folder) {
                        result.push({ ...folder });
                    }
                }

                setLstFolders(result);

                result.forEach((folder) => {
                    const alert = item.quantity_alerts?.find((itemAlert) => itemAlert.folder_id === folder.id);

                    if (alert) {
                        form.setFieldsValue({
                            [`alert_${folder.id}`]: true,
                            [`min_level_${folder.id}`]: alert.quantity,
                        });
                    } else {
                        form.setFieldsValue({
                            [`alert_${folder.id}`]: false,
                        });
                    }
                });

                dismissLoading();
            };

            handleItem();
        }
    }, [open]);

    const getOnHandQuantity = (folder) => {
        for (var stock of item.stock) {
            if (stock.folder_id === folder.id) {
                return stock.quantity;
            }
        }
    };

    const handleChangeMultiMinLevel = (value) => {
        console.log({ value });
        lstFolders.forEach((folder) => {
            form.setFieldsValue({
                [`min_level_${folder.id}`]: value,
            });
        });
    };

    const handleChangeMultiAlert = (value) => {
        lstFolders.forEach((folder) => {
            form.setFieldsValue({
                [`alert_${folder.id}`]: value,
            });
        });
    };

    const handleChangeMinLevel = (value) => {
        form.setFieldsValue({
            multi_min_level: null,
        });
    };

    const handleSave = async () => {
        showLoading();
        try {
            console.log(form.getFieldsValue());

            let lstFoldersAddAlert = [];
            let lstFoldersRemoveAlert = [];

            lstFolders.forEach((folder) => {
                const alertValue = form.getFieldValue([`alert_${folder.id}`]);
                const quantity = form.getFieldValue([`min_level_${folder.id}`]);

                if (alertValue && quantity) {
                    lstFoldersAddAlert.push({
                        item_id: item.id,
                        alert: {
                            folder_id: folder.id,
                            quantity: quantity,
                        },
                    });
                } else if (!alertValue) {
                    lstFoldersRemoveAlert.push({
                        item_id: item.id,
                        folder_id: folder.id,
                    });
                }
            });

            await itemAlertService.bulkSetLowStockAlert(lstFoldersAddAlert);
            await itemAlertService.bulkRemoveLowStockAlert(lstFoldersRemoveAlert);

            eventBus.emit("item_changed", {});

            success(`Alert has been successfully updated`);
        } catch (err) {
            error(`Failed to alert. Please try again later.`);
            console.log(err);
        } finally {
            resetValue();
            dismissLoading();
        }
    };

    const resetValue = () => {
        form.resetFields();
        setLstFolders([]);
        setOpen(false);
    };
    return (
        <>
            <Buttons.CustomButtonLink type="link" onClick={(e) => setOpen(true)}>
                Edit Low Stock Alert
            </Buttons.CustomButtonLink>
            <Modal width={800} open={open} onCancel={resetValue} title=" Low Stock Alert" maskClosable={false} footer={<Buttons.SaveButton onClick={handleSave} />}>
                <Form form={form} layout="vertical">
                    <Row gutter={20} style={{ padding: "10px 0", fontWeight: "500" }}>
                        <Col span={10}>Folder ({lstFolders.length})</Col>
                        <Col span={6}>On Hand Quantity</Col>
                        <Col span={6}>Min Quantity</Col>
                        <Col span={2}>Alert</Col>
                    </Row>

                    <Row gutter={20}>
                        <Col span={10}></Col>
                        <Col span={6}></Col>
                        <Col span={6}>
                            <Form.Item style={{ margin: 0 }} label="" name="multi_min_level">
                                <InputNumber placeholder="Min Level" min={1} style={{ width: "100%" }} onChange={handleChangeMultiMinLevel}></InputNumber>
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <Form.Item style={{ margin: 0 }} label="" name="multi_alert">
                                <Switch onChange={handleChangeMultiAlert}></Switch>
                            </Form.Item>
                        </Col>
                    </Row>

                    {lstFolders?.map((folder) => (
                        <div key={folder.id}>
                            <Divider style={{ margin: "12px 0" }} />
                            <Row gutter={20}>
                                <Col span={10}>{folder.name}</Col>
                                <Col span={6}>{getOnHandQuantity(folder)}</Col>
                                <Col span={6}>
                                    <Form.Item style={{ margin: 0 }} label="" name={`min_level_${folder.id}`}>
                                        <InputNumber placeholder="Min Level" min={1} style={{ width: "100%" }} onChange={handleChangeMinLevel} />
                                    </Form.Item>
                                </Col>
                                <Col span={2}>
                                    <Form.Item style={{ margin: 0 }} label="" name={`alert_${folder.id}`}>
                                        <Switch></Switch>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                    ))}
                </Form>

                <Divider />
            </Modal>
        </>
    );
};

export default ItemEditor;
