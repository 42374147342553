import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Input, Radio, Row, Col, Checkbox, Space, Divider, Layout, theme, InputNumber, Switch, Card } from "antd";
import * as Icon from "@ant-design/icons";
import * as Picker from "components/pickers";
import * as Buttons from "components/Buttons";
import AlertPicker from "components/pickers/alert";
import VariantsPicker from "./variants_picker";
import StickyBar from "components/sticky-bar";
import ItemService from "services/item";
import { useLoading } from "context/loading";
import CustomFieldService from "services/custom_field";
import InputCustomField from "components/CustomField/InputCustomField";
import { useNotification } from "context/notification";
import _ from "lodash";
import * as field_type_const from "components/CustomField/constants";
import { BrowserRouter, useNavigate, useParams } from "react-router-dom";
import AddCustomFieldModal from "components/CustomField/AddOrEditCustomFieldModal";
const { useToken } = theme;
const service = new ItemService();

const ItemEditor = ({ data }) => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const { token } = useToken();
    const name = Form.useWatch("name", { form });
    const variant = Form.useWatch("variant", { form });
    const quantity = Form.useWatch("quantity", { form });
    const price = Form.useWatch("price", { form });
    const has_variants = Form.useWatch("has_variants", { form });
    const has_customField = Form.useWatch("has_customField", { form });
    const total_value = (quantity ?? 0) * (price ?? 0);
    const [step, setStep] = useState(0);
    const itemNameInputRef = useRef();
    const [listCustomField, setListCustomField] = useState([]);
    const [showModalCustomField, setShowModalCustomField] = useState(false);

    const { showLoading, dismissLoading } = useLoading();
    const { success, error } = useNotification();
    const [submittable, setSubmittable] = useState(false);

    const params = useParams();
    useEffect(() => {
        form.setFieldsValue({ ...data, folder_id: params.folder_id, quantity: 1, price: 0, min_stock_level: 0 });
        setStep(0);
    }, [data, params]);

    useEffect(() => {
        checkErrors();
    }, [name, has_variants, variant]);

    const checkErrors = async () => {
        console.log({ name, variant });
        try {
            let isValid = true;

            if (!name) {
                isValid = false;
            }

            if (has_variants) {
                const variant = form.getFieldValue("variant");
                if (!variant || variant.attributes.length === 0) {
                    isValid = false;
                } else {
                    // Kiểm tra xem có options nào rỗng không
                    for (const attr of variant.attributes) {
                        if (attr.options.length === 0) {
                            isValid = false;
                            break;
                        }
                    }
                }
            }

            setSubmittable(isValid);

            await form.validateFields();
        } catch (error) {
            setSubmittable(false);
        }
    };

    useEffect(() => {
        setStep(0);
        itemNameInputRef.current.focus();
    }, []);

    useEffect(() => {
        fetchListCustomField();
    }, []);

    const fetchListCustomField = async () => {
        const customFieldService = new CustomFieldService();
        const result = await customFieldService.get();

        setListCustomField(result);
    };

    const onFinish = async (data) => {
        showLoading("Saving");

        var item = await service.add(data);
        success(`${data.name} has been successfully created`);
        navigate(`/folder/${data.folder_id}`);
        //navigate(-1);
        dismissLoading();
    };

    // const getFieldError = (namePath) => {
    //   var error = formErrors.find(x => _.isEqual(x.name, namePath));
    //   // console.log("getFieldError", error, "ok", namePath, formErrors)

    //   return error;
    // }

    // const getVariantError = () => {
    //   var error = getFieldError(['variant']);
    //   if (!error)
    //     return {};
    //   var error = {};

    //   if (has_variants) {

    //     var formVariant = form.getFieldValue(["variant"]);
    //     // console.log("formVariant",formVariant)
    //     if (!formVariant)
    //       formVariant = { attributes: [] }

    //     if (formVariant.attributes.length == 0) {
    //       error.title = `Attribute can't be blank`;

    //     } else {

    //       var options = [];
    //       for (let index = 0; index < formVariant.attributes.length; index++) {
    //         const element = formVariant.attributes[index];
    //         if (element.options.length == 0) {

    //           error.title = ``;
    //           options.push('error');
    //         } else {
    //           options.push('none');
    //         }

    //       }
    //       error.options = options;

    //     }
    //   }
    //   return error;

    // }

    // const variantValidate = (_, value) => {
    //   try {

    //     if (has_variants) {

    //       var formVariant = form.getFieldValue(["variant"]);
    //       if (!formVariant)
    //         formVariant = { attributes: [] }

    //       if (formVariant.attributes.length == 0) {
    //         return Promise.reject(new Error(``));
    //       } else {
    //         for (let index = 0; index < formVariant.attributes.length; index++) {
    //           const element = formVariant.attributes[index];
    //           if (element.options.length == 0) {
    //             return Promise.reject(new Error(``));
    //           }

    //         }

    //       }
    //     }

    //   } catch (error) {
    //     console.log("valieat error", error)
    //   }

    //   return Promise.resolve();

    // }

    const nextStep = () => {
        setStep(1);
    };

    const submitForm = () => {
        form.submit();
    };

    const onChange = () => {
        setShowModalCustomField(false);
        fetchListCustomField();
    };

    return (
        <div>
            <AddCustomFieldModal
                visible={showModalCustomField}
                onClose={() => {
                    setShowModalCustomField(false);
                }}
                onChange={onChange}
            />

            <Form layout="vertical" form={form} initialValues={{}} onFinish={onFinish}>
                <StickyBar>
                    <div
                        style={{
                            flexGrow: 1,
                        }}
                    >
                        <h1>Add Item</h1>
                    </div>

                    <div
                        style={{
                            alignContent: "center",
                        }}
                    >
                        <Space size="middle">
                            <div style={{ height: "100%", display: "flex", justifyContent: "center" }}>
                                <Form.Item name="folder_id" layout="horizontal" label="Add To" noStyle>
                                    <Picker.Folder placeholder=""></Picker.Folder>
                                </Form.Item>
                            </div>
                            {has_variants && step == 0 ? (
                                <>
                                    <Buttons.CancelButton></Buttons.CancelButton>
                                    <Button type="primary" onClick={nextStep} disabled={!submittable}>
                                        Next
                                    </Button>
                                </>
                            ) : (
                                <>
                                    <Button onClick={(e) => setStep(0)}>Back</Button>
                                    <Buttons.SaveButton onClick={submitForm} disabled={!submittable}></Buttons.SaveButton>
                                </>
                            )}
                        </Space>
                    </div>
                </StickyBar>
                <Divider></Divider>
                {/* <div>
        {JSON.stringify(formErrors)}
      </div> */}

                <div style={{ display: step == 1 ? "none" : "block" }}>
                    <Row gutter={16}>
                        <Col span={16}>
                            <Form.Item name="id" hidden></Form.Item>

                            <Form.Item
                                label="Name"
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input placeholder="Item Name" size="large" ref={itemNameInputRef} />
                            </Form.Item>
                            <Row gutter={16}>
                                <Col>
                                    <Form.Item
                                        label="Quantity"
                                        name="quantity"
                                        rules={[
                                            {
                                                required: true,
                                                type: "number",
                                                min: 1,
                                            },
                                        ]}
                                    >
                                        <InputNumber placeholder="Quantity" min={1} />
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <Form.Item label="Min Level" name="min_stock_level">
                                        <InputNumber placeholder="Min Level" min={0} />
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <Form.Item label="Low Stock Alert" name="lowstock_alert">
                                        <Switch></Switch>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col>
                                    <Form.Item label="Price" name="price">
                                        <InputNumber placeholder="Price" min={0} />
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <Form.Item label="Total Value">
                                        <InputNumber placeholder="Total Value" value={total_value} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Form.Item label="Tags" name="tags">
                                <Picker.Tags></Picker.Tags>
                            </Form.Item>

                            <Form.Item label="Notes" name="notes">
                                <Input.TextArea placeholder="Notes" />
                            </Form.Item>
                            <Form.Item label="Qr/Bar Code" name="code">
                                <Picker.QrCode></Picker.QrCode>
                            </Form.Item>
                            <Form.Item label="This item has variants" layout="horizontal" name="has_variants" valuePropName="checked">
                                <Switch></Switch>
                            </Form.Item>

                            <Form.Item
                                name="variant"
                                hidden={!has_variants}
                                rules={[
                                    {
                                        validator: (_, value) => {
                                            if (has_variants && (!value || value.attributes.length === 0)) {
                                                return Promise.reject(new Error("Attribute can't be blank!"));
                                            }
                                            return Promise.resolve();
                                        },
                                    },
                                ]}
                                shouldUpdate={true}
                            >
                                <VariantsPicker></VariantsPicker>
                            </Form.Item>

                            <Form.Item label="Custom Fields" layout="horizontal" name="has_customField" valuePropName="checked">
                                <Switch></Switch>
                            </Form.Item>

                            {listCustomField.map((item) => (
                                <Form.Item
                                    name={["custom_field", item.id]}
                                    hidden={!has_customField}
                                    label={item.field_type === field_type_const.ROUND_NUMBER ? `${item.name}, ${item.round_number_unit}` : item.name}
                                    layout={item.field_type === field_type_const.CHECK_BOX ? "horizontal" : "vertical"}
                                >
                                    <InputCustomField item={item} />
                                </Form.Item>
                            ))}

                            {has_customField && listCustomField.length == 0 && (
                                <>
                                    <Card>
                                        <Space>
                                            <div>
                                                Add custom fields to store specific information for your items, like serial numbers, expiration dates, or any unique attribute your inventory needs.
                                            </div>
                                            <div>
                                                <Button
                                                    type="link"
                                                    onClick={() => {
                                                        setShowModalCustomField(true);
                                                    }}
                                                >
                                                    Add Custom Field
                                                </Button>
                                            </div>
                                        </Space>
                                    </Card>
                                </>
                            )}
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Photos" name="photos">
                                <Picker.MultiImagePicker maxPhotos={2} acceptedFormats={["image/jpeg", "image/png"]} maxFileSize={5242880}></Picker.MultiImagePicker>
                            </Form.Item>
                        </Col>
                    </Row>
                </div>

                <div style={{ display: step == 1 ? "block" : "none" }}>
                    <Form.Item name="variant" hidden={!has_variants}>
                        <VariantsPicker showProducts={true}></VariantsPicker>
                    </Form.Item>
                </div>
            </Form>
        </div>
    );
};

export default ItemEditor;
