import BaseService from "services/base";
import _ from "lodash";

class UserService extends BaseService {
    list = async ({ info } = {}) => {
        var data = await this._api.post("user/list", { info });
        return data;
    };

    add = async ({ user_name, pass, role, email, first_name, last_name }) => {
        var data = await this._api.post("user/add", { user_name, pass, role, email, first_name, last_name });
        return data;
    };

    delete = async (id) => {
        var data = await this._api.post("user/delete", { id: id });
        return data;
    };

    updateRole = async ({ id, role }) => {
        var data = await this._api.post("user/updateRole", { id: id, role: role });
        return data;
    };
}
export default UserService;
