import React, { useState, createContext, useContext, useEffect } from 'react';
import * as Icon from "@ant-design/icons";
import { Breadcrumb, Layout, Menu, theme } from 'antd';
import PageRoutes from "./PageRoutes"
import { GoogleOAuthProvider } from '@react-oauth/google';
import { BrowserRouter, useLocation, useNavigate } from "react-router-dom";

const { Header, Content, Footer, Sider } = Layout;

const AppLayoutContext = createContext();


const MainLayout = () => {
  const [menuCollapsed, setMenuCollapsed] = useState(false);
  const {
    token
  } = theme.useToken();
  const navigate = useNavigate();

  const location = useLocation();
  useEffect(() => {
    console.log(" location.pathname", location.pathname)

    var isLoginPages = ["/login", "/reset-password", "/register", "/thank-you"].includes(location.pathname);
    isLoginPages = true;
    if (!isLoginPages) {
      //navigate login page
      navigate("/login");
    }
  }, [])

  return (
    <div>
      <PageRoutes></PageRoutes>
    </div>
  );
};

const useAppLayoutContext = () => {
  return useContext(AppLayoutContext);
}


const App = () => {
  return (
    <BrowserRouter basename="/">

      <GoogleOAuthProvider clientId="837222016155-eeekicp7tp7ngf14u44gko93l7i3h1li.apps.googleusercontent.com">
        <MainLayout></MainLayout>
      </GoogleOAuthProvider>
    </BrowserRouter>
  )
}
export { useAppLayoutContext }
export default App;