import * as  React from "react"
import "./style.css"
const AppLoader = () => {
    return (
        <div style={{ width: "100wh", height: "100vh",justifyContent: "center", display: "flex", paddingTop: 60 }}>


            <div className="lds-facebook"><div></div><div></div><div></div> </div>
        </div>
    )
}


export default React.memo(AppLoader);