import { Flex, Form, InputNumber, Modal, Select, theme, Button, Space, Input } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import { useEffect, useState } from "react";
import { useLoading } from "context/loading";

import FolderService from "services/folder";
import ItemService from "services/item";
import { useAppContext } from "context/app";
import { SaveButton } from "components/Buttons";
import { useNotification } from "context/notification";
import { useNavigate } from "react-router-dom";
import * as Picker from "components/pickers";

const itemService = new ItemService();
const folderService = new FolderService();

function CloneModal({ items, folderId, visible, onClose }) {
    const { useToken } = theme;
    const { token } = useToken();

    const navigate = useNavigate();
    const { showLoading, dismissLoading } = useLoading();
    const { success, error } = useNotification();
    const [form] = Form.useForm();
    const { eventBus, folderList } = useAppContext();
    const [disableSave, setDisableSave] = useState(true);

    const to_folder_id = Form.useWatch("to_folder_id", form);

    useEffect(() => {
        if (items.length === 1) {
            form.setFieldsValue({
                name: `${items[0].name} (Copy)`,
                number_clone: 1,
            });
        }
    }, [items]);

    useEffect(() => {
        if (to_folder_id) {
            setDisableSave(false);
        } else {
            setDisableSave(true);
        }
    }, [to_folder_id]);

    const renderCount = () => {
        const itemCounts = items.reduce(
            (counts, item) => {
                if (item.entity_type === "item") {
                    counts.countItem += 1;
                } else if (item.entity_type === "folder") {
                    counts.countFolders += 1;
                }
                return counts;
            },
            { countItem: 0, countFolders: 0 }
        );

        const { countItem, countFolders } = itemCounts;

        console.log({ countItem, countFolders });

        if (countItem > 0 && countFolders > 0) {
            return (
                <span>
                    {countFolders} Folders & {countItem} Items
                </span>
            );
        }

        if (countItem > 0) {
            return <span>{countItem} Items</span>;
        }

        if (countFolders > 0) {
            return <span>{countFolders} Folders</span>;
        }
    };

    const handleSave = () => {
        form.submit();
    };

    console.log({ items });

    const handleFinish = async () => {
        showLoading();

        try {
            const data = form.getFieldsValue();

            if (items.length === 1) {
                const { id, ...itemRemoveId } = items[0];
                if (items[0].entity_type === "item") {
                    await itemService.add({ ...itemRemoveId, name: data.name, quantity: data.number_clone, folder_id: to_folder_id });
                } else if (items[0].entity_type === "folder") {
                    await folderService.add({ ...itemRemoveId, name: data.name, parent_id: to_folder_id });
                }
            } else {
                const resultPromises = items.map(async (item) => {
                    const { id, ...itemRemoveId } = item;

                    if (item.entity_type === "item") { 
                        await itemService.add({ ...itemRemoveId, folder_id: folderId });
                    } else if (item.entity_type === "folder") {
                        await folderService.add(itemRemoveId);
                    }
                });

                await Promise.all(resultPromises);
            }

            success(`Clone has been successfully updated`);
            eventBus.emit("item_changed", { folder_id: folderId });
        } catch (err) {
            error("Failed to delete. Please try again later.");
            console.log(err);
        } finally {
            resetValue();
            dismissLoading();
        }
    };

    const resetValue = () => {
        form.resetFields();
        setDisableSave(true);
        onClose();
    };

    return (
        <Modal
            width={400}
            title={<p style={{ fontSize: token.fontSizeHeading4, textAlign: "center" }}>Clone</p>}
            open={visible}
            onCancel={() => {
                resetValue();
            }}
            maskClosable={false}
            footer={<SaveButton disabled={items.length > 1 ? false : disableSave} onClick={handleSave}></SaveButton>}
        >
            <Flex vertical gap={10} align="center">
                {items.length === 1 ? (
                    <Form form={form} layout="vertical" style={{ marginTop: token.margin, width: "100%" }} onFinish={handleFinish}>
                        <Form.Item label="Name" name="name" rules={[{ required: true }]}>
                            <Input placeholder="Name" />
                        </Form.Item>

                        <Form.Item label="Number of clones" name="number_clone" rules={[{ required: true }]} hidden={items[0].entity_type === "folder"}>
                            <InputNumber min={1} style={{ width: "100%" }} />
                        </Form.Item>

                        <Form.Item name="to_folder_id" label="Choose destination folder:">
                            <Picker.Folder style={{ width: "100%" }} placeholder=""></Picker.Folder>
                        </Form.Item>
                    </Form>
                ) : (
                    <Form form={form} layout="vertical" style={{ width: "100%" }} onFinish={handleFinish}>
                        <span style={{ fontSize: token.fontSizeHeading5, fontWeight: 500, color: token.colorError }}>Do you want to clone {renderCount()}?</span>
                    </Form>
                )}
            </Flex>
        </Modal>
    );
}

export default CloneModal;
