import { Divider, Pagination, Skeleton } from "antd";
import Item from "./Item";

function GridListItem({ items, loading, paging, setPaging, statistic }) {
    return (
        <>
            {loading ? (
                <Skeleton block={true} active />
            ) : (
                <>
                    <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                        {items.map((item) => {
                            return <Item data={item}></Item>;
                        })}
                    </div>
                    <Divider></Divider>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                        <Pagination
                            current={paging.page}
                            total={statistic.rows}
                            pageSize={paging.page_size}
                            onChange={(page, pageSize) => {
                                setPaging({ page, pageSize });
                            }}
                            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                            showSizeChanger={true}
                        />
                    </div>
                </>
            )}
        </>
    );
}

export default GridListItem;
