import { Dropdown } from "antd";
import * as Icon from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { useAppContext } from "context/app";
import * as Number from "common/number_format";
import { CustomButtonLink } from "components/Buttons";

function ListOptionItem({ data, folderId }) {
    const navigate = useNavigate();
    const { showExportModal, showCreateLabelModal, showUpdateSingleQuantityModal, showUpdateMultiQuantityModal, showMoveSingleQuantityModal, showAlertModal, showDeleteModal, showCloneModal } =
        useAppContext();

    const iconStyle = { fontSize: 18 };
    const options = [
        {
            key: "alert",
            label: <div>Alert</div>,
            icon: <Icon.BellOutlined style={{ ...iconStyle }}></Icon.BellOutlined>,
        },

        {
            key: "move",
            label: <div>Move to Folder</div>,
            icon: <Icon.FolderOutlined style={{ ...iconStyle }}></Icon.FolderOutlined>,
        },

        {
            key: "update_quantity",
            label: <div>Update Quantity</div>,
            icon: <Icon.DiffOutlined style={{ ...iconStyle }}></Icon.DiffOutlined>,
        },

        {
            key: "create_label",
            label: <div>Create Label</div>,
            icon: <Icon.BarcodeOutlined style={{ ...iconStyle }}></Icon.BarcodeOutlined>,
        },

        {
            key: "export",
            label: <div>Export</div>,
            icon: <Icon.UploadOutlined style={{ ...iconStyle }}></Icon.UploadOutlined>,
        },

        {
            key: "transaction",
            label: <div>Transactions</div>,
            icon: <Icon.AccountBookOutlined style={{ ...iconStyle }}></Icon.AccountBookOutlined>,
        },

        {
            type: "divider", // Must have
        },

        {
            key: "clone",
            label: <div>Clone</div>,
            icon: <Icon.CopyOutlined style={{ ...iconStyle }}></Icon.CopyOutlined>,
        },
        {
            key: "edit",
            label: <div>Edit</div>,
            icon: <Icon.EditOutlined style={{ ...iconStyle }}></Icon.EditOutlined>,
        },
        {
            key: "delete",
            label: <div>Delete</div>,
            icon: <Icon.DeleteOutlined style={{ ...iconStyle }}></Icon.DeleteOutlined>,
        },
    ];

    const menuClick = ({ item, key, keyPath, domEvent }) => {
        console.log({ item, key, keyPath });

        switch (key) {
            case "edit":
                navigate(`/item-edit/${data.id}`);
                break;
            case "create_label":
                const listItemsLabel = [{ ...data, entity_type: "item" }];
                showCreateLabelModal(listItemsLabel, folderId);
                break;
            case "export":
                const listItemsExport = [{ ...data, entity_type: "item" }];
                showExportModal(listItemsExport, folderId);
                break;
            case "update_quantity":
                if (data.has_variants) {
                    showUpdateMultiQuantityModal([data], folderId);
                } else {
                    showUpdateSingleQuantityModal(data, folderId);
                }
                break;
            case "move":
                showMoveSingleQuantityModal(data, folderId);
                break;
            case "alert":
                showAlertModal([data], folderId);
                break;
            case "delete":
                const listItemsDelete = [{ ...data, entity_type: "item" }];
                showDeleteModal(listItemsDelete, folderId);
                break;
            case "clone":
                const listItemsClone = [{ ...data, entity_type: "item" }];
                showCloneModal(listItemsClone, folderId);
                break;
            default:
                break;
        }
    };

    return (
        <Dropdown
            menu={{
                items: options,
                onClick: menuClick,
            }}
        >
            <CustomButtonLink size="large" style={{ padding: 0 }}>
                <Icon.MoreOutlined />
            </CustomButtonLink>
        </Dropdown>
    );
}

export default ListOptionItem;
