import BaseService from "services/base";
import _ from "lodash";

class ItemListService extends BaseService {

    /**
     * sample query object
     */

    sampleQuery = () => {
    
        var query = {
            info: "", //search text name, barcode
            forders: ["folder_id_1"],//list forder
            quantity: {
                //filter quantity
                lte: 10,//less than or equa
                gte: 100,// greater than or equa
                exact: 100//exact quantity
            },
            tags: ["tags_id"],//filter item with tag 
            lowstock_level: {//low stock level
                lte: 10,//less than or equa
                gte: 100,// greater than or equa
                exact: 100//exact quantity
            },
            lowstock_alert: {//low stock alert
                has_alert: true,//has alert or none
            }
        }
    }


    /**
     * 
     * @param {object refer sampleQuery} query 
     * @param {*} sort 
     * @param {*} option 
     * @returns 
     */
    list = async (query = {}, sort = {}, option = { group_item: false, paging: { page: 1, page_size: 10 } }) => {

        var data = await this._api.post("item/list", { query, sort, option });
        return data;
    };



}

export default ItemListService;
