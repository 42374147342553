import { useEffect, memo, useState } from "react";
import { Button, Flex, Space, Divider, Col, Row, Card, Pagination, theme, Image, Dropdown, Checkbox, Statistic } from "antd";

import * as Icon from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import DefaultImages from "assets/DefaultImage"
import * as Text from "components/Text"
import _ from "lodash"
import { useAppContext } from "context/app";
import { useItemsScreenContext } from "pages/items"
import * as Number from "common/number_format"
import Tags from "components/tag-list"
import { CustomButtonLink } from "components/Buttons";
import ListOptionItem from "pages/items/ListOptionItem";

const { useToken } = theme;
const Item = ({ data }) => {
    const navigate = useNavigate();
    const { token } = useToken();
    const [thumbnail, setThumbnail] = useState(DefaultImages.NoImageItem);
    const { showExportModal, showCreateLabelModal, showUpdateSingleQuantityModal, showUpdateMultiQuantityModal, showMoveSingleQuantityModal, showAlertModal, showDeleteModal } = useAppContext();
    const { selectedItems, selectItem, currentFolder, currentFolderPath } = useItemsScreenContext();
    const [check, setCheck] = useState(false)
    useEffect(() => {
        // var url = data.photos?[0].url;
        //
        if (data.photos && data.photos[0]) {
            setThumbnail(data.photos[0].url);
        } else {
            setThumbnail(null);
        }

        var i = selectedItems.find(x => x.id == data.id);
        if (i) {
            setCheck(true);
        } else {
            setCheck(false);
        }

    }
        , [data, selectedItems])


    




    return (
        <div
            style={{
                width: 230,
                height: 288,
                marginRight: token.margin,
                marginBottom: token.margin,
                borderRadius: 8,
                backgroundColor: token.colorBgContainer,
                borderColor: token.colorBorder,
                borderStyle: "solid",
                borderWidth: 1,
                position: "relative", overflow: "hidden"
            }}
        // onClick={e => navigate(`/item/${data.id}`)}
        >

            <div style={{ position: "absolute", top: 0, height: "100%", width: "100%", }}>
                <div style={{ position: "absolute", top: 0, width: "100%", zIndex: 2, display: "flex", justifyContent: 'space-between', alignItems: 'center', padding: '0 10px' }}>
                    <div style={{}} >

                        <Checkbox checked={check} onChange={e => selectItem({ ...data, entity_type: "item" }, e.target.checked)}></Checkbox>

                    </div>
                    <div>
                        <ListOptionItem data={data} folderId={currentFolder} />
                    </div>
                </div>

                <div style={{ height: "45%", width: "100%", overflow: "hidden" , cursor: 'pointer'}}  onClick={e => navigate(`/item-detail/${data.id}`)}>

                    {thumbnail ?
                        <img src={thumbnail} style={{ width: "100%", height: "100%", objectFit: "cover", }}></img>
                        :
                        <div style={{ height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Icon.FileImageOutlined style={{ display: 'block', fontSize: "max(3vw, 42px)", marginTop: '20%', color: token.colorPrimary }}  />

                        </div>

                    }
                </div>

                <div style={{ padding: token.paddingSM }}>

             


                        <Text.Title>
                            {data.name}
                        </Text.Title>
          

                    <div>
                        {data.variant_title}
                    </div>



                    <div >
                        <Space>
                            <div>
                                {Number.formatInt(data.folder_stock.quantity)}
                            </div>
                            <Divider type="vertical" ></Divider>
                            <div>
                                {Number.format(data.folder_stock.total_amount)}
                            </div>
                        </Space>

                        {data.has_variants &&
                            <div>Variants {data.folder_stock.variant_count}</div>
                        }

                    </div>
                    <div style={{ marginTop: token.margin, justifyContent: "flex-end", display: "flex" }}>
                        <Tags tag_ids={data.tags}></Tags>
                    </div>

                </div>

            </div>

            {/* //{JSON.stringify(data)} */}
        </div>
    )
}




export default memo(Item);