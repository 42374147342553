import { useEffect, useState } from "react";
import { Divider, Flex, Collapse, Button, Form, Switch, InputNumber, Select, theme } from "antd";
import { CheckCircleFilled } from "@ant-design/icons";
import FilterFolder from "./FilterFolders";
import { useForm, useWatch } from "antd/es/form/Form";
import FilterNumber from "./FilterNumber";
import TagPicker from "../../../components/pickers/tag";
import { useAppContext } from "context/app";
import FilterCustomFields from "./FilterCustomFields";
import FilterCustomFieldNumber from "./FilterCustomFieldNumberOrDate";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

function Filter({ filters, setFilters, setShowFilterFolder, collapseExpandKey, setCollapseExpandKey, activeFilters, setActiveFilters }) {
    const { token } = theme.useToken();

    const { customFieldList } = useAppContext();
    const [form] = useForm();

    const filter_folder = useWatch("filter_folder", form);

    const quantity_filter_from = useWatch("quantity_filter_from", form);
    const quantity_filter_to = useWatch("quantity_filter_to", form);
    const quantity_filter_exact = useWatch("quantity_filter_exact", form);

    // const minLevel_filter_from = useWatch("minLevel_filter_from", form);
    // const minLevel_filter_to = useWatch("minLevel_filter_to", form);
    // const minLevel_filter_exact = useWatch("minLevel_filter_exact", form);

    const price_filter_from = useWatch("price_filter_from", form);
    const price_filter_to = useWatch("price_filter_to", form);
    const price_filter_exact = useWatch("price_filter_exact", form);

    //const filter_alerts = useWatch("filter_alerts", form);

    //const filter_stocks = useWatch("filter_stocks", form);

    const filter_tags = useWatch("filter_tags", form);

    const [filter_customFields, set_filter_customFields] = useState({});

    const handleCustomFieldChange = (fieldName, value) => {
        set_filter_customFields((prevValues) => ({
            ...prevValues,
            [fieldName]: value,
        }));
    };

    useEffect(() => {
        setFilter();
    }, [filters]);

    // Kiểm tra giá trị có phải là đối tượng Date hoặc Dayjs
    const isDate = (value) => {
        if (value instanceof Date || dayjs.isDayjs(value)) {
            console.log("là kiểu date");
            return true;
        }

        const parsedDate = dayjs(value, "YYYY-MM-DD", true);
        const result = parsedDate.isValid();

        return result;
    };

    const setFilter = () => {
        const quantity = filters.quantity || {};
        //const minLevel = filters.lowstock_level || {};
        const price = filters.price || {};
        //const alert = filters.lowstock_alert || {};
        //const stock = filters.lowstock || filters.outof_stock || {};

        let listSetCustomField = {};
        Object.keys(filters).forEach((key) => {
            if (key.startsWith("filter_customField_")) {
                listSetCustomField[key] = filters[key];
            }

            if (key.startsWith("filter_multi_customField_")) {
                if (filters[key].hasOwnProperty("exact")) {
                    listSetCustomField[`${key}_checked_exact`] = true;
                    listSetCustomField[`${key}_filter_exact`] = isDate(filters[key].exact) ? dayjs(filters[key].exact) : filters[key].lte;
                } else {
                    listSetCustomField[`${key}_filter_from`] = isDate(filters[key].gte) ? dayjs(filters[key].gte) : filters[key].gte;
                    listSetCustomField[`${key}_filter_to`] = isDate(filters[key].lte) ? dayjs(filters[key].lte) : filters[key].lte;
                }
            }
        });

        set_filter_customFields(listSetCustomField);

        form.setFieldsValue({
            filter_folder: filters.folders || [],

            quantity_checked_exact: quantity.hasOwnProperty("exact"),
            quantity_filter_exact: quantity.exact,
            quantity_filter_from: quantity.gte,
            quantity_filter_to: quantity.lte,

            // minLevel_checked_exact: minLevel.hasOwnProperty("exact"),
            // minLevel_filter_exact: minLevel.exact,
            // minLevel_filter_from: minLevel.gte,
            // minLevel_filter_to: minLevel.lte,

            price_checked_exact: price.hasOwnProperty("exact"),
            price_filter_exact: price.exact,
            price_filter_from: price.gte,
            price_filter_to: price.lte,

            // filter_alerts: alert.has_alert === true ? "with_quantity_alerts_set" : alert.has_alert === false ? "without_quantity_alerts_set" : undefined,

            //filter_stocks: stock.is_low_stock === true ? "low_stock" : stock.is_out_of_stock === true ? "out_of_stock" : undefined,

            filter_tags: filters.tags || undefined,

            ...listSetCustomField,
        });
    };

    useEffect(() => {
        const filters = [
            filter_folder?.length > 0,
            quantity_filter_from != null || quantity_filter_to != null || quantity_filter_exact != null,
            //minLevel_filter_from != null || minLevel_filter_to != null || minLevel_filter_exact != null,
            price_filter_from != null || price_filter_to != null || price_filter_exact != null,
            //filter_alerts,
            filter_tags?.length > 0,
            //filter_stocks,
        ];

        let filterFieldCustom = {};
        let multiFieldCustom = [];

        Object.keys(filter_customFields).forEach((key) => {
            const value = filter_customFields[key];

            if (value != null) {
                if (key.startsWith("filter_customField_")) {
                    filterFieldCustom[key] = value;
                    return;
                }

                if (key.startsWith("filter_multi_customField_")) {
                    const [field] = key.split(/_(checked_exact|filter_from|filter_to|filter_exact|)$/);

                    let fieldObj = multiFieldCustom.find(
                        (item) =>
                            item.hasOwnProperty(`${field}_checked_exact`) ||
                            item.hasOwnProperty(`${field}_filter_from`) ||
                            item.hasOwnProperty(`${field}_filter_to`) ||
                            item.hasOwnProperty(`${field}_filter_exact`)
                    );

                    if (!fieldObj) {
                        fieldObj = {};
                        multiFieldCustom.push(fieldObj);
                    }

                    fieldObj[key] = value;
                }
            }
        });

        const activeCount = filters.filter(Boolean).length + Object.keys(filterFieldCustom).length + multiFieldCustom.length;

        setActiveFilters(activeCount);
    }, [
        filter_folder,
        quantity_filter_from,
        quantity_filter_to,
        quantity_filter_exact,
        // minLevel_filter_from,
        // minLevel_filter_to,
        // minLevel_filter_exact,
        price_filter_from,
        price_filter_to,
        price_filter_exact,
        //filter_alerts,
        filter_tags,
        // filter_stocks,
        filter_customFields,
    ]);

    const handleClearFilter = (filterName) => {
        if (filterName.startsWith("filter_multi_customField_")) {
            const checked = `${filterName}_checked_exact`;
            const exact = `${filterName}_filter_exact`;
            const from = `${filterName}_filter_from`;
            const to = `${filterName}_filter_to`;

            form.setFieldsValue({
                [checked]: false,
                [exact]: null,
                [from]: null,
                [to]: null,
            });

            [checked, exact, from, to].forEach((field) => handleCustomFieldChange(field, null));

            return;
        }

        if (filterName.startsWith("filter_customField_")) {
            form.setFieldValue(filterName, null);
            handleCustomFieldChange(filterName, null);
            return;
        }

        switch (filterName) {
            case "folders":
                form.setFieldValue("filter_folder", null);
                break;
            case "quantity":
            //case "minLevel":
            case "price":
                form.setFieldsValue({
                    [`${filterName}_checked_exact`]: false,
                    [`${filterName}_filter_exact`]: null,
                    [`${filterName}_filter_from`]: null,
                    [`${filterName}_filter_to`]: null,
                });
                break;
            // case "alerts":
            //     form.setFieldValue("filter_alerts", null);
            //     break;
            case "stocks":
                form.setFieldValue("filter_stocks", null);
                break;
            case "tags":
                form.setFieldValue("filter_tags", null);
                break;
            default:
                break;
        }
    };

    const handleClearAll = () => {
        form.resetFields();
        set_filter_customFields({});
    };

    const items = [
        {
            key: "FOLDERS",
            label: (
                <>
                    <span>FOLDERS</span>

                    {filter_folder && filter_folder.length > 0 && (
                        <>
                            <span style={{ backgroundColor: "#333", color: "#fff", padding: filter_folder.length >= 10 ? "3px 5px" : "3px 9px", borderRadius: "50%", marginLeft: 12 }}>
                                {filter_folder.length}
                            </span>
                            <Button
                                type="link"
                                style={{ padding: 6 }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleClearFilter("folders");
                                }}
                            >
                                Clear
                            </Button>
                        </>
                    )}
                </>
            ),
            children: (
                <Form.Item name="filter_folder">
                    <FilterFolder />
                </Form.Item>
            ),
        },
        FilterSection({
            label: "QUANTITY",
            isActive:
                (quantity_filter_exact !== null && quantity_filter_exact !== undefined) ||
                (quantity_filter_from !== null && quantity_filter_from !== undefined) ||
                (quantity_filter_to !== null && quantity_filter_to !== undefined),
            onClear: () => handleClearFilter("quantity"),
            children: <FilterNumber form={form} filterType="quantity" />,
        }),
        FilterSection({
            label: "PRICE",
            isActive:
                (price_filter_exact !== null && price_filter_exact !== undefined) ||
                (price_filter_from !== null && price_filter_from !== undefined) ||
                (price_filter_to !== null && price_filter_to !== undefined),
            onClear: () => handleClearFilter("price"),
            children: <FilterNumber form={form} filterType="price" />,
        }),
        // FilterSection({
        //     label: "MIN LEVEL",
        //     isActive:
        //         (minLevel_filter_exact !== null && minLevel_filter_exact !== undefined) ||
        //         (minLevel_filter_from !== null && minLevel_filter_from !== undefined) ||
        //         (minLevel_filter_to !== null && minLevel_filter_to !== undefined),
        //     onClear: () => handleClearFilter("minLevel"),
        //     children: <FilterNumber form={form} filterType="minLevel" />,
        // }),
        // FilterSection({
        //     label: "QUANTITY ALERTS",
        //     isActive: filter_alerts !== null && filter_alerts !== undefined && filter_alerts !== "",
        //     onClear: () => handleClearFilter("alerts"),
        //     children: (
        //         <Form.Item name="filter_alerts" style={{ margin: 0 }}>
        //             <Select
        //                 options={[
        //                     { label: "With Quantity Alerts set", value: "with_quantity_alerts_set" },
        //                     { label: "Without Quantity Alerts set", value: "without_quantity_alerts_set" },
        //                 ]}
        //             />
        //         </Form.Item>
        //     ),
        // }),
        FilterSection({
            label: "TAGS",
            isActive: filter_tags && filter_tags.length > 0,
            onClear: () => handleClearFilter("tags"),
            children: (
                <Form.Item name="filter_tags" style={{ margin: 0 }}>
                    <TagPicker placeholder="" hiddenButtonAdd={true} />
                </Form.Item>
            ),
        }),
        ...customFieldList.map((customField) => {
            const fieldName = `filter_customField_${customField.id}`;
            const fieldNameMulti = `filter_multi_customField_${customField.id}`;

            const fieldNameMultiExact = `${fieldNameMulti}_filter_exact`;
            const fieldNameMultiFrom = `${fieldNameMulti}_filter_from`;
            const fieldNameMultiTo = `${fieldNameMulti}_filter_to`;

            if (customField.field_type === "round_number" || customField.field_type === "decimal_number" || customField.field_type === "date") {
                return FilterSection({
                    label: `${customField.name.toUpperCase()}`,
                    idCustomField: customField.id,
                    isActive:
                        (filter_customFields[fieldNameMultiExact] !== null && filter_customFields[fieldNameMultiExact] !== undefined) ||
                        (filter_customFields[fieldNameMultiFrom] !== null && filter_customFields[fieldNameMultiFrom] !== undefined) ||
                        (filter_customFields[fieldNameMultiTo] !== null && filter_customFields[fieldNameMultiTo] !== undefined),
                    onClear: () => handleClearFilter(fieldNameMulti),
                    children: <FilterCustomFieldNumber form={form} data={customField} fieldNameMulti={fieldNameMulti} handleCustomFieldChange={handleCustomFieldChange} />,
                });
            } else {
                return FilterSection({
                    label: `${customField.name.toUpperCase()}`,
                    idCustomField: customField.id,
                    isActive: filter_customFields[fieldName] != null,
                    onClear: () => handleClearFilter(fieldName),
                    children: (
                        <Form.Item
                            name={fieldName}
                            style={{ margin: 0 }}
                            getValueFromEvent={(value) => {
                                handleCustomFieldChange(fieldName, value);
                                return value;
                            }}
                        >
                            <FilterCustomFields data={customField} />
                        </Form.Item>
                    ),
                });
            }
        }),
    ];

    const onChange = (key) => {
        setCollapseExpandKey(key);
    };

    const updateFilter = (prev, fieldValue, filterName, checkExactKey, exactKey, rangeKeys) => {
        if (fieldValue[checkExactKey]) {
            if (fieldValue[exactKey] !== undefined && fieldValue[exactKey] !== null) {
                return {
                    ...prev,
                    [filterName]: {
                        exact: fieldValue[exactKey],
                    },
                };
            }
            return removeFilter(prev, filterName);
        }

        const filter = {};
        if (fieldValue[rangeKeys.from] !== undefined && fieldValue[rangeKeys.from] !== null) {
            filter.gte = fieldValue[rangeKeys.from];
        }
        if (fieldValue[rangeKeys.to] !== undefined && fieldValue[rangeKeys.to] !== null) {
            filter.lte = fieldValue[rangeKeys.to];
        }

        if (Object.keys(filter).length > 0) {
            return {
                ...prev,
                [filterName]: filter,
            };
        }

        return removeFilter(prev, filterName);
    };

    const removeFilter = (prev, filterName) => {
        const { [filterName]: removedFilter, ...rest } = prev;
        return rest;
    };

    // Hàm để kiểm tra và chuyển đổi giá trị ngày tháng
    const formatDateValues = (values) => {
        const formattedValues = { ...values };

        Object.keys(formattedValues).forEach((key) => {
            const value = formattedValues[key];

            // Kiểm tra nếu giá trị là đối tượng dayjs
            if (value && typeof value === "object" && value.$isDayjsObject) {
                formattedValues[key] = value.format("YYYY-MM-DD");
            } else if (typeof value === "string") {
                // Kiểm tra nếu giá trị là chuỗi và có thể là ngày tháng
                const date = dayjs(value);
                if (date.isValid() && value.includes("T")) {
                    formattedValues[key] = date.format("YYYY-MM-DD");
                }
            }
        });

        return formattedValues;
    };
    const handleApply = () => {
        const fieldValue = form.getFieldValue();

        // Chuyển đổi các giá trị ngày tháng trong fieldValue
        const formattedFieldValue = formatDateValues(fieldValue);
        console.log({ fieldValue });
        console.log({ formattedFieldValue });

        let filterFieldCustom = {};
        let multiFieldCustom = [];

        Object.keys(formattedFieldValue).forEach((key) => {
            // Nếu là filter_customField, thêm vào filterFieldCustom
            if (key.startsWith("filter_customField_")) {
                filterFieldCustom[key] = formattedFieldValue[key];
                return;
            }

            // Nếu là filter_multi_customField, xử lý multi-field logic
            if (key.startsWith("filter_multi_customField_")) {
                // Tách phần field và phần subKey
                const [field] = key.split(/_(checked_exact|filter_from|filter_to|filter_exact|)$/);

                // Tìm fieldObj trong multiFieldCustom dựa trên field
                let fieldObj = multiFieldCustom.find(
                    (item) =>
                        item.hasOwnProperty(`${field}_checked_exact`) ||
                        item.hasOwnProperty(`${field}_filter_from`) ||
                        item.hasOwnProperty(`${field}_filter_to`) ||
                        item.hasOwnProperty(`${field}_filter_exact`)
                );

                // Nếu không tìm thấy, khởi tạo mới
                if (!fieldObj) {
                    fieldObj = {};
                    multiFieldCustom.push(fieldObj);
                }

                // Gán giá trị vào đối tượng đã tìm được hoặc mới tạo
                fieldObj[key] = formattedFieldValue[key];
            }
        });

        setFilters((prev) => {
            let updatedFilters = { ...prev };

            // Set filter folder
            if (Array.isArray(formattedFieldValue.filter_folder) && formattedFieldValue.filter_folder.length > 0) {
                updatedFilters = { ...updatedFilters, folders: formattedFieldValue.filter_folder };
            } else {
                const { folders, ...rest } = updatedFilters;
                updatedFilters = rest;
            }

            // Set filter quantity
            updatedFilters = updateFilter(updatedFilters, formattedFieldValue, "quantity", "quantity_checked_exact", "quantity_filter_exact", {
                from: "quantity_filter_from",
                to: "quantity_filter_to",
            });

            // Set filter min level
            // updatedFilters = updateFilter(updatedFilters, formattedFieldValue, "lowstock_level", "minLevel_checked_exact", "minLevel_filter_exact", {
            //     from: "minLevel_filter_from",
            //     to: "minLevel_filter_to",
            // });

            // Set filter price
            updatedFilters = updateFilter(updatedFilters, fieldValue, "price", "price_checked_exact", "price_filter_exact", { from: "price_filter_from", to: "price_filter_to" });

            // Set filter quantity alerts
            // updatedFilters = {
            //     ...updatedFilters,
            //     lowstock_alert:
            //         formattedFieldValue.filter_alerts === "with_quantity_alerts_set"
            //             ? { has_alert: true }
            //             : formattedFieldValue.filter_alerts === "without_quantity_alerts_set"
            //             ? { has_alert: false }
            //             : undefined,
            // };

            // Set filter stocks
            // const stockFilters = {
            //     lowstock: fieldValue.filter_stocks === "low_stock" ? { is_low_stock: true } : undefined,
            //     outof_stock: fieldValue.filter_stocks === "out_of_stock" ? { is_out_of_stock: true } : undefined,
            // };
            // updatedFilters = {
            //     ...updatedFilters,
            //     ...stockFilters,
            // };

            // Set filter tags
            if (Array.isArray(formattedFieldValue.filter_tags) && formattedFieldValue.filter_tags.length > 0) {
                updatedFilters = { ...updatedFilters, tags: formattedFieldValue.filter_tags };
            } else {
                const { tags, ...rest } = updatedFilters;
                updatedFilters = rest;
            }

            // Set filter list custom field
            if (Object.keys(filterFieldCustom).length > 0) {
                Object.keys(filterFieldCustom).forEach((key) => {
                    if (filterFieldCustom[key] !== null && filterFieldCustom[key] !== undefined) {
                        console.log(filterFieldCustom[key]);
                        updatedFilters = { ...updatedFilters, [key]: filterFieldCustom[key] };
                    } else {
                        const { key, ...rest } = updatedFilters;
                        updatedFilters = rest;
                    }
                });
            } else {
                Object.keys(updatedFilters).forEach((key) => {
                    if (key.startsWith("filter_customField_")) {
                        delete updatedFilters[key];
                    }
                });
            }

            // Set filter list custom field (CASE: round_number || decimal_number || date)
            if (multiFieldCustom.length > 0) {
                multiFieldCustom.forEach((fieldCustom) => {
                    const firstKey = Object.keys(fieldCustom)[0];
                    const id = firstKey.split("_")[3];

                    updatedFilters = updateFilter(
                        updatedFilters,
                        formattedFieldValue,
                        `filter_multi_customField_${id}`,
                        `filter_multi_customField_${id}_checked_exact`,
                        `filter_multi_customField_${id}_filter_exact`,
                        {
                            from: `filter_multi_customField_${id}_filter_from`,
                            to: `filter_multi_customField_${id}_filter_to`,
                        }
                    );
                });
            } else {
                Object.keys(updatedFilters).forEach((key) => {
                    if (key.startsWith("filter_multi_customField_")) {
                        delete updatedFilters[key];
                    }
                });
            }

            return updatedFilters;
        });

        setShowFilterFolder(false);
    };

    return (
        <Flex vertical style={{ position: "fixed", right: 0, zIndex: 5, backgroundColor: token.colorBgBase, boxShadow: token.boxShadow, width: "350px" }}>
            <div style={{ flex: 1 }}>
                <Flex align="center" gap={4} style={{ borderBottom: "1px solid #ccc" }}>
                    <span style={{ display: "block", fontSize: token.fontSizeHeading4, margin: `${token.margin}px ${token.marginSM}px` }}>Filters</span>
                    {activeFilters > 0 && (
                        <>
                            <Flex align="center" justify="space-between" style={{ flex: 1 }}>
                                <span style={{ fontSize: token.fontSizeHeading5, backgroundColor: "red", color: "white", padding: "2px 8px", borderRadius: "50%" }}>{activeFilters}</span>
                                <Button type="link" onClick={handleClearAll}>
                                    Clear All
                                </Button>
                            </Flex>
                        </>
                    )}
                </Flex>

                <div style={{ height: "calc(100vh - 125px)", overflowY: "scroll" }}>
                    <Form form={form}>
                        <Collapse
                            items={items}
                            expandIconPosition="end"
                            onChange={onChange}
                            style={{ borderRadius: 0, borderLeft: "none", borderRight: "none" }}
                            defaultActiveKey={collapseExpandKey}
                        />
                    </Form>
                </div>
            </div>

            <Flex gap={16} style={{ margin: token.margin }}>
                <Button style={{ flex: 1 }} type="primary" onClick={() => handleApply()}>
                    APPLY
                </Button>
                <Button
                    style={{ flex: 1 }}
                    onClick={() => {
                        setFilter();
                        setTimeout(() => {
                            setShowFilterFolder(false);
                        }, 0);
                    }}
                >
                    CANCEL
                </Button>
            </Flex>
        </Flex>
    );
}

export default Filter;

function FilterSection({ label, isActive, onClear, children, idCustomField = null }) {
    return {
        key: `${idCustomField ? label.toUpperCase().replace(" ", "_") + "_" + idCustomField : label.toUpperCase().replace(" ", "_")}`,
        label: (
            <Flex align="center" gap={16}>
                <span>{label}</span>
                {isActive && (
                    <Flex>
                        <CheckCircleFilled style={{ fontSize: "20px" }} />
                        <Button
                            type="link"
                            style={{ padding: 6 }}
                            onClick={(e) => {
                                e.stopPropagation();
                                onClear();
                            }}
                        >
                            Clear
                        </Button>
                    </Flex>
                )}
            </Flex>
        ),
        children: children,
    };
}
