import { Flex, Form, Switch, InputNumber, DatePicker } from "antd";
import { useWatch } from "antd/es/form/Form";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

function FilterCustomFieldNumberAndDate({ form, data, fieldNameMulti, handleCustomFieldChange }) {
    const checkedExact = useWatch(`${fieldNameMulti}_checked_exact`, form);

    const filterExact = `${fieldNameMulti}_filter_exact`;
    const filterFrom = `${fieldNameMulti}_filter_from`;
    const filterTo = `${fieldNameMulti}_filter_to`;

    const NumberInput = ({ name, placeholder }) => (
        <Form.Item
            name={name}
            getValueFromEvent={(value) => {
                handleCustomFieldChange(name, value);
                return value;
            }}
        >
            <InputNumber placeholder={placeholder} style={{ width: "100%" }} />
        </Form.Item>
    );

    const DatePickerInput = ({ name, placeholder }) => (
        <Form.Item
            name={name}
            getValueFromEvent={(value, valueString) => {
                handleCustomFieldChange(name, valueString);
                return value;
            }}
        >
            <DatePicker format="YYYY-MM-DD" placeholder={placeholder} style={{ width: "100%" }} />
        </Form.Item>
    );

    const renderField = (name, placeholder) => {
        return data.field_type === "date" ? <DatePickerInput name={name} placeholder={placeholder} /> : <NumberInput name={name} placeholder={placeholder} />;
    };

    return (
        <>
            {checkedExact === true ? (
                renderField(filterExact, "Exact")
            ) : (
                <Flex gap={16}>
                    {renderField(filterFrom, "From")}
                    {renderField(filterTo, "To")}
                </Flex>
            )}

            <Form.Item
                name={`${fieldNameMulti}_checked_exact`}
                label="Exact value"
                style={{ marginBottom: "0" }}
                initialValue={false}
                getValueFromEvent={(value) => {
                    handleCustomFieldChange(`${fieldNameMulti}_checked_exact`, value);
                    return value;
                }}
            >
                <Switch />
            </Form.Item>
        </>
    );
}

export default FilterCustomFieldNumberAndDate;
