import React from "react";
import { Divider, Flex, Tag, Button, Modal, Input } from "antd";
import * as Icon from "@ant-design/icons";
import { CustomButtonLink } from "components/Buttons";
import CodeService from "services/code";
import { useLoading } from "context/loading";

const codeServices = new CodeService();

const QrCodePicker = ({ value, onChange }) => {
    const { showLoading, dismissLoading } = useLoading();

    const handleGenerate = async () => {
        showLoading();

        const code = await codeServices.generate();
        onChange(code);

        dismissLoading();
    };

    return (
        <Input
            value={value}
            onChange={(e) => onChange(e.target.value)}
            suffix={
                <CustomButtonLink type="link" onClick={handleGenerate}>
                    <Icon.QrcodeOutlined></Icon.QrcodeOutlined>Generate Qr/Bar code
                </CustomButtonLink>
            }
        ></Input>
    );
};
export default QrCodePicker;
