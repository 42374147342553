import { Modal, Divider, Form, Flex, Select, Image, InputNumber, Input, Button, Switch, theme } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { CustomButtonLink, SaveButton } from "components/Buttons";
import ItemService from "services/item";
import { useLoading } from "context/loading";
import { useAppContext } from "context/app";
import { getQuantityItem } from "common/get_quantity_item";
import { useNotification } from "context/notification";
import * as Picker from "components/pickers";
import SelectMoveReason from "./SelectMoveReason";
import InputQuantityMove from "./InputQuantityMove";
import * as Number from "common/number_format";

const itemService = new ItemService();

function MoveSingleQuantityItemModal({ item, folderId, visible, onClose }) {
    const { useToken } = theme;
    const { token } = useToken();

    const { showLoading, dismissLoading } = useLoading();
    const { success, error } = useNotification();
    const [disableSave, setDisableSave] = useState(true);
    const [hasMoveAll, setHasMoveAll] = useState(true);
    const [form] = Form.useForm();
    const { eventBus } = useAppContext();
    const quantityOfItem = item && getQuantityItem(item, folderId);

    const to_folder_id = Form.useWatch("to_folder_id", form);

    useEffect(() => {
        if (item) {
            form.setFieldsValue({
                quantity_to_move: 1,
            });

            checkErrors(1);
        }
    }, [item]);

    useEffect(() => {
        if (!to_folder_id) {
            setDisableSave(true);
            return;
        }

        const hasError = form.getFieldsError().some((field) => field.errors.length > 0);
        setDisableSave(hasError);
    }, [to_folder_id]);

    const handleChangeQuantityMove = (value) => {
        checkErrors(value);
    };

    const checkErrors = (value) => {
        if (value !== quantityOfItem) {
            setHasMoveAll(true);
        } else {
            setHasMoveAll(false);
        }

        // Kiểm tra lỗi
        if (value === undefined || value === null) {
            form.setFields([
                {
                    name: "quantity_to_move",
                    errors: ["Quantity can't be blank"],
                },
            ]);
            setDisableSave(true);
        } else if (value <= 0) {
            form.setFields([
                {
                    name: "quantity_to_move",
                    errors: ["Quantity To Move can't be below 1"],
                },
            ]);
            setDisableSave(true);
        } else if (value > quantityOfItem) {
            form.setFields([
                {
                    name: "quantity_to_move",
                    errors: [`Quantity To Move must be less than or equal to ${quantityOfItem}`],
                },
            ]);
            setDisableSave(true);
        } else {
            form.setFields([
                {
                    name: "quantity_to_move",
                    errors: null,
                },
            ]);
            setDisableSave(false);
        }

        if (!to_folder_id) {
            setDisableSave(true);
            return;
        }
    };

    const handleBtnMoveAll = () => {
        form.setFieldsValue({
            quantity_to_move: quantityOfItem,
        });
        checkErrors(quantityOfItem);
        setHasMoveAll(false);
    };

    const handleSave = async () => {
        showLoading();

        try {
            const data = {
                item_id: item.id,
                folder_id: folderId,
                to_folder_id: form.getFieldValue("to_folder_id"),
                quantity: form.getFieldValue("quantity_to_move"),
                notes: form.getFieldValue("notes"),
                reason_id: form.getFieldValue("reason"),
            };

            console.log(data);

            const result = await itemService.moveQuantity(data);
            eventBus.emit("item_changed", { folder_id: folderId });
            success(`Move quantity has been successfully updated`);
        } catch (err) {
            error("Failed to move quantity. Please try again later.");
            console.log(err);
        } finally {
            resetValue();
            dismissLoading();
        }
    };

    const resetValue = () => {
        form.resetFields();
        setDisableSave(true);
        setHasMoveAll(true);
        onClose();
    };

    return (
        <Modal
            width={500}
            title={<p style={{ fontSize: token.fontSizeHeading4, textAlign: "center" }}>Move Item</p>}
            open={visible}
            onCancel={resetValue}
            maskClosable={false}
            footer={<SaveButton disabled={disableSave} onClick={handleSave} />}
        >
            <Divider style={{ margin: "10px 0" }} />

            <Flex vertical style={{ marginBottom: "10px" }}>
                <span style={{ display: "block", fontSize: token.fontSizeHeading5, fontWeight: 500 }}>{item && item.name}</span>
                <span>{item && item.variant_title}</span>
                <span>On hand quantity: {item && Number.formatInt(getQuantityItem(item, folderId))}</span>
            </Flex>

            <Form form={form} layout="vertical">
                <Flex align="center" justify="space-between">
                    <Form.Item name="quantity_to_move" label="Quantity to move" style={{ width: "80%" }}>
                        <InputQuantityMove value={form.getFieldValue("quantity_to_move")} quantityOfItem={quantityOfItem} onChange={handleChangeQuantityMove} />
                    </Form.Item>
                    {hasMoveAll && (
                        <CustomButtonLink type="link" onClick={handleBtnMoveAll} style={{ paddingTop: "10px" }}>
                            Move all
                        </CustomButtonLink>
                    )}
                </Flex>

                <Form.Item name="reason" label="Move reason">
                    <SelectMoveReason />
                </Form.Item>

                <Form.Item name="notes" label="Move notes">
                    <Input.TextArea />
                </Form.Item>

                <Divider style={{ margin: "10px 0" }} />

                <Form.Item name="to_folder_id" label="Choose destination folder:">
                    <Picker.Folder style={{ width: "100%" }} placeholder=""></Picker.Folder>
                </Form.Item>
                <Divider />
            </Form>
        </Modal>
    );
}

export default MoveSingleQuantityItemModal;
