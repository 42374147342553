import BaseService from "services/base";
import _ from "lodash";

class CustomFieldService extends BaseService {
  getDataTypes = async () => {
    return [
      {
        name: "Small Text Box",
        placeholder: "Sample Text",
        data_type: "short_text",
        description: "<p>Field Type: <b>Small Text Box</b> (eg. Serial Number, Manufacturer Name, Customer ID, etc.)</p> <p>Character Limit: 190</p>",
      },
      {
        name: "Large Text Box",
        placeholder: "Sample Text",
        data_type: "long_text",
        description: "<p>Field Type: <b>Large Text Box</b> (eg. Address, Status, Instructions, Notes, Details, etc.)</p> <p>Character Limit: 4000</p>",
      },
      {
        name: "Round Number",
        placeholder: "123",
        data_type: "round_number",
        description: "<p>Field Type: <b>Round Number</b> (numbers without decimals - eg. Quantity, Count, etc.)",
      },
      {
        name: "Decimal Number",
        placeholder: "123.99",
        data_type: "decimal_number",
        description: "<p>Field Type: <b>Decimal Number</b> (numbers with decimals - eg. Cost, Selling price, Measurements like Area, Length, etc.)",
      },
      {
        name: "Checkbox",
        placeholder: null,
        data_type: "check_box",
        description: "<p>Field Type: <b>Checkbox</b> (Yes or No status - eg. Damaged, Repaired, Lent, Sold, etc.)<p>",
      },

      {
        name: "Dropdown",
        placeholder: "Sample Options",
        data_type: "dropdown",
        description: "<p>Field Type: <b>Dropdown</b> (Allows to select one option from a list) <p>Options limit: 250</p>",
      },
      {
        name: "Date",
        placeholder: new Date().toDateString(),
        data_type: "date",
        description: "<p>Field Type: <b>Date </b> (eg. Expiry Date, Purchase Date, etc.)",
      },
      // {
      //   name: "Scanner",
      //   placeholder: "",
      //   data_type: "scanner",
      //   description: "<p>Field Type: <b>Scanner</b> ((Scan and connect additional bar codes or QR codes)",
      // },
      {
        name: "Phone Number",
        placeholder: "(123) 350 - 2345",
        data_type: "phone_number",
        description: "<p>Field Type: <b>Phone Number</b> (eg. (123) 350 - 2345, +9144235423)",
      },
      {
        name: "Email",
        placeholder: "mail@example.com",
        data_type: "email",
        description: "<p>Field Type: <b>Email</b> (eg. mail@example.com)",
      },
      {
        name: "Web Link",
        placeholder: "https://google.com",
        data_type: "link",
        description: "<p>Field Type: <b>Web link</b> (eg. https://www.google.com)",
      },
      {
        name: "File Attachment",
        placeholder: "",
        data_type: "file",
        description: "<p>Field Type: <b>File Attachment</b>",
      },
    ];
  };
  get = async () => {
    var data = await this._api.get("customfield/list");
    return data;
  };

  getByID = async (id) => {
    var data = await this._api.get("customfield/get", { params: { id } });
    return data;
  }

  add = async (data) => {
    var newItem = {
      name: data.name,
      description: data.description,
      default_value: data.default_value || null,
      field_type: data.field_type,
      placeholder: data.placeholder || null,
      applicable: data.applicable,
      apply_all_existing_items: data.apply_all_existing_items,
      round_number_unit: data.round_number_unit,
      options: data.options || null,
    };
    var data = await this._api.post("customfield/add", newItem);
    return data;
  };

  edit = async (data) => {
    var data = await this._api.post("customfield/update", data);
    return data;
  };

  remove = async (id) => {
    var data = await this._api.post("customfield/delete", { id });
    return data;
  };

  getSuggestedFields = async () => {
    return [
      {
        name: "Serial Number",
        placeholder: "SN252067",
        data_type: "short_text",
        description: "<p>Field Type: <b>Small Text Box</b> </p> <p>Character Limit: 190</p>",
      },
      {
        name: "Model/Part Number",
        placeholder: "TM-U220",
        data_type: "short_text",
        description: "<p>Field Type: <b>Small Text Box</b> </p> <p>Character Limit: 190</p>",
      },
      {
        name: "Purchase Date",
        placeholder: new Date().toDateString(),
        data_type: "date",
        description: "<p>Field Type: <b>Date</b> </p>",
      },
      {
        name: "Expiry Date",
        placeholder: new Date().toDateString(),
        data_type: "date",
        description: "<p>Field Type: <b>Date</b> </p>",
      },
      {
        name: "Product Link",
        placeholder: "https://google.com",
        data_type: "link",
        description: "<p>Field Type: <b>Web Link</b> </p>",
      },
      {
        name: "Size",
        placeholder: "9.5 US / 8.5 UK / 42.5 EUR",
        data_type: "short_text",
        description: "<p>Field Type: <b>Small Text Box</b> </p>",
      },
    ];
  };
}

export default CustomFieldService;
