import { Flex, Form, InputNumber, Modal, Select, theme, Button, Space } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import { useEffect, useState } from "react";
import { useLoading } from "context/loading";

import FolderService from "services/folder";
import ItemService from "services/item";
import { useAppContext } from "context/app";
import { SaveButton } from "components/Buttons";
import { useNotification } from "context/notification";
import { useNavigate } from "react-router-dom";

const itemService = new ItemService();
const folderService = new FolderService();

function DeleteModal({ items, folderId, visible, onClose }) {
    const { useToken } = theme;
    const { token } = useToken();

    const navigate = useNavigate();
    const [lstReason, setLstReason] = useState([
        { value: "i_created_this_item_by_mistake", label: "I created this item by mistake" },
        { value: "i_don't_need_this_item_anymore", label: "I don't need this item anymore" },
        { value: "i_want_to_Archive_this_item", label: "I want to Archive this item" },
        { value: "item_is_a_duplicate", label: "Item is a duplicate" },
        { value: "item_is_End_of_Life_(EOL)", label: "Item is End of Life (EOL)" },
    ]);
    const [lstItems, setLstItems] = useState([]);
    const { showLoading, dismissLoading } = useLoading();
    const { success, error } = useNotification();
    const [form] = Form.useForm();
    const { eventBus, folderList } = useAppContext();

    useEffect(() => {
        if (items) {
            const handleItems = async () => {
                showLoading();

                const itemPromises = items.map(async (item) => {
                    if (item.entity_type === "item" && item.has_variants) {
                        const folderStockPromises = item.folder_stock.variant_stock.map(async (variantStock) => {
                            const result = await itemService.getByID(variantStock.item_id);
                            result.entity_type = "item";
                            return result;
                        });

                        return await Promise.all(folderStockPromises);
                    }

                    return item;
                });

                const result = await Promise.all(itemPromises);
                const lstItems = result.flat();

                setLstItems(lstItems);

                form.setFieldsValue({
                    notes: "",
                    reason: lstReason[0].value,
                });

                dismissLoading();
            };

            handleItems();
        }
    }, [items]);

    const renderListItem = () => {
        const itemCounts = lstItems.reduce(
            (counts, item) => {
                if (item.entity_type === "item") {
                    counts.items += 1;
                } else if (item.entity_type === "folder") {
                    counts.folders += 1;
                }
                return counts;
            },
            { items: 0, folders: 0 }
        );

        const { items, folders } = itemCounts;

        if (items > 0 && folders > 0) {
            return (
                <span>
                    {folders} Folders & {items} Items
                </span>
            );
        }

        if (items > 0) {
            return <span>{items} Items</span>;
        }

        if (folders > 0) {
            return <span>{folders} Folders</span>;
        }
    };

    const handleSave = async () => {
        showLoading();

        try {
            const note = form.getFieldValue("notes");
            const reason = form.getFieldValue("reason");

            const items = lstItems
                .filter((itemFilter) => itemFilter.entity_type === "item")
                .map((item) => ({
                    item_id: item.id,
                    folder_id: folderId,
                    notes: note,
                    reason_id: reason,
                }));

            const foldersId = lstItems.filter((itemFilter) => itemFilter.entity_type === "folder").map((item) => item.id);

            console.log({ items, foldersId });

            if (items.length > 0) {
                await itemService.bulkRemoveFolder(items);
            }

            if (foldersId.length > 0) {
                await folderService.bulkDelete({
                    ids: foldersId,
                    notes: note,
                    reason_id: reason,
                });

                if (foldersId[0] === folderId) {
                    const root = folderList.find((x) => x.is_root);
                    success(`Delete has been successfully updated`);
                    navigate(`/folder/${root.id}`);
                    return;
                }
            }

            success(`Delete has been successfully updated`);
            eventBus.emit("item_changed", { folder_id: folderId });
        } catch (err) {
            error("Failed to delete. Please try again later.");
            console.log(err);
        } finally {
            resetValue();
            dismissLoading();
        }
    };

    const resetValue = () => {
        form.resetFields();
        setLstItems([]);
        onClose();
    };

    return (
        <Modal
            width={600}
            title={<p style={{ fontSize: token.fontSizeHeading4, textAlign: "center" }}>Delete</p>}
            open={visible}
            onCancel={() => {
                resetValue();
            }}
            maskClosable={false}
            footer={
                <Space>
                    <Button type="default" onClick={onClose} >  Cancel</Button>
                    <Button type="primary" onClick={handleSave} >  <DeleteOutlined></DeleteOutlined> Delete</Button>
                </Space>
            }
        >
            <Flex vertical gap={10} align="center">
                {/* <DeleteOutlined style={{ fontSize: "60px",color:token.colorError  }} /> */}

                <span style={{ fontSize: token.fontSizeHeading5, fontWeight: 500, color: token.colorError }}>Do you want to delete {renderListItem()}?</span>

                <div style={{ textAlign: "center" }}>
                    <span style={{ display: "block", color: token.colorError }}>This action canot be undone.</span>

                </div>

                <Form form={form} layout="vertical" style={{ marginTop: token.margin, width: "100%" }}>
                    <Form.Item label="Delete Reason" name="reason">
                        <Select options={lstReason} placeholder="Delete Reason (optional)" />
                    </Form.Item>
                    <Form.Item label="Delete Note" name="notes">
                        <TextArea placeholder="Delete Note (optional)" />
                    </Form.Item>
                </Form>
            </Flex>
        </Modal>
    );
}

export default DeleteModal;
