import {
    FontSizeOutlined,
    CheckSquareOutlined,
    FieldBinaryOutlined,
    DownSquareOutlined,
    PhoneOutlined,
    MailOutlined,
    LinkOutlined,
    CalendarOutlined,
    BarcodeOutlined,
    FileOutlined,
} from "@ant-design/icons";
import { Button, theme } from "antd";
import { CustomButtonLink } from "components/Buttons";
import * as field_type_const from "components/CustomField/constants";

function ListCustomFields({ listField, selectedField, setSelectedField, typeField, setTypeField }) {
    const { useToken } = theme;
    const { token } = useToken();

    const showIcon = (dataType) => {
        switch (dataType) {
            case field_type_const.SHORT_TEXT:
                return <FontSizeOutlined />;
            case field_type_const.LONG_TEXT:
                return <FontSizeOutlined />;
            case field_type_const.CHECK_BOX:
                return <CheckSquareOutlined />;
            case field_type_const.ROUND_NUMBER:
                return <FieldBinaryOutlined />;
            case field_type_const.DECIMAL_NUMBER:
                return <FieldBinaryOutlined />;
            case field_type_const.DROPDOWN:
                return <DownSquareOutlined />;
            case field_type_const.PHONE_NUMBER:
                return <PhoneOutlined />;
            case field_type_const.EMAIL:
                return <MailOutlined />;
            case field_type_const.LINK:
                return <LinkOutlined />;
            case field_type_const.DATE:
                return <CalendarOutlined />;
            case field_type_const.SCANNER:
                return <BarcodeOutlined />;
            case field_type_const.FILE:
                return <FileOutlined />;
            default:
                break;
        }
    };

    return (
        <>
            {typeField === "SUGGESTED" ? <p>SUGGESTED FIELDS</p> : <p>CHOOSE FIELD TYPE</p>}

            <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
                {listField.map((item, index) => (
                    <div
                        key={index}
                        style={{ flex: "0 1 calc(50% - 10px)" }}
                        onClick={() => {
                            setSelectedField(item);
                        }}
                    >
                        <p
                            style={{
                                boxShadow: token.boxShadow,
                                padding: `${token.paddingSM}px ${token.padding}px`,
                                borderRadius: token.borderRadiusLG,
                                cursor: "pointer",
                                border: item === selectedField ? `1px solid ${token.colorPrimary}` : "",
                            }}
                        >
                            <span style={{ marginRight: token.marginXS, color: item === selectedField ? token.colorPrimary : "" }}>{showIcon(item.data_type)}</span>
                            {item.name}
                        </p>
                    </div>
                ))}
            </div>

            {typeField === "SUGGESTED" ? (
                <CustomButtonLink type="link" onClick={() => setTypeField("OWN")}>
                    Create your own field
                </CustomButtonLink>
            ) : (
                <CustomButtonLink type="link" onClick={() => setTypeField("SUGGESTED")}>
                    Back to suggested fields
                </CustomButtonLink>
            )}
        </>
    );
}

export default ListCustomFields;
