import * as React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import * as Page from "pages";

const PageRoutes = () => {
    return (
        <Routes>
            <Route path="/dashboard" element={<Page.Dashboard />} />
            <Route path="/items" element={<Page.Items />} />
            <Route path="/item/add/:folder_id" element={<Page.AddItem />} />
            <Route path="/item/variants/:id" element={<Page.EditItemVariants />} />
            <Route path="/item-edit/:id" element={<Page.EditItem />} />
            <Route path="/item-detail/:id" element={<Page.ItemDetail />} />
            <Route path="/items-list" element={<Page.ItemsList />} />
            <Route path="/folder/add/:folder_id" element={<Page.AddFolder />} />
            <Route path="/folder" element={<Page.Items />} />
            <Route path="/folder/:id" element={<Page.Items />} />
            <Route path="/folder-edit/:id" element={<Page.EditFolder />} />
            <Route path="/tags" element={<Page.Tags />} />
            <Route path="/setting/custom-fields" element={<Page.CustomField />} />
            <Route path="/setting/tags" element={<Page.Tags />} />
            <Route path="/reports/inventory-summary" element={<Page.InventorySummary />} />
            <Route path="/reports/transactions" element={<Page.Transactions />} />
            <Route path="/inventory" element={<Page.InventorySummary />} />
            <Route path="/setting/user-management" element={<Page.UserManagement />} />
      
            <Route path="/reset-password" element={<Page.ResetPassword />} />
   
            <Route path="/thank-you" element={<Page.ThankYou />} />
            <Route path="/setting/attributes" element={<Page.Attribute />} />
            <Route path="/profile" element={<Page.Profile />} />
            <Route path="/notification" element={<Page.Notification />} />
            <Route path="/notification-detail/:id" element={<Page.NotificationDetail />} />
            <Route path="/" element={<Page.Dashboard></Page.Dashboard>} />
            <Route path="*" element={<>not found</>} />
        </Routes>
    );
};

export default React.memo(PageRoutes);
