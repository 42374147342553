import BaseService from "services/base";
import _ from "lodash";

class FolderService extends BaseService {

    list = async (query = {}, sort = {}, option = { group_item: false, paging: { page: 1, page_zize: 10 } }) => {
        var data = await this._api.post("folder/list", { query, sort, option });
        return data;
    };



    add = async (item) => {
        var data = await this._api.post("folder/add", item);
        return data;
    };

    update = async (item) => {
        var data = await this._api.post("folder/update", item);
        return data;
    };


    getByID = async (id) => {
        var data = await this._api.get("folder/get", { params: { id } });
        return data;
    }


    childs = async (query = {}, sort = {}, option = { group_item: false, paging: { page: 1, page_zize: 10 }, only_items: false }) => {
        var data = await this._api.post("folder/childs", { query, sort, option });
        return data;
    };


    move = async ({ folder_id, to_folder_id, notes, reason_id }) => {
        var data = await this._api.post("folder/move", { folder_id, to_folder_id, notes, reason_id });
        return data;
    }

    /**
     * 
     * @param {Array[{ folder_id, to_folder_id, notes, reason_id }]} lstFolder 
     * @returns 
     */
    bulkMove = async (lstFolder) => {
        var data = await this._api.post("folder/bulkmove", lstFolder);
        return data;
    }



    /**
  * 
  * @param {
    * item_ids:  array of item id
    * tags:  array of tag id
    * } data 
    * @returns 
    */

    addTags = async ({ item_ids, tags }) => {
        var data = await this._api.post("folder/addtags", { item_ids, tags });
        return data;
    }



    /**
     * 
     * @param {
    * item_ids:  array of item id
    * tags:  array of tag id
    * } data 
    * @returns 
    */
    removeTags = async ({ item_ids, tags }) => {
        var data = await this._api.post("folder/removetags", { item_ids, tags });
        return data;
    }

    deleteFolder = async ({ id, note, reason_id }) => {
        var data = await this._api.post("folder/delete", { id, note, reason_id });
        return data;
    }


    /**
     * 
     * @param {ids:array(string)} param0 
     * @returns 
     */
    bulkDelete = async ({ ids, note, reason_id }) => {
        var data = await this._api.post("folder/bulkdelete", { ids, note, reason_id });
        return data;
    }



    updateSortIndex = async ( {id, sort_index}) => {
        var data = await this._api.post("folder/update-sort-index", {id, sort_index});
        return data;
    };


    

    /**
     * 
     * @param {Array of {id, sort_index}} lst 
     * @returns 
     */
    bulkUpdateSortIndex = async ( lst) => {
        var data = await this._api.post("folder/bulkupdate-sort-index", lst);
        return data;
    };








}

export default FolderService;
