import { memo, useCallback, useEffect, useState } from "react";
import ListTag from "./ListTag";
import AddOrEditTagModal from "components/Tag/AddOrEditTagModal";
import { Button, Card, ColorPicker, Divider, Flex, Popconfirm, Space, Table } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import TagService from "services/tag";
import { useAppContext } from "context/app";
import Search from "components/Search/Search";
import _ from "lodash";

const tagService = new TagService();

function TagsPage() {
    const [loading, setLoading] = useState(false);
    const [info, setInfo] = useState();
    const [listTag, setListTag] = useState([]);
    const [showModalTag, setShowModalTag] = useState(false);
    const [editTag, setEditTag] = useState(null);

    useEffect(() => {
        fetchListTag();
    }, [info]);

    const fetchListTag = async () => {
        setLoading(true);

        const result = await tagService.list({ info: info });
        setListTag(result);

        setLoading(false);
    };

    const handleSearch = useCallback(
        _.debounce(async (textSearch) => {
            setInfo(textSearch);
        }, 500),
        []
    );

    const onChange = async () => {
        fetchListTag();
        setEditTag(null);
        setShowModalTag(false);
    };

    const confirm = async (data) => {
        await tagService.remove(data.id);
        fetchListTag();
    };

    const columns = [
        {
            title: "TITLE",
            dataIndex: "title",
            width: "60%",
        },
        {
            title: "COLOR",
            dataIndex: "color",
            width: "30%",
            render: (text, record) => <ColorPicker value={text} disabled />,
        },
        {
            title: "ACTIONS",
            dataIndex: "action",
            width: "10%",
            render: (text, record) => (
                <>
                    <div>
                        <EditOutlined
                            style={{ cursor: "pointer", marginRight: "16px" }}
                            onClick={() => {
                                setEditTag(record);
                                setShowModalTag(true);
                            }}
                        />
                        <Popconfirm title="Delete the custom tag" description="Are you sure to delete this tag ?" onConfirm={() => confirm(record)} okText="Yes" cancelText="No">
                            <DeleteOutlined style={{ cursor: "pointer" }} />
                        </Popconfirm>
                    </div>
                </>
            ),
        },
    ];

    return (
        <>
            <AddOrEditTagModal visible={showModalTag} onClose={() => setShowModalTag(false)} onChange={onChange} value={editTag} />

            <Flex justify="space-between" align="center">
                <h1>New Tag</h1>

                <div>
                    <Button
                        type="primary"
                        onClick={() => {
                            setEditTag(null);
                            setShowModalTag(true);
                        }}
                    >
                        ADD TAG
                    </Button>
                </div>
            </Flex>

            <Divider />
            <Space direction="vertical" style={{ width: "100%" }}>
                <Card>
                    <div style={{ width: "700px" }}>
                        <Search placeholder={"Search tags"} onChange={handleSearch} />
                    </div>
                </Card>

                <Card>
                    <Table loading={loading} dataSource={listTag} columns={columns} />
                </Card>
            </Space>
        </>
    );
}

export default memo(TagsPage);
