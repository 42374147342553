import BaseService from "services/base";
import _ from "lodash";

class CodeService extends BaseService {
  generate = async () => {
    var data = await this._api.post("code/generate");
    return data;
  }

  find = async (code) => {
    var data = await this._api.post("code/find", { code });
    return data;
  }

}

export default CodeService;
