
import { useState, useEffect } from "react";
import { Button, Card, Space, Modal } from "antd";
import { CloseOutlined } from '@ant-design/icons';
import UserSettingService from "services/user_setting";
import "./style.css"
var service = new UserSettingService();


const StartGuide = () => {
    var [show, setShow] = useState(false);

    var confirmNotShow = async () => {
        setShow(false);
        var result = await Modal.confirm({
            title: "Do not show again?", icon: null, onOk: async () => {
                await service.save({ key: "not_show_start_guide", value: true })
            }
        });

    }

    useEffect(() => {
        var load = async () => {
            var setting = await service.get({});
            if (setting?.not_show_start_guide) {
                setShow(false);
            } else {
                setShow(true);
            }

        }
        load();
    }, [])

    if (!show) {
        return null;
    }

    return (
        <Card title="Getting Started." extra={<div><Button type="link" onClick={confirmNotShow}><CloseOutlined /></Button></div>}>

            <h2>1. Item Management</h2>
            <p>Easily add, edit, and search items in your inventory with detailed descriptions, photos, tags, and custom fields.</p>
            <ul>
                <li><span class="highlight">Add New Items</span>: Easily add items with detailed information.</li>
                <li><span class="highlight">Edit Items</span>: Keep your inventory accurate and up-to-date by editing item details anytime.</li>
                <li><span class="highlight">Search Items</span>: Use powerful search functionality to find specific items quickly.</li>
            </ul>

            <h2>2. Folders</h2>
            <p>Group related items into folders to keep your inventory tidy. Use subfolders for deeper categorization (e.g., "Electronics - Mobile Devices").</p>
            <ul>
                <li><span class="highlight">Organize with Folders</span>: Create folders and subfolders to categorize your items.</li>
            </ul>

            <h2>3. Tags and Custom Fields</h2>
            <p>Categorize and add custom attributes to your items to make them easier to find and track.</p>
            <ul>
                <li><span class="highlight">Tag Your Items</span>: Apply tags to categorize and organize your items.</li>
                <li><span class="highlight">Custom Fields</span>: Add custom fields to store specific information like serial numbers or expiration dates.</li>
            </ul>

            <h2>4. Attachments</h2>
            <p>Attach files to your items, such as receipts, warranties, or manuals, for easy reference.</p>
            <ul>
                <li><span class="highlight">File Attachments</span>: Keep all related documents with the item for quick access.</li>
            </ul>

            <h2>5. Reports and Analytics</h2>
            <p>Generate reports to get a summary of your inventory, and export data for further analysis or backup.</p>
            <ul>
                <li><span class="highlight">Generate Reports</span>: Get summaries and insights on your inventory.</li>
                <li><span class="highlight">Export Data</span>: Export your inventory data in multiple formats.</li>
            </ul>

            <h2>6. Reminders</h2>
            <p>Set reminders for important tasks related to your items, ensuring you never miss a deadline or important date.</p>
            <ul>
                <li><span class="highlight">Set Reminders</span>: Stay on top of maintenance, warranties, and more.</li>
            </ul>

            <h2>7. QR/Barcode Scanning</h2>
            <p>Use QR and barcode scanning to quickly add or locate items in your inventory.</p>
            <ul>
                <li><span class="highlight">Scan Items</span>: Streamline the process of finding and organizing your items.</li>
            </ul>

            <h2>8. Cloud Sync and Multi-Device Access</h2>
            <p>Sync your inventory data across multiple devices and access it anywhere with cloud backup options.</p>
            <ul>
                <li><span class="highlight">Backup and Sync</span>: Keep your inventory data safe and accessible across devices.</li>
            </ul>

            <h2>9. Sharing and Collaboration</h2>
            <p>Share your inventory with others for collaborative management of items and spaces.</p>
            <ul>
                <li><span class="highlight">Share Inventory</span>: Allow others to view or manage specific folders or your entire inventory.</li>
            </ul>

            <h2>10. Customizable Settings</h2>
            <p>Personalize the app with various settings, such as appearance options like dark mode and font size.</p>
            <ul>
                <li><span class="highlight">Personalize Your Experience</span>: Customize the look and feel of the app to suit your preferences.</li>
            </ul>

        </Card >
    )
}

export default StartGuide;