import { Button, Divider, Flex, Input, Switch, Table, Image, Dropdown, Pagination, Badge, Checkbox, Menu, Card, theme, Skeleton, Empty, Space } from "antd";
import * as Icon from "@ant-design/icons";
import { useCallback, useEffect, useState } from "react";
import _ from "lodash";
import DashBoardService from "services/dashboard";
import * as Number from "../../common/number_format";
import * as Date from "../../common/date_format";
import ItemService from "services/item";
import StartGuide from "components/quick_start_guide";

const dashboardService = new DashBoardService();
const itemService = new ItemService();

function Dashboard() {
    const { token } = theme.useToken();

    const [data, setData] = useState();

    useEffect(() => {
        fetchDashboard();
    }, []);

    const fetchDashboard = async () => {
        const data = await dashboardService.get();

        setData(data);
    };

    return (
        <>
            <Flex justify="space-between" align="center">
                <h1>Dashboard</h1>
            </Flex>

            <Divider />
            <StartGuide></StartGuide>
            <Skeleton loading={!data} active>
                {data && (
                    <div style={{ marginBottom: token.margin }}>
                        <div>
                            <h2>Inventory Summary</h2>

                            <Flex justify="center" gap={40} wrap>
                                <InformationCard title="Items" value={data.items} icon={<Icon.FileOutlined />} />
                                <InformationCard title="Folders" value={data.folders} icon={<Icon.FolderOutlined />} />
                                <InformationCard title="Total Quantity" value={Number.formatInt(data.quantity)} icon={<Icon.BarChartOutlined />} />
                                <InformationCard title="Total Value" value={Number.format(data.amount)} icon={<Icon.DollarOutlined />} />
                                <InformationCard title="User" value={data.users} icon={<Icon.UserOutlined />} />
                            </Flex>
                        </div>

                        <div>
                            <h2>Recent Activity</h2>

                            <Flex vertical gap={12}>
                                {data.user_activities.map((activity) => (
                                    <Flex justify="space-between" style={{ backgroundColor: token.colorBorderBg, padding: token.padding, borderRadius: token.borderRadiusLG }}>
                                        <span>{`${activity.create_by_name} ${activity.message}`}</span>
                                        <span>{Date.formatDateTime(activity.create_on)}</span>
                                    </Flex>
                                ))}
                            </Flex>
                        </div>
                    </div>
                )}
            </Skeleton>
        </>
    );
}

function InformationCard({ title, value, icon }) {
    const { token } = theme.useToken();

    return (
        <Card>
            <Space direction="vertical" align="center" style={{ width: "120px" }}>
                <span style={{ fontSize: "24px" }}>{icon}</span>
                <span style={{ fontSize: token.fontSizeHeading3 }}>{value}</span>
                <span>{title}</span>
            </Space>
        </Card>
    );
}

export default Dashboard;
