import BaseService from "services/base";
import _ from "lodash";

class ExportService extends BaseService {
  /**
   *
   * @param {Array[item]} data
   * @param {ExportSetting} options
   * @returns {{fileUrl}}
   */
  export = async (data, options) => {
    var url = await this._api.post("export/export", { data, options });
    return url;
  };

  getExportsType = () => [
    {
      type: "spreadsheet",
      name: "Spreadsheet",
      file: "CSV, XLSX",
    },
    {
      type: "page",
      name: "Page (s)",
      file: "PDF",
    },
    
  ];

  getLayoutsType = () => [
    {
      type: "page_album",
      name: "Album",
    },
    {
      type: "page_list",
      name: "List",
    },
    {
      type: "page_compact",
      name: "Compact",
    },
  ];
}

export default ExportService;
