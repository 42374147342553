import { useEffect, memo, useState } from "react";
import { Button, Flex, Space, Divider, Col, Row, Card, Pagination, theme, Image, Dropdown, Checkbox, Statistic } from "antd";

import * as Icon from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import * as Text from "components/Text";
import _ from "lodash";
import { useAppContext } from "context/app";
import * as Number from "common/number_format";
import Tags from "components/tag-list";
import { CustomButtonLink } from "components/Buttons";

function Item({ data }) {
    const navigate = useNavigate();
    const { token } = theme.useToken();

    return (
        <div
            style={{
                width: 230,
                height: 288,
                marginRight: token.margin,
                marginBottom: token.margin,
                borderRadius: 8,
                backgroundColor: token.colorBgContainer,
                borderColor: token.colorBorder,
                borderStyle: "solid",
                borderWidth: 1,
                position: "relative",
                overflow: "hidden",
            }}
        >
            <div style={{ position: "absolute", top: 0, height: "100%", width: "100%" }}>
                <div style={{ height: "45%", width: "100%", overflow: "hidden", cursor: "pointer" }} onClick={(e) => navigate(`/item-detail/${data.id}`)}>
                    {data?.photos ? (
                        <img src={data?.photos[0].url} style={{ width: "100%", height: "100%", objectFit: "cover" }}></img>
                    ) : (
                        <div style={{ height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Icon.FileImageOutlined style={{ display: "block", fontSize: "max(3vw, 42px)", marginTop: "20%", color: token.colorPrimary }} />
                        </div>
                    )}
                </div>

                <div style={{ padding: token.paddingSM }}>
                    <Text.Title>{data.name}</Text.Title>

                    <div>{data.variant_title}</div>

                    <div>
                        <Space>
                            <div>{Number.formatInt(data.stock_sum.quantity)}</div>
                            <Divider type="vertical"></Divider>
                            <div> {Number.format(data.stock_sum.total_amount)}</div>
                        </Space>

                        {data.has_variants && <div>Variants {data.variant.childs.length}</div>}
                    </div>
                    <div style={{ marginTop: token.margin, justifyContent: "flex-end", display: "flex" }}>
                        <Tags tag_ids={data.tags}></Tags>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Item;
