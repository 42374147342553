import { Button, Divider, Flex, Input, Form, Card, theme, Switch } from "antd";
import * as Icon from "@ant-design/icons";
import React, { useState, useMemo, useEffect, useContext } from "react";
import { useNotification } from "context/notification";
import { useLoading } from "context/loading";
import { ImagePicker, MultiImagePicker } from "components/pickers/image";
import "./profile.css";
import { useAuth } from "context/auth";
import { SaveButton } from "components/Buttons";
import AccountService from "services/account";
import ColorsPicker from "components/pickers/color/ColorPicker";

const accountService = new AccountService();

function Profile() {
    const { token } = theme.useToken();
    const [form] = Form.useForm();
    const { success, error } = useNotification();
    const { showLoading, dismissLoading } = useLoading();
    const { user, checkLogin } = useAuth();

    useEffect(() => {
        if (user) {
            form.setFieldsValue({
                first_name: user.first_name,
                last_name: user.last_name,
                avatar: user.avatar ? [{ url: user.avatar }] : null,
                primary_color: user?.web_setting?.primary_color || "#0284c7",
                darkmode: user?.web_setting?.darkmode === "on" ? true : false,
            });
        }
    }, [user]);

    const onOk = () => {
        form.submit();
    };

    const onFinish = async () => {
        showLoading("Saving");
        const data = { ...form.getFieldsValue() };
        console.log({ data });
        try {
            const result = await accountService.update({
                first_name: data.first_name,
                last_name: data.last_name,
                avatar: data.avatar ? data.avatar[0].url : undefined,
                web_setting: {
                    primary_color: data.primary_color,
                    darkmode: data.darkmode ? "on" : "off",
                },
            });
            if (result) {
                success(`Update profile has been successfully`);
                checkLogin();
            }
        } catch (err) {
            error("Failed update profile. Please try again later.");
        } finally {
            dismissLoading();
        }
    };

    return (
        <>
            <Flex>
                <h1>User Profile</h1>
            </Flex>

            <Divider />

            <Card style={{ width: "700px", margin: "0 auto" }}>
                <Form layout="vertical" form={form} initialValues={{}} onFinish={onFinish}>
                    <span style={{ display: "block", fontSize: token.fontSizeHeading4, marginBottom: token.marginLG }}>Personal Information</span>
                    <Form.Item label="Avatar" name="avatar" className="custom_image_picker">
                        <ImagePicker />
                    </Form.Item>

                    <Flex justify="space-between" gap={12}>
                        <Form.Item
                            label="First Name"
                            name="first_name"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                            style={{ flex: 1 }}
                        >
                            <Input placeholder="First Name"></Input>
                        </Form.Item>

                        <Form.Item
                            label="Last Name"
                            name="last_name"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                            style={{ flex: 1 }}
                        >
                            <Input placeholder="Last Name"></Input>
                        </Form.Item>
                    </Flex>

                    <Divider />

                    <span style={{ display: "block", fontSize: token.fontSizeHeading4, marginBottom: token.marginLG }}>Theme Setting</span>
                    <Form.Item
                        label="Primary Color"
                        name="primary_color"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                        style={{ flex: 1 }}
                    >
                        <ColorsPicker />
                    </Form.Item>

                    <Form.Item
                        label="Dark Mode"
                        name="darkmode"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                        style={{ flex: 1 }}
                    >
                        <Switch />
                    </Form.Item>
                </Form>

                <div style={{ float: "right" }}>
                    <Button type="primary" onClick={() => onOk()}>
                        Update
                    </Button>
                </div>
            </Card>
        </>
    );
}

export default Profile;
