import React from "react";
import Mainlayout from "components/app-layout";
import AuthProvider from "context/auth";
import ConfigUserProvider from "context/config";

//algorithm: theme.darkAlgorithm,
const App = () => {
    return (
        <AuthProvider>
            <ConfigUserProvider>
                <Mainlayout></Mainlayout>
            </ConfigUserProvider>
        </AuthProvider>
    );
};

export default App;
