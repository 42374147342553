import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Input, Radio, Row, Col, Checkbox, Space, Divider, Layout, theme, InputNumber, Switch } from "antd";
import * as Icon from "@ant-design/icons";
import * as Picker from "components/pickers";
import * as Buttons from "components/Buttons";

import StickyBar from "components/sticky-bar";
import FolderService from "services/folder";
import { useLoading } from "context/loading";
import CustomFieldService from "services/custom_field";
import InputCustomField from "components/CustomField/InputCustomField";
import { useNotification } from "context/notification";
import _ from 'lodash'
import * as field_type_const from "components/CustomField/constants";
import { BrowserRouter, useNavigate, useParams } from "react-router-dom";
const { useToken } = theme;
const service = new FolderService();

const ItemEditor = ({ data }) => {
  const [form] = Form.useForm();
  const { token } = useToken();
  const quantity = Form.useWatch("quantity", { form });
  const price = Form.useWatch("price", { form });
  const navigate = useNavigate();
  const has_customField = Form.useWatch("has_customField", { form });


  const itemNameInputRef = useRef();
  const [listCustomField, setListCustomField] = useState([]);

  const { showLoading, dismissLoading } = useLoading();
  const { success, error } = useNotification();
  const [submittable, setSubmittable] = useState(false)

  const values = Form.useWatch([], form);
  const [formErrors, setFormErrors] = useState([]);
  const params = useParams();
  React.useEffect(() => {

    form.setFieldsValue({ ...data, parent_id: params.folder_id });


  }, [data])

  React.useEffect(() => {

    form
      .validateFields({ validateOnly: true })
      .then(() => {
        console.log("set submit")
        setSubmittable(true);
        setFormErrors([]);

      })
      .catch((error) => {
        console.log("set submit", error)
        if (error.errorFields && error.errorFields.length > 0) {
          setFormErrors(error.errorFields);
          setSubmittable(false)
        } else {
          setSubmittable(true);
          setFormErrors([]);
        }


      });
  }, [form, values]);


  useEffect(() => {


    itemNameInputRef.current.focus();
  }, []);

  useEffect(() => {
    fetchListCustomField();
  }, []);

  const fetchListCustomField = async () => {
    const customFieldService = new CustomFieldService();
    const result = await customFieldService.get();

    setListCustomField(result);
  };

  const onFinish = async (data) => {
    showLoading("Saving");
    console.log("data", data);
    if (data.id) {
      var item = await service.update(data);
      success(`${data.name} has been successfully updated`);

    } else {
      var item = await service.add(data);
      success(`${data.name} has been successfully created`);
      navigate(`/folder/${item.id}`)
    }

    dismissLoading();

  };




  const submitForm = () => {
    form.submit();
  };

  return (
    <div>
      <Form layout="vertical" form={form} initialValues={{}} onFinish={onFinish}>
        <StickyBar>
          <div
            style={{
              flexGrow: 1,
            }}
          >
            <h1>Add Folder</h1>
          </div>

          <div
            style={{
              alignContent: "center",
            }}
          >
            <Space size="middle">


              <Form.Item name="parent_id" layout="horizontal" label='Add To' noStyle>
                <Picker.Folder placeholder=''></Picker.Folder>
              </Form.Item>
              <Button onClick={null}>Cancel</Button>
              <Buttons.SaveButton onClick={submitForm} disabled={!submittable}></Buttons.SaveButton>

            </Space>
          </div>
        </StickyBar>
        <Divider></Divider>
        {/* <div>
        {JSON.stringify(formErrors)}
      </div> */}


        <Row gutter={16}>

          <Col span={16}>
            <Form.Item
              name="id"
              hidden
            >

            </Form.Item>

            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input placeholder="Item Name" size="large" ref={itemNameInputRef} />
            </Form.Item>


            <Form.Item label="Tags" name="tags">
              <Picker.Tags></Picker.Tags>
            </Form.Item>

            <Form.Item label="Notes" name="notes">
              <Input.TextArea placeholder="Notes" />
            </Form.Item>
            <Form.Item label="Qr/Bar Code" name="code">
              <Picker.QrCode></Picker.QrCode>
            </Form.Item>


            {/* <Form.Item label="Custom Fields" layout="horizontal" name="has_customField" valuePropName="checked">
              <Switch></Switch>
            </Form.Item> */}

            {/* {listCustomField.map((item) => (
              <Form.Item
                name={["custom_field", item.id]}
                hidden={!has_customField}
                label={item.field_type === field_type_const.ROUND_NUMBER ? `${item.name}, ${item.round_number_unit}` : item.name}
                layout={item.field_type === field_type_const.CHECK_BOX ? "horizontal" : "vertical"}
              >
                <InputCustomField item={item} />
              </Form.Item>
            ))} */}
          </Col>
          <Col span={8}>
            <Form.Item label="Photos" name="photos">
              <Picker.MultiImagePicker maxPhotos={2} acceptedFormats={["image/jpeg", "image/png"]} maxFileSize={5242880}></Picker.MultiImagePicker>
            </Form.Item>
          </Col>
        </Row>


      </Form>
    </div>
  );
};

export default ItemEditor;
