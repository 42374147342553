import { useEffect, useState } from "react";
import { Divider, Flex, Collapse, Button, Form, Switch, InputNumber, Select, theme } from "antd";
import { CheckCircleFilled } from "@ant-design/icons";
import FilterFolder from "./FilterFolders";
import { useForm, useWatch } from "antd/es/form/Form";
import FilterNumber from "./FilterNumber";
import TagPicker from "../../../components/pickers/tag";
import { useAppContext } from "context/app";
import FilterSelect from "./FilterSelect";
import FilterDate from "./FilterDate";

function Filter({ filters, setFilters, setShowFilterFolder, collapseExpandKey, setCollapseExpandKey, activeFilters, setActiveFilters }) {
    const { token } = theme.useToken();
    const [form] = useForm();

    const filter_folder = useWatch("filter_folder", form);

    const quantity_filter_from = useWatch("quantity_filter_from", form);
    const quantity_filter_to = useWatch("quantity_filter_to", form);
    const quantity_filter_exact = useWatch("quantity_filter_exact", form);

    const filter_items = useWatch("filter_items", form);

    const filter_date = useWatch("filter_date", form);

    const filter_users = useWatch("filter_users", form);

    useEffect(() => {
        setFilter();
    }, [filters]);

    const setFilter = () => {
        const quantity = filters.quantity || {};

        form.setFieldsValue({
            filter_folder: filters.folders || [],

            quantity_checked_exact: quantity.hasOwnProperty("exact"),
            quantity_filter_exact: quantity.exact,
            quantity_filter_from: quantity.gte,
            quantity_filter_to: quantity.lte,

            filter_items: filters.items || undefined,

            filter_date: filters.date || undefined,

            filter_users: filters.users || undefined,
        });
    };

    useEffect(() => {
        const filters = [
            filter_folder?.length > 0,
            quantity_filter_from != null || quantity_filter_to != null || quantity_filter_exact != null,
            filter_items?.length > 0,
            filter_date,
            filter_users?.length > 0,
        ];

        const activeCount = filters.filter(Boolean).length;
        setActiveFilters(activeCount);
    }, [filter_folder, quantity_filter_from, quantity_filter_to, quantity_filter_exact, filter_items, filter_date, filter_users]);

    const handleClearFilter = (filterName) => {
        switch (filterName) {
            case "folders":
                form.setFieldValue("filter_folder", null);
                break;
            case "quantity":
                form.setFieldsValue({
                    [`${filterName}_checked_exact`]: false,
                    [`${filterName}_filter_exact`]: null,
                    [`${filterName}_filter_from`]: null,
                    [`${filterName}_filter_to`]: null,
                });
                break;
            case "items":
                form.setFieldValue("filter_items", null);
                break;
            case "date":
                form.setFieldValue("filter_date", null);
                break;
            case "users":
                form.setFieldValue("filter_users", null);
                break;
            default:
                break;
        }
    };

    const handleClearAll = () => {
        form.resetFields();
    };

    const items = [
        {
            key: "FOLDERS",
            label: (
                <>
                    <span>FOLDERS</span>

                    {filter_folder && filter_folder.length > 0 && (
                        <>
                            <span style={{ backgroundColor: "#333", color: "#fff", padding: filter_folder.length >= 10 ? "3px 5px" : "3px 9px", borderRadius: "50%", marginLeft: 12 }}>
                                {filter_folder.length}
                            </span>
                            <Button
                                type="link"
                                style={{ padding: 6 }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleClearFilter("folders");
                                }}
                            >
                                Clear
                            </Button>
                        </>
                    )}
                </>
            ),
            children: (
                <Form.Item name="filter_folder">
                    <FilterFolder />
                </Form.Item>
            ),
        },
        FilterSection({
            label: "ITEMS",
            isActive: filter_items && filter_items.length > 0,
            onClear: () => handleClearFilter("items"),
            children: (
                <Form.Item name="filter_items" style={{ margin: 0 }}>
                    <FilterSelect type="items" />
                </Form.Item>
            ),
        }),
        FilterSection({
            label: "QUANTITY",
            isActive:
                (quantity_filter_exact !== null && quantity_filter_exact !== undefined) ||
                (quantity_filter_from !== null && quantity_filter_from !== undefined) ||
                (quantity_filter_to !== null && quantity_filter_to !== undefined),
            onClear: () => handleClearFilter("quantity"),
            children: <FilterNumber form={form} filterType="quantity" />,
        }),
        FilterSection({
            label: "DATE",
            isActive: filter_date,
            onClear: () => handleClearFilter("date"),
            children: (
                <Form.Item name="filter_date" style={{ margin: 0 }}>
                    <FilterDate />
                </Form.Item>
            ),
        }),
        FilterSection({
            label: "USERS",
            isActive: filter_users && filter_users.length > 0,
            onClear: () => handleClearFilter("users"),
            children: (
                <Form.Item name="filter_users" style={{ margin: 0 }}>
                    <FilterSelect type="users" />
                </Form.Item>
            ),
        }),
    ];

    const onChange = (key) => {
        console.log(key);
        setCollapseExpandKey(key);
    };

    const updateFilter = (prev, fieldValue, filterName, checkExactKey, exactKey, rangeKeys) => {
        if (fieldValue[checkExactKey]) {
            if (fieldValue[exactKey] !== undefined && fieldValue[exactKey] !== null) {
                return {
                    ...prev,
                    [filterName]: {
                        exact: fieldValue[exactKey],
                    },
                };
            }
            return removeFilter(prev, filterName);
        }

        const filter = {};
        if (fieldValue[rangeKeys.from] !== undefined && fieldValue[rangeKeys.from] !== null) {
            filter.gte = fieldValue[rangeKeys.from];
        }
        if (fieldValue[rangeKeys.to] !== undefined && fieldValue[rangeKeys.to] !== null) {
            filter.lte = fieldValue[rangeKeys.to];
        }

        if (Object.keys(filter).length > 0) {
            return {
                ...prev,
                [filterName]: filter,
            };
        }

        return removeFilter(prev, filterName);
    };

    const removeFilter = (prev, filterName) => {
        const { [filterName]: removedFilter, ...rest } = prev;
        return rest;
    };

    const handleApply = () => {
        const fieldValue = form.getFieldValue();
        console.log({ fieldValue });
        setFilters((prev) => {
            let updatedFilters = { ...prev };

            // Set filter folder
            if (Array.isArray(fieldValue.filter_folder) && fieldValue.filter_folder.length > 0) {
                updatedFilters = { ...updatedFilters, folders: fieldValue.filter_folder };
            } else {
                const { folders, ...rest } = updatedFilters;
                updatedFilters = rest;
            }

            // Set filter quantity
            updatedFilters = updateFilter(updatedFilters, fieldValue, "quantity", "quantity_checked_exact", "quantity_filter_exact", { from: "quantity_filter_from", to: "quantity_filter_to" });

            // Set filter items
            if (Array.isArray(fieldValue.filter_items) && fieldValue.filter_items.length > 0) {
                updatedFilters = { ...updatedFilters, items: fieldValue.filter_items };
            } else {
                const { items, ...rest } = updatedFilters;
                updatedFilters = rest;
            }

            // Set filter date
            if (fieldValue.filter_date?.time && fieldValue.filter_date?.radioChecked) {
                updatedFilters = {
                    ...updatedFilters,
                    date: filter_date,
                };
            } else {
                const { date, ...rest } = updatedFilters;
                updatedFilters = rest;
            }

            // Set filter users
            if (Array.isArray(fieldValue.filter_users) && fieldValue.filter_users.length > 0) {
                updatedFilters = { ...updatedFilters, users: fieldValue.filter_users };
            } else {
                const { users, ...rest } = updatedFilters;
                updatedFilters = rest;
            }

            return updatedFilters;
        });

        setShowFilterFolder(false);
    };

    return (
        <Flex vertical style={{ position: "fixed", right: 0, zIndex: 5, backgroundColor: token.colorBgBase, boxShadow: token.boxShadow, width: "350px" }}>
            <div style={{ flex: 1 }}>
                <Flex align="center" gap={4} style={{ borderBottom: "1px solid #ccc" }}>
                    <span style={{ display: "block", fontSize: token.fontSizeHeading4, margin: `${token.margin}px ${token.marginSM}px` }}>Filters</span>
                    {activeFilters > 0 && (
                        <>
                            <Flex align="center" justify="space-between" style={{ flex: 1 }}>
                                <span style={{ fontSize: token.fontSizeHeading5, backgroundColor: "red", color: "white", padding: "2px 8px", borderRadius: "50%" }}>{activeFilters}</span>
                                <Button type="link" onClick={handleClearAll}>
                                    Clear All
                                </Button>
                            </Flex>
                        </>
                    )}
                </Flex>

                <div style={{ height: "calc(100vh - 125px)", overflowY: "scroll" }}>
                    <Form form={form}>
                        <Collapse
                            items={items}
                            expandIconPosition="end"
                            onChange={onChange}
                            style={{ borderRadius: 0, borderLeft: "none", borderRight: "none" }}
                            defaultActiveKey={collapseExpandKey}
                        />
                    </Form>
                </div>
            </div>

            <Flex gap={16} style={{ margin: token.margin }}>
                <Button style={{ flex: 1 }} type="primary" onClick={() => handleApply()}>
                    APPLY
                </Button>
                <Button
                    style={{ flex: 1 }}
                    onClick={() => {
                        setFilter();
                        setTimeout(() => {
                            setShowFilterFolder(false);
                        }, 0);
                    }}
                >
                    CANCEL
                </Button>
            </Flex>
        </Flex>
    );
}

export default Filter;

function FilterSection({ label, isActive, onClear, children }) {
    return {
        key: label.toUpperCase().replace(" ", "_"),
        label: (
            <Flex align="center" gap={16}>
                <span>{label}</span>
                {isActive && (
                    <Flex>
                        <CheckCircleFilled style={{ fontSize: "20px" }} />
                        <Button
                            type="link"
                            style={{ padding: 6 }}
                            onClick={(e) => {
                                e.stopPropagation();
                                onClear();
                            }}
                        >
                            Clear
                        </Button>
                    </Flex>
                )}
            </Flex>
        ),
        children: children,
    };
}
