import { useEffect, memo, useState, useRef } from "react";
import { Button, Flex, Space, Divider, Col, Row, Card, Pagination, Typography, Select, Dropdown, Input, theme, Table, Tag, Checkbox, InputNumber, Form, Switch } from "antd";
import * as Icon from "@ant-design/icons";
import AttributeService from "services/attribute";
import * as Picker from "components/pickers";
import _ from "lodash";
import AddOrEditAttributeModal from "components/Attribute/AddOrEditAttributeModal";
import { CustomButtonLink } from "components/Buttons";
const { Title } = Typography;
const attributeService = new AttributeService();
const { useToken } = theme;
const { Text, Link } = Typography;
const VariantsPicker = (props) => {
    const { value = { attributes: [], childs: [] }, onChange, showProducts = false, error, edit } = props;
    const [attributes, setAttributes] = useState([]);
    const attributesRef = useRef([]);
    const [menuItems, setMenuItems] = useState([]);
    const [allAttributes, setAllAtributes] = useState([]);
    const [products, setProduct] = useState([]);
    const unSaveProductListRef = useRef([]);
    const [visible, setVisible] = useState(false);

    //  console.log("222", { status, errors } )
    const { token } = useToken();
    useEffect(() => {
        const fetchAllAttribute = async () => {
            var lst = await attributeService.get();
            setAllAtributes(lst);
        };
        fetchAllAttribute();
        unSaveProductListRef.current = [];
    }, []);

    useEffect(() => {
        // var isEqual = _.isEqual(value, newValue);
        // if (tt > 5)
        //     return;
        // tt++;
        var isEqual = _.isEqual(value.attributes, attributesRef.current);
        if (isEqual) return;
        setAttributes([...value.attributes]);
        //setProduct([...value.childs])
    }, [value]);

    useEffect(() => {
        // var isEqual = _.isEqual(value, newValue);
        // if (tt > 5)
        //     return;
        // tt++;
        var isEqual = _.isEqual(value.childs, products);
        if (isEqual) return;
        setProduct([...value.childs]);
        //setProduct([...value.childs])
    }, [value.childs]);

    useEffect(() => {
        var lstMenu = [];
        //not include selected
        var selectable = allAttributes.filter((x) => {
            const notExist =
                attributes.findIndex((a) => {
                    return a.id == x.id;
                }) < 0;
            return notExist;
        });

        lstMenu = selectable.map((att) => {
            return {
                label: att.name,
                key: att.id,
            };
        });
        if (lstMenu.length > 0) {
            lstMenu.push({ type: "divider" });
        }
        lstMenu.push({
            label: "New Attribute",
            key: "new_attribute",
            icon: <Icon.PlusOutlined></Icon.PlusOutlined>,
        });
        setMenuItems(lstMenu);
    }, [allAttributes, attributes]);

    const onAddAtribute = ({ item, key, keyPath, selectedKeys, domEvent }) => {
        if (key == "new_attribute") {
            setVisible(true);
            return;
        }
        domEvent.preventDefault();
        // var attr = allAttributes.find(x => x.id == key);
        // attr.selectedOptions = [];
        var attr = { id: key, options: [] };
        var newList = [...attributes];
        newList.push({ ...attr });
        setAttributes(newList);
        //add atribute
    };

    const onDeleteAttribute = (attr) => {
        var newList = [...attributes];
        newList = newList.filter((x) => x.id != attr.id);
        setAttributes(newList);
    };

    const onOptionsChange = (attrId, options) => {
        var newList = [...attributes];
        var old = newList.find((x) => x.id == attrId);
        old.options = options;
        setAttributes(newList);
    };
    useEffect(() => {
        var lst = [];

        if (attributes.length == 3) {
            attributes[0].options.forEach((attr0) => {
                attributes[1].options.forEach((attr1) => {
                    attributes[2].options.forEach((attr2) => {
                        lst.push({
                            variants: [
                                { id: attributes[0].id, option: attr0 },
                                { id: attributes[1].id, option: attr1 },
                                { id: attributes[2].id, option: attr2 },
                            ],
                        });
                    });
                });
            });
        }

        if (attributes.length == 2) {
            attributes[0].options.forEach((attr0) => {
                attributes[1].options.forEach((attr1) => {
                    lst.push({
                        variants: [
                            { id: attributes[0].id, option: attr0 },
                            { id: attributes[1].id, option: attr1 },
                        ],
                    });
                });
            });
        }

        if (attributes.length == 1) {
            attributes[0].options.forEach((attr0) => {
                lst.push({
                    variants: [{ id: attributes[0].id, option: attr0 }],
                });
            });
        }
        lst = lst.map((x) => {
            x.variantsKey = x.variants.map((v) => `${v.id}-${v.option}`).join("_");
            return x;
        });
        var oldChilds = value.childs;
        for (let index = 0; index < lst.length; index++) {
            const element = lst[index];
            var old = oldChilds.find((x) => x.variantsKey == element.variantsKey);
            if (old) {
                lst[index] = { ...old };
            } else {
                old = unSaveProductListRef.current.find((x) => x.variantsKey == element.variantsKey);
                if (old) {
                    lst[index] = { ...old };
                }
            }
        }

        setProduct(lst);
        triggerOnChange({ attributes, childs: lst });
        attributesRef.current = attributes;
    }, [attributes]);

    const triggerOnChange = (newValue) => {
        var isEqual = _.isEqual(value, newValue);
        if (!isEqual) {
            onChange(newValue);
        }
    };

    const onChildChange = (record, name, newValue) => {
        var lst = [...products];
        var old = lst.find((x) => x.variantsKey == record.variantsKey);
        old[name] = newValue;
        setProduct(lst);
        triggerOnChange({ attributes: attributesRef.current, childs: lst });
        unSaveProductListRef.current = [...lst];
    };

    const onChildAllChange = (name, newValue) => {
        var lst = [...products];
        lst.forEach((x) => (x[name] = newValue));
        setProduct(lst);
        triggerOnChange({ attributes: attributesRef.current, childs: lst });
        unSaveProductListRef.current = [...lst];
    };

    const onChangeItemAddAttribute = (result) => {
        setAttributes((prev) => [...prev, result]);
        setAllAtributes((prev) => [...prev, result]);
        setVisible(false);
    };

    return (
        <div>
            <AddOrEditAttributeModal visible={visible} onClose={() => setVisible(false)} onChangeItemAddAttribute={onChangeItemAddAttribute} />

            <div>ATTRIBUTES AND OPTIONS</div>
            <div>
                {attributes.map((attr, i) => {
                    return <AttributePicker allAttributes={allAttributes} data={attr} onDelete={(e) => onDeleteAttribute(attr)} onOptionsChange={onOptionsChange}></AttributePicker>;
                })}
            </div>
            <div style={{ marginTop: token.margin }}>
                <Dropdown
                    menu={{
                        onClick: onAddAtribute,
                        items: menuItems,
                    }}
                    trigger={["click"]}
                >
                    <CustomButtonLink onClick={(e) => e.preventDefault()}>
                        <Space>
                            <Icon.PlusOutlined></Icon.PlusOutlined>Add Attribute
                        </Space>
                    </CustomButtonLink>
                </Dropdown>
                <div>{error && <Text type="danger">{error?.title}</Text>}</div>

                {showProducts && (
                    <div style={{ marginTop: token.margin }}>
                        {products.length} Item Variants
                        <div>
                            <ProductVariants data={products} onChange={onChildChange} onChangeAll={onChildAllChange} edit={edit}></ProductVariants>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

const AttributePicker = memo(({ data, onDelete, onOptionsChange, allAttributes, status }) => {
    const { token } = useToken();
    const [options, setOptions] = useState([]);
    const [attribute, setAttribute] = useState({});
    useEffect(() => {
        var attr = allAttributes.find((x) => x.id == data.id);
        if (attr) {
            var lst = attr.options.map((x) => ({ value: x, label: x }));
            setOptions(lst);
            setAttribute(attr);
        } else {
            setOptions([]);
            setAttribute({});
        }
    }, [data, allAttributes]);
    return (
        <div style={{ marginTop: token.margin }}>
            <Row gutter={16}>
                <Col span={8}>
                    <Input contentEditable={false} value={attribute.name} status="none"></Input>
                </Col>
                <Col span={14}>
                    <Select
                        showSearch
                        mode="tags"
                        placeholder="Select options"
                        optionFilterProp="label"
                        value={data.options}
                        onChange={(e) => onOptionsChange(data.id, e)}
                        options={options}
                        status={data.options.length == 0 ? "error" : "none"}
                    />
                    {data.options.length == 0 && (
                        <div>
                            <Text type="danger">{`Options can't be blank`}</Text>
                        </div>
                    )}
                </Col>

                <Col span={2}>
                    <CustomButtonLink onClick={onDelete}>
                        <Icon.DeleteOutlined></Icon.DeleteOutlined>
                    </CustomButtonLink>
                </Col>
            </Row>
        </div>
    );
});

const ProductVariants = memo(({ data = [], onChange, onChangeAll, edit }) => {
    const [input, setInput] = useState({
        quantity: {
            check: false,
            value: null,
        },
        min_level: {
            check: false,
            value: null,
        },
        lowstock_alert: {
            check: false,
            value: null,
        },
        price: {
            check: false,
            value: null,
        },
        tags: {
            check: false,
            value: null,
        },
        notes: {
            check: false,
            value: null,
        },
    });

    const quantityInputRef = useRef(null);
    const minLevelInputRef = useRef(null);
    const priceInputRef = useRef(null);
    const tagsInputRef = useRef(null);
    const notesInputRef = useRef(null);

    const showOrHiddenInput = (key, status) => {
        setInput((prev) => ({
            ...prev,
            [key]: {
                ...prev[key],
                check: status,
            },
        }));

        if (status) {
            setTimeout(() => {
                switch (key) {
                    case "quantity":
                        quantityInputRef.current?.focus();
                        break;
                    case "min_level":
                        minLevelInputRef.current?.focus();
                        break;
                    case "price":
                        priceInputRef.current?.focus();
                        break;
                    case "tags":
                        tagsInputRef.current?.focus();
                        break;
                    case "notes":
                        notesInputRef.current?.focus();
                        break;
                    default:
                        break;
                }
            }, 0);
        }
    };

    const setValueInput = (key, value) => {
        setInput((prev) => ({
            ...prev,
            [key]: {
                ...prev[key],
                value: value,
            },
        }));
    };

    var columns = [
        // {
        //     title: "",
        //     dataIndex: "id",
        //     key: "id",
        //     width: 50,
        //     render: (text, record) => <Checkbox></Checkbox>,
        //     fixed: "left",
        // },
        {
            title: "Variant",
            dataIndex: "variantsKey",
            key: "variant",
            width: 150,
            fixed: "left",
            render: (text, record) => (
                <Flex vertical align="center">
                    <Picker.MultiImagePicker
                        value={record.photos}
                        maxPhotos={1}
                        acceptedFormats={["image/jpeg", "image/png"]}
                        maxFileSize={5242880}
                        onChange={(e) => onChange(record, "photos", e)}
                    ></Picker.MultiImagePicker>
                    <CustomButtonLink style={{ padding: 0 }}>{record.variants.map((x) => x.option).join(" ")}</CustomButtonLink>
                </Flex>
            ),
        },
        {
            title: (
                <Flex vertical gap={6}>
                    <CustomButtonLink
                        onClick={() => {
                            showOrHiddenInput("quantity", true);
                        }}
                    >
                        Quantity
                        <Icon.EditOutlined />
                    </CustomButtonLink>
                    {input.quantity.check && (
                        <Flex>
                            <InputNumber
                                ref={quantityInputRef}
                                value={input.quantity.value}
                                style={{ width: "100%" }}
                                onChange={(e) => {
                                    setValueInput("quantity", e);
                                    onChangeAll("quantity", e);
                                }}
                            />
                            <CustomButtonLink
                                type="link"
                                onClick={() => {
                                    showOrHiddenInput("quantity", false);
                                }}
                                icon={<Icon.CloseCircleOutlined />}
                            ></CustomButtonLink>
                        </Flex>
                    )}
                </Flex>
            ),
            dataIndex: "quantity",
            key: "quantity",
            render: (text, record) => <InputNumber value={record.quantity} onChange={(e) => onChange(record, "quantity", e)} style={{ width: "100%" }}></InputNumber>,
        },
        {
            title: (
                <Flex vertical gap={6}>
                    <CustomButtonLink
                        type="link"
                        onClick={() => {
                            showOrHiddenInput("min_level", true);
                        }}
                    >
                        Min Level
                        <Icon.EditOutlined />
                    </CustomButtonLink>
                    {input.min_level.check && (
                        <Flex>
                            <InputNumber
                                ref={minLevelInputRef}
                                value={input.min_level.value}
                                style={{ width: "100%" }}
                                onChange={(e) => {
                                    setValueInput("min_level", e);
                                    onChangeAll("min_level", e);
                                }}
                            />
                            <CustomButtonLink
                                type="link"
                                onClick={() => {
                                    showOrHiddenInput("min_level", false);
                                }}
                                icon={<Icon.CloseCircleOutlined />}
                            ></CustomButtonLink>
                        </Flex>
                    )}
                </Flex>
            ),
            dataIndex: "minlevel",
            key: "minlevel",
            render: (text, record) => <InputNumber value={record.min_level} onChange={(e) => onChange(record, "min_level", e)} style={{ width: "100%" }}></InputNumber>,
        },
        {
            title: (
                <Flex vertical gap={6}>
                    <CustomButtonLink
                        type="link"
                        onClick={() => {
                            showOrHiddenInput("lowstock_alert", true);
                        }}
                    >
                        Low Stock Alert
                        <Icon.EditOutlined />
                    </CustomButtonLink>
                    {input.lowstock_alert.check && (
                        <Flex justify="center" align="center">
                            <Switch
                                checked={input.lowstock_alert.value}
                                onChange={(e) => {
                                    setValueInput("lowstock_alert", e);
                                    onChangeAll("lowstock_alert", e);
                                }}
                            />
                            <Button
                                type="link"
                                onClick={() => {
                                    showOrHiddenInput("lowstock_alert", false);
                                }}
                                icon={<Icon.CloseCircleOutlined />}
                            ></Button>
                        </Flex>
                    )}
                </Flex>
            ),
            key: "lowstock_alert",
            dataIndex: "lowstock_alert",
            align: "center",
            render: (text, record) => <Switch value={record.lowstock_alert} onChange={(e) => onChange(record, "lowstock_alert", e)}></Switch>,
        },
        {
            title: (
                <Flex vertical gap={6}>
                    <CustomButtonLink
                        type="link"
                        onClick={() => {
                            showOrHiddenInput("price", true);
                        }}
                    >
                        Price
                        <Icon.EditOutlined />
                    </CustomButtonLink>
                    {input.price.check && (
                        <Flex>
                            <InputNumber
                                ref={priceInputRef}
                                value={input.price.value}
                                style={{ width: "100%" }}
                                onChange={(e) => {
                                    setValueInput("price", e);
                                    onChangeAll("price", e);
                                }}
                            />
                            <CustomButtonLink
                                type="link"
                                onClick={() => {
                                    showOrHiddenInput("price", false);
                                }}
                                icon={<Icon.CloseCircleOutlined />}
                            ></CustomButtonLink>
                        </Flex>
                    )}
                </Flex>
            ),
            key: "price",
            dataIndex: "price",
            render: (text, record) => <InputNumber value={record.price} onChange={(e) => onChange(record, "price", e)} style={{ width: "100%" }}></InputNumber>,
        },
        {
            title: (
                <Flex vertical gap={6}>
                    <CustomButtonLink
                        type="link"
                        onClick={() => {
                            showOrHiddenInput("tags", true);
                        }}
                    >
                        Tags
                        <Icon.EditOutlined />
                    </CustomButtonLink>

                    {input.tags.check && (
                        <Flex justify="center" align="center">
                            <Picker.Tags
                                ref={tagsInputRef}
                                value={input.tags.value}
                                onChange={(e) => {
                                    setValueInput("tags", e);
                                    onChangeAll("tags", e);
                                }}
                            ></Picker.Tags>
                            <CustomButtonLink
                                type="link"
                                onClick={() => {
                                    showOrHiddenInput("tags", false);
                                }}
                                icon={<Icon.CloseCircleOutlined />}
                            ></CustomButtonLink>
                        </Flex>
                    )}
                </Flex>
            ),
            key: "tags",
            dataIndex: "tags",
            render: (text, record) => <Picker.Tags value={record.tags} onChange={(e) => onChange(record, "tags", e)}></Picker.Tags>,
        },
        {
            title: (
                <Flex vertical gap={6}>
                    <CustomButtonLink
                        type="link"
                        onClick={() => {
                            showOrHiddenInput("notes", true);
                        }}
                    >
                        Notes
                        <Icon.EditOutlined />
                    </CustomButtonLink>
                    {input.notes.check && (
                        <Flex>
                            <Input
                                ref={notesInputRef}
                                value={input.notes.value}
                                onChange={(e) => {
                                    setValueInput("notes", e.target.value);
                                    onChangeAll("notes", e.target.value);
                                }}
                            ></Input>
                            <CustomButtonLink
                                type="link"
                                onClick={() => {
                                    showOrHiddenInput("notes", false);
                                }}
                                icon={<Icon.CloseCircleOutlined />}
                            ></CustomButtonLink>
                        </Flex>
                    )}
                </Flex>
            ),
            key: "Note",
            dataIndex: "Note",
            render: (text, record) => <Input value={record.notes} onChange={(e) => onChange(record, "notes", e.target.value)}></Input>,
        },
        // {
        //     title: "",
        //     key: "action",
        //     fixed: "right",
        //     width: 50,
        //     render: (_, record) => (
        //         <Space size="middle">
        //             <Button type="link">
        //                 <Icon.DeleteOutlined></Icon.DeleteOutlined>
        //             </Button>
        //         </Space>
        //     ),
        // },
    ];

    if (edit) {
        columns = columns.filter((x) => !["quantity", "minlevel", "lowstock_alert"].includes(x.key));
    }

    return (
        <Table
            columns={columns}
            dataSource={data}
            scroll={{
                x: 1300,
            }}
        />
    );
});

export default memo(VariantsPicker);
