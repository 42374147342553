import { Empty, Select, Spin } from "antd";
import { useCallback, useEffect, useState } from "react";
import _ from "lodash";
import ItemSearchService from "services/item/ItemSearch";
import UserService from "services/user";

const itemSearchService = new ItemSearchService();
const userSearchService = new UserService();

// Type = items or users
function FilterSelect({ value, onChange, type }) {
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState([]);

    useEffect(() => {
        if (type === "users") {
            fetchLstUser();
        }

        onChange(value);
    }, [value]);

    const fetchLstUser = async () => {
        const result = await userSearchService.list();
        const lstOption = result.map((user) => ({
            label: user.name,
            value: user.id,
        }));
        setOptions(lstOption);
    };

    const handleSearch = useCallback(
        _.debounce(async (textSearch) => {
            setLoading(true);

            let result;
            let lstOption;

            if (type === "items") {
                result = await itemSearchService.search({ info: textSearch });
                lstOption = result.items.map((item) => ({
                    label: item.name,
                    value: item.id,
                }));
            } else if (type === "users") {
                result = await userSearchService.list({ info: textSearch });
                lstOption = result.map((user) => ({
                    label: user.name,
                    value: user.id,
                }));
            }

            setOptions(lstOption);

            setLoading(false);
        }, 500),
        []
    );

    const handleChange = (value) => {
        onChange(value.map((x) => ({ label: x.label, value: x.value })));
    };

    return (
        <Select
            mode="multiple"
            value={value}
            labelInValue
            onChange={handleChange}
            filterOption={false}
            onSearch={handleSearch}
            options={options}
            notFoundContent={loading ? <Spin size="small" /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
        />
    );
}

export default FilterSelect;
