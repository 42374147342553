import { useEffect, memo, useState } from "react";
import { Button, Flex, Space, Divider, Col, Row, Card, Pagination, theme, Image, Dropdown, Checkbox } from "antd";
import * as Icon from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import DefaultImages from "assets/DefaultImage"
import * as Text from "components/Text"
import _ from "lodash"
import { useAppContext } from "context/app";
import { useItemsScreenContext } from "pages/items"
import Tags from "components/tag-list"
import ListOptionFolder from "pages/folder/ListOptionFolder";
const { useToken } = theme;
const Item = ({ data }) => {
    const navigate = useNavigate();
    const { token } = useToken();
    const [thumbnail, setThumbnail] = useState(DefaultImages.NoImageItem);
    const { selectedItems, selectItem, currentFolder } = useItemsScreenContext();
    const [check, setCheck] = useState(false)

    useEffect(() => {
        // var url = data.photos?[0].url;
        //
        if (data.photos && data.photos[0]) {
            setThumbnail(data.photos[0].url);
        } else {
            setThumbnail(null);
        }


        var i = selectedItems.find(x => x.id == data.id);
        if (i) {
            setCheck(true);
        } else {
            setCheck(false);
        }

    }
        , [data, selectedItems])


    


    return (
        <div
            style={{
                width: 230,
                height: 200,
                marginRight: token.margin,
                marginBottom: token.margin,
                borderRadius: 8,
                backgroundColor: token.colorBgContainer,
                borderColor: token.colorBorder,
                borderStyle: "solid",
                borderWidth: 1,
                position: "relative", overflow: "hidden"
            }}

        >

            <div style={{ position: "absolute", top: 0, height: "100%", width: "100%" }}>
                <div style={{ position: "absolute", top: 0, width: "100%", zIndex: 2, display: "flex", justifyContent: 'space-between', alignItems: 'center', padding: '0 10px' }}>
                    <div style={{ flexGrow: 1, display: "flex" }} >

                        <Checkbox checked={check} onChange={e => selectItem({ ...data, entity_type: "folder" }, e.target.checked)}></Checkbox>

                    </div>
                    <div>
                        <ListOptionFolder data={data} />
                    </div>
                </div>

                <div style={{ height: "45%", width: "100%", overflow: "hidden", justifyContent: "center", cursor: "pointer" }} onClick={e => navigate(`/folder/${data.id}`)}>
                    {thumbnail ?
                        <img src={thumbnail} style={{ width: "100%", height: "100%", objectFit: "cover" }}></img>

                        :
                        <div style={{ height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Icon.FolderOutlined style={{ display: 'block', fontSize: 'max(2.5vw, 35px)', marginTop: '10%', color: token.colorPrimary }} />
                        </div>


                    }
                </div>

                <div style={{ padding: token.padding }}>


                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{
                            flexGrow: 1, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap",
                            display: "flex",
                            alignItems: "center"


                        }}>
                            <Text.Title>
                                {data.name}
                            </Text.Title>

                        </div>




                    </div>

                    <div style={{ justifyContent: "flex-end", display: "flex" }}>
                        <Tags tag_ids={data.tags}></Tags>
                    </div>
                </div>
            </div>


            {/* //{JSON.stringify(data)} */}
        </div>
    )
}




export default memo(Item);