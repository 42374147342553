import { Modal, Divider, Form, Flex, Select, Image, InputNumber, Input, Button, Switch, Row, Col, theme } from "antd";
import { FileImageOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { CustomButtonLink, SaveButton } from "components/Buttons";
import ItemService from "services/item";
import { useLoading } from "context/loading";
import { useAppContext } from "context/app";
import { getQuantityItem } from "common/get_quantity_item";
import { useNotification } from "context/notification";
import * as Picker from "components/pickers";
import SelectMoveReason from "./SelectMoveReason";
import InputQuantityMove from "./InputQuantityMove";
import * as Number from "common/number_format";

const itemService = new ItemService();

function MoveMultiQuantityItemsModal({ items, folderId, visible, onClose }) {
    const { useToken } = theme;
    const { token } = useToken();

    const [lstItems, setLstItems] = useState([]);
    const { showLoading, dismissLoading } = useLoading();
    const { success, error } = useNotification();
    const [disableSave, setDisableSave] = useState(true);
    const [hasMoveAll, setHasMoveAll] = useState({});
    const [newQuantity, setNewQuantity] = useState({});
    const [form] = Form.useForm();
    const { eventBus } = useAppContext();

    const to_folder_id = Form.useWatch("to_folder_id", form);

    useEffect(() => {
        checkErrors();
    }, [to_folder_id]);

    useEffect(() => {
        if (items) {
            const handleItems = async () => {
                showLoading();

                const itemPromises = items.map(async (item) => {
                    if (item.has_variants) {
                        const folderStockPromises = item.folder_stock.variant_stock.map(async (variantStock) => {
                            const result = await itemService.getByID(variantStock.item_id);
                            return result;
                        });

                        return await Promise.all(folderStockPromises);
                    }

                    return item;
                });

                const result = await Promise.all(itemPromises);
                const lstItems = result.flat();
                setLstItems(lstItems);

                const initialMoveAllState = {};
                lstItems.forEach((item) => {
                    form.setFieldsValue({
                        [`quantity_to_move_${item.id}`]: 1,
                    });

                    initialMoveAllState[item.id] = getQuantityItem(item, folderId) === 1 ? false : true;
                });

                setHasMoveAll(initialMoveAllState);

                form.setFieldsValue({
                    multi_quantity_to_move: 1,
                    notes: "",
                });

                checkErrors();
                dismissLoading();
            };

            handleItems();
        }
    }, [items]);

    useEffect(() => {
        setAllNewQuantity();
    }, [lstItems]);

    const handleClickMultiMoveAll = () => {
        lstItems.forEach((item) => {
            const quantityOfItem = getQuantityItem(item, folderId);
            form.setFieldsValue({
                [`quantity_to_move_${item.id}`]: quantityOfItem,
            });

            setHasMoveAll((prev) => ({
                ...prev,
                [item.id]: false,
            }));
        });

        resetMultiMoveQuantity();
        setAllNewQuantity();

        checkErrors();
    };

    const handleChangeMultiQuantityMove = (value) => {
        lstItems.forEach((item) => {
            form.setFieldsValue({
                [`quantity_to_move_${item.id}`]: value,
            });

            setMoveAll(item, value);
        });

        setAllNewQuantity();
    };

    const handleChangeQuantityMove = (item, value, type) => {
        resetMultiMoveQuantity();
        checkErrors();
        setMoveAll(item, value);
        setAllNewQuantity(item, value);
    };

    const handleMoveAll = (item) => {
        const quantityOfItem = getQuantityItem(item, folderId);
        form.setFieldsValue({
            [`quantity_to_move_${item.id}`]: quantityOfItem,
        });

        setAllNewQuantity(item, quantityOfItem);
        setHasMoveAll((prev) => ({
            ...prev,
            [item.id]: false,
        }));
        resetMultiMoveQuantity();

        checkErrors();
    };

    const setAllNewQuantity = (item = null, value = null) => {
        if (item) {
            const oldQuantity = getQuantityItem(item, folderId);
            const newQuantity = oldQuantity - value;

            setNewQuantity((prev) => ({
                ...prev,
                [item.id]: newQuantity,
            }));
        } else {
            const lstNewQuantity = {};
            lstItems.forEach((item) => {
                const oldQuantity = getQuantityItem(item, folderId);
                const moveQuantity = form.getFieldValue(`quantity_to_move_${item.id}`);
                const newQuantity = oldQuantity - moveQuantity;

                lstNewQuantity[item.id] = newQuantity;
            });
            setNewQuantity(lstNewQuantity);
        }
    };

    const resetMultiMoveQuantity = () => {
        form.setFieldsValue({
            multi_quantity_to_move: undefined,
        });
    };

    const setMoveAll = (item, value) => {
        setHasMoveAll((prev) => ({
            ...prev,
            [item.id]: getQuantityItem(item, folderId) !== value,
        }));
    };

    const checkErrors = async () => {
        try {
            if (to_folder_id) {
                setDisableSave(false);
                await form.validateFields();
            } else {
                setDisableSave(true);
            }
        } catch (error) {
            setDisableSave(true);
        }
    };

    const handleSave = async () => {
        showLoading();

        try {
            const data = lstItems.map((item) => ({
                item_id: item.id,
                folder_id: folderId,
                notes: form.getFieldValue("notes"),
                reason_id: form.getFieldValue("reason"),
                to_folder_id: form.getFieldValue("to_folder_id"),
                quantity: form.getFieldValue([`quantity_to_move_${item.id}`]),
            }));

            console.log(data);

            const result = await itemService.bulkMoveQuantity(data);

            eventBus.emit("item_changed", { folder_id: folderId });
            success(`Move quantity has been successfully updated`);
        } catch (err) {
            error("Failed to move quantity. Please try again later.");
            console.log(err);
        } finally {
            resetValue();
            dismissLoading();
        }
    };

    const resetValue = () => {
        form.resetFields();
        setLstItems([]);
        setDisableSave(true);
        setHasMoveAll({});
        setNewQuantity({});
        onClose();
    };

    return (
        <Modal
            width={1000}
            title={<p style={{ fontSize: token.fontSizeHeading4, textAlign: "center" }}>Bulk Move Item</p>}
            open={visible}
            onCancel={resetValue}
            maskClosable={false}
            footer={<SaveButton disabled={disableSave} onClick={handleSave} />}
        >
            <Form form={form} layout="vertical">
                <Row gutter={30} style={{ padding: `${token.paddingXS}px 0` }}>
                    <Col span={8}>ITEMS ({lstItems?.length})</Col>
                    <Col span={5}>QUANTITY ON HAND</Col>
                    <Col span={6}>MOVE QUANTITY</Col>
                    <Col span={5}>NEW QUANTITY</Col>
                </Row>

                <Row gutter={30}>
                    <Col span={8}>Bulk Move Item</Col>
                    <Col span={5}></Col>
                    <Col span={6}>
                        <Flex gap={10}>
                            <Form.Item name="multi_quantity_to_move" layout="horizontal" style={{ flex: 1 }}>
                                <InputNumber style={{ width: "100%" }} onChange={handleChangeMultiQuantityMove} />
                            </Form.Item>

                            <CustomButtonLink onClick={handleClickMultiMoveAll}>Move all</CustomButtonLink>
                        </Flex>
                    </Col>
                    <Col span={5}></Col>
                </Row>

                <Divider style={{ marginTop: "0" }} />

                <div style={{ maxHeight: "300px", overflowY: "scroll", overflowX: "hidden", marginRight: "-18px" }}>
                    {lstItems?.map((item) => (
                        <div key={item.id}>
                            <Row gutter={30}>
                                <Col span={8}>
                                    <Flex gap={10} align="center">
                                        {item.photos ? (
                                            <Image style={{ width: "30px", height: "30px", objectFit: "contain", borderRadius: token.borderRadiusLG }} src={item.photos[0].url} />
                                        ) : (
                                            <FileImageOutlined style={{ display: "block", fontSize: "30px", color: token.colorPrimary }} />
                                        )}

                                        <Flex vertical>
                                            <span
                                                style={{
                                                    display: "block",
                                                    whiteSpace: "nowrap",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                }}
                                            >
                                                {item.name}
                                            </span>
                                            <span
                                                style={{
                                                    fontSize: token.fontSizeSM,
                                                }}
                                            >
                                                {item.variant_title}
                                            </span>
                                        </Flex>
                                    </Flex>
                                </Col>
                                <Col span={5}>
                                    <span style={{ display: "block", paddingTop: "5px" }}>{Number.formatInt(getQuantityItem(item, folderId))}</span>
                                </Col>
                                <Col span={6}>
                                    <Flex gap={10}>
                                        <Form.Item
                                            name={`quantity_to_move_${item.id}`}
                                            label=""
                                            style={{ flex: 1 }}
                                            rules={[
                                                { required: true, message: "Quantity can't be blank" },
                                                { type: "number", min: 1, message: "Quantity can't be below 1" },
                                                { type: "number", max: getQuantityItem(item, folderId), message: `Quantity must be less than or equal to ${getQuantityItem(item, folderId)}` },
                                            ]}
                                        >
                                            <InputQuantityMove value={form.getFieldValue(`quantity_to_move_${item.id}`)} onChange={(value) => handleChangeQuantityMove(item, value)} />
                                        </Form.Item>

                                        <CustomButtonLink type="link" onClick={() => handleMoveAll(item)} disabled={!hasMoveAll[item.id]}>
                                            Move all
                                        </CustomButtonLink>
                                    </Flex>
                                </Col>
                                <Col span={5} style={{ paddingRight: "20px" }}>
                                    <span style={{ display: "block", paddingTop: "5px" }}>{newQuantity[item.id] || 0}</span>
                                </Col>
                            </Row>
                        </div>
                    ))}
                </div>

                <Divider style={{ marginBottom: token.marginSM }} />

                <Row gutter={30} style={{ paddingTop: token.marginSM }}>
                    <Col span={10}>
                        <Form.Item name="reason" label="Move reason">
                            <SelectMoveReason />
                        </Form.Item>
                    </Col>
                    <Col span={14}>
                        <Form.Item name="notes" label="Move notes">
                            <Input.TextArea style={{ height: "30px" }} />
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item name="to_folder_id" label="Choose destination folder:">
                    <Picker.Folder style={{ width: "100%" }} placeholder=""></Picker.Folder>
                </Form.Item>

                <Divider style={{ marginTop: "-10px", marginBottom: "24px" }} />
            </Form>
        </Modal>
    );
}

export default MoveMultiQuantityItemsModal;
