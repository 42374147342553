import { Button, Divider, Flex, Input, Switch, Table, Image, Dropdown, Pagination, Badge, Checkbox, Menu, Card, theme, Skeleton, Empty } from "antd";
import * as Icon from "@ant-design/icons";
import { SaveButton } from "components/Buttons";
import { useCallback, useEffect, useState } from "react";
import ItemInventoryService from "services/item/ItemInventory";
import * as Number from "common/number_format";
import { useAppContext } from "context/app";

import ItemService from "services/item";
import _ from "lodash";
import TableInventory from "./TableInventory";
import Filter from "./Filter";
import UserSettingServices from "services/user_setting";
import useToken from "antd/es/theme/useToken";
import TransactionService from "services/transaction";
import InputSearchBarcode from "components/Input/InputSearchBarCode";

const transactionService = new TransactionService();
const userSettingServices = new UserSettingServices();

function TransactionsPage() {
    const { token } = theme.useToken();
    const [items, setItems] = useState([]);
    const [statistic, setStatistic] = useState({});
    const [paging, setPaging] = useState({ page: 1, pageSize: 10 });
    const [groupItem, setGroupItem] = useState(false);
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState({ info: "" });
    const [sorts, setSorts] = useState({});

    const [collapseExpandKey, setCollapseExpandKey] = useState(["FOLDERS"]);
    const [showFilterFolder, setShowFilterFolder] = useState(false);
    const [activeFilters, setActiveFilters] = useState(0);

    const { showExportModal, folderList, customFieldList } = useAppContext();

    const debouncedLoadData = useCallback(
        _.debounce(() => {
            loadData();
        }, 500),
        [paging, groupItem, sorts]
    );

    useEffect(() => {
        setPaging((prev) => ({ ...prev, page: 1 }));
        debouncedLoadData();
    }, [filters]);

    useEffect(() => {
        debouncedLoadData();

        return () => {
            debouncedLoadData.cancel();
        };
    }, [debouncedLoadData]);

    const loadData = async (
        query = {
            info: filters.info,
            items: filters.items?.map((item) => item.value),
            folders: filters.folders,
            quantity: filters.quantity,
            time: filters.date?.time,
            users: filters.users?.map((user) => user.value),
        },
        sort = {
            ...sorts,
        },
        option = { group_item: groupItem, paging: { page: paging.page, page_size: paging.pageSize } }
    ) => {
        setLoading(true);

        try {
            const result = await transactionService.list(query, sort, option);

            setItems(result.items);
            setStatistic(result.statistic);
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            {showFilterFolder && (
                <Filter
                    filters={filters}
                    setFilters={setFilters}
                    setShowFilterFolder={setShowFilterFolder}
                    collapseExpandKey={collapseExpandKey}
                    setCollapseExpandKey={setCollapseExpandKey}
                    activeFilters={activeFilters}
                    setActiveFilters={setActiveFilters}
                />
            )}

            <Flex justify="space-between" align="center">
                <h1>Transactions</h1>

                {/* <div>
                    <Button type="primary" icon={<Icon.UploadOutlined></Icon.UploadOutlined>} onClick={() => showExportModal()}>
                        Export
                    </Button>
                </div> */}
            </Flex>

            <Divider />
            <Card>
                <Flex align="center" justify="space-between">
                    <Flex gap={16} style={{ flexGrow: 1, maxWidth: 700 }}>
                        <InputSearchBarcode filters={filters} setFilters={setFilters} />

                        <Badge count={activeFilters}>
                            <Button icon={<Icon.FilterOutlined />} onClick={() => setShowFilterFolder(true)}>
                                Filter
                            </Button>
                        </Badge>
                    </Flex>
                </Flex>
            </Card>
            <Card>
                <div style={{ marginTop: token.margin }}>
                    <TableInventory loading={loading} items={items} paging={paging} setPaging={setPaging} statistic={statistic} setSorts={setSorts} />
                </div>
            </Card>
        </div>
    );
}

export default TransactionsPage;
