
import { createContext, useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LoginLayout from "components/login-layout"
import AccountService from "services/account";
import AppLoader from "components/app-loader";
const accountService = new AccountService();

const AuthContext = createContext();

function AuthProvider({ children }) {


    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        checkLogin();
    }, [])

    const login = async ({ user, token }) => {
        localStorage.setItem("token", token)
        setUser(user);
        window.location = "/"

    }


    const logout = async () => {
        localStorage.removeItem("token")
        var redirect = `/login`
        setUser(null);
        window.location = redirect;

    }

    const checkLogin = async () => {
        var token = localStorage.getItem("token");

        if (!token) {
            setLoading(false);
            return;
        }
        var user = await accountService.me();
        setUser(user);
        setLoading(false);
    }

    if (loading) {
        return <AppLoader></AppLoader>
    }


    return <AuthContext.Provider value={{ user, setUser, login, logout, checkLogin }}>
        {user ?
            <>
                {children}
            </>
            :
            <>
                <LoginLayout></LoginLayout>
            </>
        }

    </AuthContext.Provider>;
}

const useAuth = () => {
    const context = useContext(AuthContext);
    return context;
};

export { useAuth };
export default AuthProvider;
