import { Button, Card, Divider, Flex, Form, Image, Input, Space, theme } from "antd";
import { useForm, useWatch } from "antd/es/form/Form";
import { EyeTwoTone, EyeInvisibleOutlined, GoogleOutlined } from "@ant-design/icons";
import { useLoading } from "context/loading";
import { useNavigate } from "react-router-dom";
import { useNotification } from "context/notification";
import AuthService from "services/auth";
import { useAuth } from "context/auth";
import { useState } from "react";
import IconGoogle from "../../assets/images/icon_google.png";
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';

const authService = new AuthService();

function Login() {
    const { token } = theme.useToken();
    const [form] = useForm();
    const { success, error } = useNotification();
    const { showLoading, dismissLoading } = useLoading();
    const [loginError, setLoginError] = useState(null);
    const navigate = useNavigate();
    const { login } = useAuth();

    const email = useWatch("email", { form });

    const handleContinue = () => {
        form.submit();
    };

    const onFinish = async () => {
        setLoginError(null);
        showLoading("Login");

        const data = { ...form.getFieldsValue() };
        const user = await authService.userLogin(data);
        if (user) {
            login(user);
        } else {
            setLoginError({ message: "Incorrect username or password" });
            form.resetFields();
        }
        dismissLoading();
    };

    const googleLogin = useGoogleLogin({
        onSuccess: async codeResponse => {
            showLoading("Login");
            try {

                var user = await authService.loginWithGoogle(codeResponse.access_token);
                if (user) {
                    login(user);
                }

            } catch (error) {

                navigate(`/signupwithgoogle/${codeResponse.access_token}`)
                //  /signupwithgoogle/:access_token
                console.log("error", error);
            }

            dismissLoading();
        },
        flow: 'implicit',
    });


    return (
        <Card style={{ width: "500px", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
            <Flex vertical gap={14}>
                <Space direction="vertical" align="center">
                    <img width={200} style={{ borderRadius: "8px" }} src="https://storage.googleapis.com/cubebio/data/4aa8c176-4c15-4a94-b6bf-83ac71462f10.png" />
                    <span style={{ fontSize: token.fontSizeHeading3, fontWeight: "500" }}>Welcome back!</span>
                    <span>Log in to your account</span>
                </Space>
                <Form layout="vertical" form={form} initialValues={{}} onFinish={onFinish}>
                    <Form.Item
                        label="Email"
                        name="email"
                        validateTrigger="onBlur"
                        rules={[
                            {
                                required: true,
                            },
                            {
                                type: "email",
                                message: `${email} is not a valid email`,
                            },
                        ]}
                    >
                        <Input placeholder="Email"></Input>
                    </Form.Item>

                    <Form.Item
                        label="Password"
                        name="pass"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input.Password placeholder="Password" iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
                    </Form.Item>

                    <Space direction="vertical" style={{ width: "100%" }} size={"large"}>
                        {loginError && (
                            <div style={{ float: "right" }}>
                                <span style={{ color: token.colorErrorText }}>{loginError.message}</span>
                            </div>
                        )}

                        <div style={{ float: "right" }}>
                            <Button size="small" type="link" onClick={() => navigate("/reset-password")}>
                                Forgot password?
                            </Button>
                        </div>

                        <Button type="primary" style={{ width: "100%" }} onClick={() => handleContinue()}>
                            Login
                        </Button>

                        <Divider orientation="center" style={{ opacity: "0.5" }}>
                            OR
                        </Divider>



                        <Button type="primary" style={{ width: "100%" }} onClick={googleLogin} ghost>
                            <img width="26" height="26" src="https://img.icons8.com/color/48/google-logo.png" alt="google-logo" />  Continue With Google
                        </Button>

                        <Button type="primary" style={{ width: "100%" }} onClick={e=>navigate("/workspacelogin")} ghost>
                            Continue With Workspace Account
                        </Button>

                        {/* <GoogleLogin
                            onSuccess={credentialResponse => {
                                console.log("login ok " , credentialResponse);
                            }}
                            onError={() => {
                                console.log('Login Failed');
                            }}
                            style={{width:"100%"}}
                          
                        /> */}

                        <Flex align="center" justify="center">
                            <span>New here?</span>
                            <Button type="link" onClick={() => navigate("/register")}>
                                Create an account
                            </Button>
                        </Flex>
                    </Space>
                </Form>
            </Flex>
        </Card>
    );
}

export default Login;
