import React, { useState, memo, useEffect, useMemo } from "react";
import { Button, Flex, Input, Tree, theme } from "antd";
import FolderService from "services/folder";
import * as Icon from "@ant-design/icons";
import _ from "lodash";
import { useItemsScreenContext } from "pages/items";
import { useAppContext } from "context/app";
import { useLoading } from "context/loading";
import "./FolderTree.css";
const folderService = new FolderService();

const { useToken } = theme;

const FolderPicker = ({ value, onChange, onPathChange }) => {
    const [expandedKeys, setExpandedKeys] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [showSortIndexFolder, setShowSortIndexFolder] = useState(false);

    const { token } = useToken();
    const folders = useAppContext().folderList;
    const { currentFolder, setCurrentFolder, currentFolderPath, setCurrentFolderPath } = useItemsScreenContext();
    const { showLoading, dismissLoading } = useLoading();

    useEffect(() => {
        loadTreeData();
    }, [folders]);

    useEffect(() => {
        var tree = toTreeData([...folders]);
        const parentKeys = findParentKeys(tree, currentFolder);
        setExpandedKeys([...parentKeys, currentFolder]);
        setSelectedKeys([currentFolder]);
    }, [folders, currentFolder]);

    const findParentKeys = (nodes, targetKey, parents = []) => {
        for (let node of nodes) {
            if (node.key === targetKey) {
                return parents;
            }
            if (node.children) {
                const foundParents = findParentKeys(node.children, targetKey, [...parents, node.key]);
                if (foundParents.length > 0) {
                    return foundParents;
                }
            }
        }
        return [];
    };

    const loadTreeData = async () => {
        toTreeData([...folders]);
    };

    const toTreeData = (lst) => {
        if (lst.length == 0) {
            return [];
        }

        var root = lst.find((x) => x.is_root);
        var arrarLoop = [];
        var tree = { value: root.id, title: root.name, children: [], id: root.id, key: root.id, isRoot: true, isLeaf: true };
        arrarLoop.push(tree);

        while (arrarLoop.length > 0) {
            var node = arrarLoop.pop();

            var children = lst
                .filter((x) => x.parent_id === node.id)
                .map((x) => ({ value: x.id, title: x.name, id: x.id, children: [], key: x.id, sort_index: x.sort_index || "-1" }))
                .sort((a, b) => {
                    const aIndex = parseInt(a.sort_index, 10);
                    const bIndex = parseInt(b.sort_index, 10);
                    return aIndex - bIndex;
                });

            node.children = children;
            node.children.forEach((childNode) => {
                arrarLoop.push(childNode);
            });
        }
        // tree.id='root'

        console.log({ tree });
        return [tree];
    };

    const toQuickAccessTree = (lst) => {
        var root = lst.find((x) => x.is_root);
        var arrarLoop = [];
        var tree = { value: root.id, title: "Quick Access", children: [], id: root.id, key: root.id };
        arrarLoop.push(tree);
        while (arrarLoop.length > 0) {
            var node = arrarLoop.pop();

            var children = lst.filter((x) => x.parent_id === node.id).map((x) => ({ value: x.id, title: x.name, id: x.id, children: [], key: x.id }));

            node.children = children;
            node.children.forEach((childNode) => {
                arrarLoop.push(childNode);
            });
        }

        return [tree];
    };

    const onSelect = (key) => {
        if (key && key.length > 0) {
            setCurrentFolder(key[0]);
        }
    };

    const onExpand = (keys) => {
        setExpandedKeys(keys);
    };

    const getParentKey = (key, tree) => {
        let parentKey;
        for (let i = 0; i < tree.length; i++) {
            const node = tree[i];
            if (node.children) {
                if (node.children.some((item) => item.key === key)) {
                    parentKey = node.key;
                } else if (getParentKey(key, node.children)) {
                    parentKey = getParentKey(key, node.children);
                }
            }
        }
        return parentKey;
    };

    const handleSearch = (e) => {
        const { value } = e.target;
        const newExpandedKeys = folders
            .map((item) => {
                if (item.name && item.name.toLowerCase().indexOf(value.toLowerCase()) > -1) {
                    return findParentKeys(toTreeData(folders), item.id);
                }
                return [];
            })
            .flat()
            .filter((key, index, self) => self.indexOf(key) === index);

        setExpandedKeys(newExpandedKeys);
        setSearchValue(value);
    };

    const treeData = useMemo(() => {
        if (searchValue === "") {
            var tree = toTreeData([...folders]);
            const parentKeys = findParentKeys(tree, currentFolder);
            setExpandedKeys([...parentKeys, currentFolder]);
            setSelectedKeys([currentFolder]);
            return tree;
        }

        const normalizeString = (str) => str.toLowerCase();
        const loop = (data) =>
            data.map((item) => {
                const strTitle = item.title;
                const normalizedTitle = normalizeString(strTitle);
                const normalizedSearchValue = normalizeString(searchValue);

                const index = normalizedTitle.indexOf(normalizedSearchValue);
                const beforeStr = strTitle.substring(0, index);
                const highlightedStr = strTitle.substring(index, index + searchValue.length);
                const afterStr = strTitle.slice(index + searchValue.length);
                const title =
                    index > -1 ? (
                        <span key={item.key}>
                            {beforeStr}
                            <span style={{ color: token.colorPrimary }}>{highlightedStr}</span>
                            {afterStr}
                        </span>
                    ) : (
                        <span key={item.key}>{strTitle}</span>
                    );

                if (item.children) {
                    return {
                        id: item.id,
                        value: item.id,
                        title,
                        key: item.key,
                        children: loop(item.children),
                    };
                }

                return {
                    title,
                    key: item.key,
                    id: item.id,
                    value: item.id,
                };
            });

        return loop(toTreeData(folders));
    }, [searchValue, folders]);

    const onDrop = async (info) => {
        const dragNodeKey = info.dragNode.key; // Key thư mục đang được kéo thả
        const dropNodeKey = info.node.key; // Key thư mục trên chỗ thư mục đã được thả vào

        const treeData = toTreeData([...folders]);

        // Hàm lấy danh sách id của các thư mục cùng cấp
        const getSiblings = (key, data) => {
            let siblings = [];
            const findSiblings = (nodes) => {
                for (let node of nodes) {
                    if (node.children) {
                        if (node.children.some((child) => child.key === key)) {
                            siblings = node.children.map((child) => child.key);
                            return;
                        }
                        findSiblings(node.children);
                    }
                }
            };
            findSiblings(data);
            return siblings;
        };

        // Cập nhật danh sách sort index cần thay đổi
        const updateSortIndex = async (siblings, originalSiblings) => {
            const updatedSortIndexFolders = siblings.map((id, index) => ({ id, sort_index: index })).filter((item, index) => originalSiblings[index] !== siblings[index]);

            if (updatedSortIndexFolders.length > 0) {
                try {
                    showLoading();
                    await folderService.bulkUpdateSortIndex(updatedSortIndexFolders);
                    setCurrentFolder(dragNodeKey);
                } catch (err) {
                    console.error(err);
                } finally {
                    dismissLoading();
                }
            }
        };

        // Xác định các thư mục cùng cấp của thư mục thả
        const siblings = getSiblings(dropNodeKey, treeData);
        const siblingsOriginal = [...siblings];

        // Kiểm tra nếu thư mục kéo và thư mục thả đều là các thư mục cùng cấp
        if (siblings.includes(dragNodeKey)) {
            const dragIndex = siblings.indexOf(dragNodeKey);
            const dropIndex = siblings.indexOf(dropNodeKey);

            siblings.splice(dragIndex, 1);
            const newIndex = dragIndex < dropIndex ? dropIndex : dropIndex + 1;
            siblings.splice(newIndex, 0, dragNodeKey);

            await updateSortIndex(siblings, siblingsOriginal);
        } else if (!info.dropToGap) {
            const lstChildrenDrop = info.node.children;
            const checkDragNodeExists = lstChildrenDrop.some((node) => node.key === dragNodeKey);

            if (checkDragNodeExists) {
                const siblings = getSiblings(dragNodeKey, treeData);
                const siblingsOriginal = [...siblings];

                siblings.splice(siblings.indexOf(dragNodeKey), 1);
                siblings.unshift(dragNodeKey);

                await updateSortIndex(siblings, siblingsOriginal);
            }
        }
    };

    return (
        <div className="custom">
            <div>
                <Input style={{ margin: "10px 0 12px" }} placeholder="Search" addonBefore={<Icon.SearchOutlined />} onChange={handleSearch} />
            </div>
            <div style={{ backgroundColor: token.colorBgContainer }}>
                <Tree
                    style={{
                        width: "100%",
                    }}
                    value={value}
                    height={880}
                    suffixIcon={<Icon.DownOutlined size={64} />}
                    treeData={treeData}
                    placeholder="Please select"
                    showLine={true}
                    defaultExpandAll={true}
                    expandedKeys={expandedKeys}
                    selectedKeys={selectedKeys}
                    onExpand={onExpand}
                    onSelect={onSelect}
                    draggable={showSortIndexFolder}
                    blockNode
                    onDrop={onDrop}
                    titleRender={(node) => (
                        <Flex justify="space-between">
                            {node.title}
                            {node.isRoot ? (
                                <Button
                                    size="small"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setShowSortIndexFolder((prev) => !prev);
                                    }}
                                >
                                    {showSortIndexFolder ? <Icon.CloseOutlined /> : <Icon.HolderOutlined />}
                                </Button>
                            ) : (
                                showSortIndexFolder && <Icon.HolderOutlined style={{ marginRight: "8px" }} />
                            )}
                        </Flex>
                    )}
                />
            </div>
        </div>
    );
};
export default memo(FolderPicker);
