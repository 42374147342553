import { Flex, theme, Typography, List, Divider, Card, Button, notification } from "antd";
import { useEffect, useState } from "react";
import NotificationService from "services/notification";
import * as Date from "../../common/date_format";
import { useAuth } from "context/auth";
import { useNavigate } from "react-router-dom";
import { useLoading } from "context/loading";
import { useAppContext } from "context/app";

const notificationService = new NotificationService();

function Notification() {
    const { token } = theme.useToken();
    const navigate = useNavigate();
    const [lstNotification, setLstNotification] = useState([]);
    const { user } = useAuth();
    const { showLoading, dismissLoading } = useLoading();
    const { setCountUnRead } = useAppContext();

    useEffect(() => {
        fetchLstNotification();
    }, []);

    const fetchLstNotification = async () => {
        const result = await notificationService.list();
        setLstNotification(result);
    };

    const getRead = (notification) => {
        const findUser = notification.users.find((x) => x.user_id === user.id);
        return findUser.read;
    };

    const handleRead = async (notification) => {
        showLoading();
        await notificationService.read(notification.id);
        dismissLoading();

        loadCountUnread();
        fetchLstNotification();
    };

    const handleReadAll = async () => {
        showLoading();
        await notificationService.readAll();
        dismissLoading();

        loadCountUnread();
        fetchLstNotification();
    };

    const loadCountUnread = async () => {
        const notificationService = new NotificationService();
        const result = await notificationService.countUnread();
        setCountUnRead(result);
    };

    return (
        <div>
            <Flex justify="space-between" align="center">
                <h1>Notifications</h1>

                <Button type="link" onClick={handleReadAll}>
                    Read All
                </Button>
            </Flex>

            <Divider />

            <Flex vertical gap={12}>
                {lstNotification.map((notification) => (
                    <Flex
                        justify="space-between"
                        style={{
                            backgroundColor: token.colorBorderBg,
                            //color: !getRead(notification) && token.colorPrimary,
                            padding: token.padding,
                            borderRadius: token.borderRadiusLG,
                            cursor: "pointer",
                            fontWeight: !getRead(notification) && "700",
                        }}
                        onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = token.colorPrimaryBgHover)}
                        onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = token.colorBorderBg)}
                        onClick={() => {
                            if (!getRead(notification)) {
                                handleRead(notification);
                            }
                            navigate(`/notification-detail/${notification.id}`, { state: { data: notification } });
                        }}
                    >
                        <span>{notification.message}</span>
                        <span>{Date.formatDateTime(notification.update_on)}</span>
                    </Flex>
                ))}
            </Flex>
        </div>
    );
}

export default Notification;
