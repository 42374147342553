import { useEffect, memo, useState } from "react";
import { Button, Flex, Space, Divider, Breadcrumb, Image, Dropdown, Table, theme, Card, Popconfirm } from "antd";
import * as Icon from "@ant-design/icons";
import { BrowserRouter, json, useLocation, useNavigate, useNavigation, useParams } from "react-router-dom";
import ItemService from "services/item";
import ItemEditor from "components/item/Editor";
import { useLoading } from "context/loading";
import { getQuantityItem } from "common/get_quantity_item";
import { getStockItem } from "common/get_stock_item";
import { getAlertItem } from "common/get_alert_item";
import { useAppContext } from "context/app";
import * as Number from "common/number_format";
import * as Date from "common/date_format";
import FolderService from "services/folder";
import ListFolders from "./ListFolders";
import Tags from "components/tag-list";
import RenderCustomField from "./RenderCustomField";
import { CustomButtonLink } from "components/Buttons";

const itemService = new ItemService();

const ItemDetailPage = () => {
    const { token } = theme.useToken();
    const navigate = useNavigate();
    const [item, setItem] = useState({});
    const [parentItem, setParentItem] = useState(null);
    const [folders, setFolders] = useState({});
    const [lstVariants, setLstVariants] = useState([]);
    const [lstAttributes, setLstAttributes] = useState([]);
    const [photoShow, setPhotoShow] = useState({});
    const [previewVisible, setPreviewVisible] = useState(false);
    const [lstCustomFields, setLstCustomFields] = useState([]);

    const { tagList, customFieldList, folderList, attributeList } = useAppContext();

    const { id } = useParams();

    const loading = useLoading();

    useEffect(() => {
        if (id && tagList && customFieldList && attributeList) {
            loadItem();
        }
    }, [id, tagList, customFieldList, attributeList]);

    const loadItem = async () => {
        loading.showLoading();
        setParentItem(null);
        var data = await itemService.getByID(id);
        setItem(data);

        if (data.parent_id) {
            itemService.getByID(data.parent_id).then((parent) => {
                setParentItem(parent);
            });
        }
        setPhotoShow(data.photos ? data.photos[0] : null);

        // Lấy tất cả folder có chứa item detail
        const lstFolder = data.stock.map((stock) => {
            return folderList.find((folder) => folder.id === stock.folder_id);
        });
        const filteredFolders = lstFolder.filter((folder) => folder !== null && folder !== undefined);
        setFolders(filteredFolders);

        // Xử lý nếu là group item
        if (data.has_variants) {
            const lstIds = [...new Set(data.stock.flatMap((stock) => stock.variant_stock?.map((variantStock) => variantStock.item_id)))];
            const lstItemById = await itemService.getByIDs(lstIds);

            const lstVariants = data.stock.flatMap((stock) =>
                stock.variant_stock?.map((variantStock) => {
                    const getItem = lstItemById.find((item) => item.id === variantStock.item_id);
                    return {
                        ...getItem,
                        folderId: stock.folder_id,
                    };
                })
            );

            setLstVariants(lstVariants);

            if (attributeList.length > 0) {
                const attributes = data.variant.attributes.map((attr) => {
                    const findAttribute = attributeList.find((x) => x.id === attr.id);
                    return {
                        id: attr.id,
                        name: findAttribute.name,
                        options: attr.options,
                    };
                });

                setLstAttributes(attributes);
            }
        }

        if (customFieldList.length > 0 && data.custom_field) {
            const customField = Object.keys(data.custom_field)
                .map((id) => {
                    const result = customFieldList?.find((customField) => customField.id === id);
                    return {
                        ...result,
                        value: data.custom_field[id],
                    };
                })
                .filter((field) => field !== undefined && field !== null);

            setLstCustomFields(customField);
        }

        loading.dismissLoading();
    };

    const handleChangePhoto = (direction) => {
        let index;
        if (direction === "back") {
            index = item.photos.findIndex((photo) => photo.id === photoShow.id) - 1;
            if (index === -1) {
                index = item.photos.length - 1;
            }
        } else if (direction === "next") {
            index = item.photos.findIndex((photo) => photo.id === photoShow.id) + 1;
            if (index === item.photos.length) {
                index = 0;
            }
        }

        setPhotoShow(item.photos[index]);
    };

    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            width: "30%",
            render: (text, record) => {
                const img = record.photos && record.photos.length > 0 ? <Image width={30} src={record.photos[0].url} /> : <Icon.FileImageOutlined style={{ fontSize: "30px" }} />;

                const result = (
                    <Flex gap={10} align="center">
                        {img}
                        <Flex vertical style={{ cursor: "pointer" }} onClick={() => navigate(`/item-detail/${record.id}`)}>
                            <span>{record.name}</span>
                            <span>{record.variant_title}</span>
                        </Flex>
                    </Flex>
                );

                return result;
            },
        },
        {
            title: "Quantity",
            dataIndex: "quantity",
            key: "quantity",
            width: "10%",
            align: "right",
            render: (text, record) => <div>{Number.formatInt(getQuantityItem(record, record.folderId))}</div>,
        },
        {
            title: "Price",
            dataIndex: "price",
            key: "price",
            width: "10%",
            align: "right",
            render: (text, record) => <div>{Number.format(record.price)}</div>,
        },
        {
            title: "Tag",
            dataIndex: "quantity",
            key: "quantity",
            width: "25%",
            render: (text, record) => <Tags tag_ids={record.tags}></Tags>,
        },
        {
            title: "Notes",
            dataIndex: "notes",
            key: "notes",
            width: "25%",
            render: (text, record) => <span style={{ display: "-webkit-box", WebkitBoxOrient: "vertical", WebkitLineClamp: 2, overflow: "hidden", overflowWrap: "anywhere" }}>{text}</span>,
        },
    ];

    const getTotalQuantityVariants = () => {
        let totalQuantity = 0;
        lstVariants.forEach((variant) => {
            totalQuantity += getQuantityItem(variant, variant?.folderId);
        });

        return totalQuantity;
    };

    const confirm = async (id) => {
        await itemService.deleteItem({ id });
        navigate("/folder");
    };

    return (
        <Flex vertical >
            {item && (
                <>
                    <div style={{ position: "sticky", top: 0, padding: token.padding, backgroundColor: token.colorBorderBg, zIndex: 5, borderRadius: token.borderRadiusLG }}>
                        <Flex justify="space-between" align="center" style={{ padding: `${token.padding}px 0`, borderRadius: token.borderRadiusLG }}>
                            <Flex vertical>
                                <span style={{ fontSize: token.fontSizeHeading4, fontWeight: 500 }}>{item.name}</span>
                                <span>{item.variant_title}</span>
                            </Flex>

                            <Flex gap={16}>
                                <Popconfirm title="Delete" description="Are you sure to delete this item ?" onConfirm={() => confirm(item.id)} okText="Yes" cancelText="No">
                                    <Button icon={<Icon.DeleteOutlined />}>Delete</Button>
                                </Popconfirm>

                                <Button
                                    type="primary"
                                    icon={<Icon.EditOutlined></Icon.EditOutlined>}
                                    onClick={() => {
                                        navigate(`/item-edit/${item.id}`);
                                    }}
                                >
                                    Edit
                                </Button>
                            </Flex>
                        </Flex>
                        <Divider style={{ margin: 0 }}></Divider>
                    </div>

                    <Flex
                        gap={20}
                        vertical
                        style={{ backgroundColor: token.colorBorderBg, padding: `${token.fontSizeSM}px ${token.padding}px`, borderRadius: token.borderRadiusLG, marginTop: token.margin }}
                    >
                        <Flex align="center" vertical gap={30}>
                            {/* <span style={{ fontSize: "22px", fontWeight: 500 }}>Product Information</span> */}

                            {item.photos && photoShow ? (
                                <>
                                    <img
                                        height={200}
                                        src={photoShow.url}
                                        style={{
                                            borderRadius: "8px",
                                            objectFit: "contain",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => setPreviewVisible(true)}
                                    />

                                    <Flex gap={14} justifyContent="center">
                                        <Button icon={<Icon.LeftOutlined />} onClick={() => handleChangePhoto("back")} disabled={item.photos?.findIndex((photo) => photo.id === photoShow.id) === 0} />
                                        {item.photos?.map((photo) => (
                                            <img
                                                key={photo.id}
                                                style={{
                                                    borderRadius: token.borderRadiusLG,
                                                    objectFit: "cover",
                                                    cursor: "pointer",
                                                    opacity: photo.id === photoShow.id ? 1 : 0.5,
                                                }}
                                                width={60}
                                                height={30}
                                                src={photo.url}
                                                onClick={() => setPhotoShow(photo)}
                                            />
                                        ))}
                                        <Button
                                            icon={<Icon.RightOutlined />}
                                            onClick={() => handleChangePhoto("next")}
                                            disabled={item.photos?.findIndex((photo) => photo.id === photoShow.id) === item.photos.length - 1}
                                        />
                                    </Flex>

                                    {previewVisible && (
                                        <Image.PreviewGroup
                                            preview={{
                                                current: item.photos.findIndex((photo) => photo.id === photoShow.id),
                                                onChange: (current) => setPhotoShow(item.photos[current]),
                                                visible: previewVisible,
                                                onVisibleChange: (visible) => setPreviewVisible(visible),
                                            }}
                                        >
                                            {item.photos?.map((photo) => (
                                                <Image key={photo.id} src={photo.url} style={{ display: "none" }} />
                                            ))}
                                        </Image.PreviewGroup>
                                    )}
                                </>
                            ) : (
                                <>
                                    <Icon.FileImageOutlined style={{ fontSize: 48, color: token.colorPrimary }} />
                                    No photo
                                </>
                            )}
                        </Flex>
                        {parentItem && (
                            <ItemInfomation title="Item Group">
                                <a style={{ color: token.colorPrimary }} href={`/item-detail/${parentItem.id}`}>
                                    {parentItem.name}
                                </a>
                            </ItemInfomation>
                        )}

                        <ItemInfomation title="Price">{item.price}</ItemInfomation>
                        <ItemInfomation title="Tags">
                            <Tags tag_ids={item.tags}></Tags>
                        </ItemInfomation>
                        <ItemInfomation title="Notes" value={item.notes}>
                            {item.notes}
                        </ItemInfomation>
                        <ItemInfomation title="Qr/Bar Code" value={item.notes}>
                            {item.code}
                        </ItemInfomation>

                        <Flex gap={6} vertical style={{ padding: "12px 0" }}>
                            {lstCustomFields.length > 0 &&
                                lstCustomFields.map((customField) => (
                                    <ItemInfomation title={customField.name}>
                                        <RenderCustomField data={customField}></RenderCustomField>
                                    </ItemInfomation>
                                ))}
                        </Flex>
                    </Flex>

                    <div style={{ backgroundColor: token.colorBorderBg, padding: `${token.fontSizeSM}px ${token.padding}px`, borderRadius: token.borderRadiusLG, marginTop: token.margin }}>
                        <div style={{ marginBottom: token.margin }}>
                            <span style={{ fontSize: "16px", fontWeight: 500 }}>Stocks</span>
                        </div>
                        {folders.length > 0 && <ListFolders item={item} lstFolder={folders} />}
                    </div>

                    {item.has_variants && (
                        <>
                            {lstAttributes.length > 0 && (
                                <Flex
                                    gap={20}
                                    vertical
                                    style={{ backgroundColor: token.colorBorderBg, padding: `${token.fontSizeSM}px ${token.padding}px`, borderRadius: token.borderRadiusLG, marginTop: token.margin }}
                                >
                                    {lstVariants.length > 0 && (
                                        <Flex gap={30}>
                                            <span style={{ fontSize: "16px", fontWeight: 500 }}>Variants: {lstVariants.length}</span>
                                            <span style={{ fontSize: "16px", fontWeight: 500 }}>Total Quantity: {getTotalQuantityVariants()}</span>
                                        </Flex>
                                    )}

                                    <span style={{ fontSize: "16px", fontWeight: 500 }}>Variant details</span>
                                    {lstAttributes.map((attr) => (
                                        <span key={attr.id}>
                                            <span style={{ fontWeight: 500 }}>{attr.name}: </span>
                                            {attr.options.join(", ")}
                                        </span>
                                    ))}
                                </Flex>
                            )}

                            <Card style={{ marginTop: token.margin }}>
                                <Table dataSource={lstVariants} columns={columns}></Table>
                            </Card>
                        </>
                    )}
                </>
            )}
        </Flex>
    );
};

const ItemInfomation = memo(({ title, children }) => {
    const { token } = theme.useToken();
    return (
        <div>
            <div style={{ marginTop: token.margin, display: "flex" }}>
                <div>
                    <span style={{ fontWeight: 500 }}>{title}:</span>
                </div>

                <div style={{ marginLeft: token.margin }}>{children}</div>
            </div>
            <Divider style={{ margin: 0, marginTop: token.margin }}></Divider>
        </div>
    );
});

export default memo(ItemDetailPage);
