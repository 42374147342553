import { Button, Divider, Flex, Input, Switch, Table, Image, Dropdown, Pagination, Badge, Checkbox, Menu, Card, theme, Skeleton, Empty } from "antd";
import * as Icon from "@ant-design/icons";
import { SaveButton } from "components/Buttons";
import { useCallback, useEffect, useState } from "react";
import ItemInventoryService from "services/item/ItemInventory";
import * as Number from "common/number_format";
import { useAppContext } from "context/app";

import ItemService from "services/item";
import _ from "lodash";
import TableInventory from "./TableInventory";
import Filter from "./Filter";
import UserSettingServices from "services/user_setting";
import useToken from "antd/es/theme/useToken";
import InputSearchBarcode from "components/Input/InputSearchBarCode";

const itemInventoryService = new ItemInventoryService();
const userSettingServices = new UserSettingServices();

function InventorySummaryPage() {
    const { token } = theme.useToken();
    const [items, setItems] = useState([]);
    const [statistic, setStatistic] = useState({});
    const [paging, setPaging] = useState({ page: 1, pageSize: 10 });
    const [groupItem, setGroupItem] = useState(false);
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState({ info: "" });
    const [sorts, setSorts] = useState({});

    const [collapseExpandKey, setCollapseExpandKey] = useState(["FOLDERS"]);
    const [showFilterFolder, setShowFilterFolder] = useState(false);
    const [activeFilters, setActiveFilters] = useState(0);

    const { showExportModal, folderList, customFieldList } = useAppContext();

    const debouncedLoadData = useCallback(
        _.debounce(() => {
            loadData();
        }, 500),
        [paging, groupItem, sorts]
    );

    useEffect(() => {
        setPaging((prev) => ({ ...prev, page: 1 }));
        debouncedLoadData();
    }, [filters]);

    useEffect(() => {
        debouncedLoadData();

        return () => {
            debouncedLoadData.cancel();
        };
    }, [debouncedLoadData]);

    const loadData = async (
        query = {
            info: filters.info,
            folders: filters.folders,
            quantity: filters.quantity,
            lowstock_level: filters.lowstock_level,
            price: filters.price,
            lowstock_alert: filters.lowstock_alert,
            lowstock: filters.lowstock,
            outof_stock: filters.outof_stock,
            tags: filters.tags,
        },
        sort = {
            ...sorts,
        },
        option = { group_item: groupItem, paging: { page: paging.page, page_size: paging.pageSize } }
    ) => {
        setLoading(true);

        try {
            const result = await itemInventoryService.list(query, sort, option);

            if (groupItem) {
                const groupedItems = await handleGroupItems(result.items);

                setItems(groupedItems);
            } else {
                setItems(result.items);
            }
            setStatistic(result.statistic);
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    const handleGroupItems = async (items) => {
        items.forEach((item, index) => {
            if (item.stock.variant_stock && item.stock.variant_stock.length > 0) {
                item.children = [];
                item.key = index;
            }
        });
        return items;
    };

    const defaultColumns = ["name", "folder", "price", "quantity", "total_amount"];
    const [visibleColumns, setVisibleColumns] = useState(defaultColumns);

    useEffect(() => {
        const getTableInventorySetting = async () => {
            try {
                const getSetting = await userSettingServices.get({});
                const screenSetting = getSetting["table_inventory_setting"];

                if (screenSetting && Array.isArray(screenSetting)) {
                    setVisibleColumns([...defaultColumns, ...screenSetting]);
                } else {
                    setVisibleColumns(defaultColumns);
                }
            } catch (error) {
                console.error("Failed to load user settings:", error);
            }
        };

        getTableInventorySetting();
    }, []);

    const setTableInventorySetting = async (value) => {
        try {
            await userSettingServices.save({ key: "table_inventory_setting", value });
        } catch (error) {
            console.error("Failed to save user settings:", error);
        }
    };

    const handleColumnToggle = (key) => {
        setVisibleColumns((prev) => {
            let value;
            if (prev.includes(key)) {
                value = prev.filter((col) => col !== key);
            } else {
                value = [...prev, key];
            }

            setTableInventorySetting(value);

            return value;
        });
    };

    const columnMenu = [
        { key: "tags", title: "Tags" },
        { key: "notes", title: "Notes" },
        { key: "quantity_alert", title: "Lowstock Level" },
        ...customFieldList.map((field) => ({
            key: `CustomField_${field.id}`,
            title: field.name,
        })),
    ].map((column) => ({
        key: column.key,
        label: (
            <Flex
                gap={10}
                onClick={(e) => {
                    e.stopPropagation();
                    handleColumnToggle(column.key);
                }}
            >
                <Checkbox checked={visibleColumns.includes(column.key)} />
                <span>{column.title}</span>
            </Flex>
        ),
    }));

    return (
        <div>
            {showFilterFolder && (
                <Filter
                    filters={filters}
                    setFilters={setFilters}
                    setShowFilterFolder={setShowFilterFolder}
                    collapseExpandKey={collapseExpandKey}
                    setCollapseExpandKey={setCollapseExpandKey}
                    activeFilters={activeFilters}
                    setActiveFilters={setActiveFilters}
                />
            )}

            <Flex justify="space-between" align="center">
                <h1>Inventory Summary</h1>

                <div>
                    <Button type="primary" icon={<Icon.UploadOutlined></Icon.UploadOutlined>} onClick={() => showExportModal()}>
                        Export
                    </Button>
                </div>
            </Flex>

            <Divider />
            <Card>
                <Flex align="center" justify="space-between">
                    <Flex gap={16} style={{ flexGrow: 1, maxWidth: 700 }}>
                        <InputSearchBarcode filters={filters} setFilters={setFilters} />

                        <Badge count={activeFilters}>
                            <Button icon={<Icon.FilterOutlined />} onClick={() => setShowFilterFolder(true)}>
                                Filter
                            </Button>
                        </Badge>

                        <Dropdown
                            menu={{
                                items: columnMenu,
                            }}
                            trigger={["click"]}
                        >
                            <Button icon={<Icon.FontColorsOutlined />}> Columns</Button>
                        </Dropdown>
                    </Flex>

                    <Flex gap={10}>
                        <span>Group Items</span>
                        <Switch onChange={(e) => setGroupItem(e)} />
                    </Flex>
                </Flex>

                <Flex gap={50} style={{ marginTop: token.margin }}>
                    {loading ? (
                        <Flex vertical gap={12} style={{ width: "100%" }}>
                            <Skeleton.Input block={true} active size="small" />
                            <Skeleton.Input block={true} active size="small" />
                        </Flex>
                    ) : (
                        statistic &&
                        Object.keys(statistic).length > 0 && (
                            <>
                                <Flex vertical>
                                    <span style={{ fontSize: token.fontSizeHeading5 }}>Total Quantity</span>
                                    <span style={{ fontSize: token.fontSizeHeading3 }}>{Number.formatInt(statistic.total_quantity)}</span>
                                </Flex>

                                <Flex vertical>
                                    <span style={{ fontSize: token.fontSizeHeading5 }}>Total Value</span>
                                    <span style={{ fontSize: token.fontSizeHeading3 }}>{Number.format(statistic.total_amount)}</span>
                                </Flex>
                            </>
                        )
                    )}
                </Flex>
            </Card>
            <Card style={{ marginTop: token.margin }}>
                <div>
                    <TableInventory visibleColumns={visibleColumns} loading={loading} items={items} paging={paging} setPaging={setPaging} statistic={statistic} setSorts={setSorts} />
                </div>
            </Card>
        </div>
    );
}

export default InventorySummaryPage;
