import { Button, Card, Checkbox, Divider, Flex, Form, Image, Input, Space, theme } from "antd";
import { useForm, useWatch } from "antd/es/form/Form";
import { EyeTwoTone, EyeInvisibleOutlined, GoogleOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { useLoading } from "context/loading";
import { json, useNavigate } from "react-router-dom";
import SignUpService from "services/signup";
import OTPService from "services/otp";
import { useEffect, useState } from "react";
import { useNotification } from "context/notification";
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';

const signUpService = new SignUpService();
const otpService = new OTPService();

function Register() {
    const { token } = theme.useToken();
    const [form] = useForm();
    const { success, error } = useNotification();
    const { showLoading, dismissLoading } = useLoading();
    const navigate = useNavigate();
    const [idOtp, setIdOtp] = useState();

    const email = useWatch("email", { form });
    const agree = useWatch("agree", { form })

    const handleCreate = () => {
        form.submit();
    };

    // type = 'signUp' or 'otp'
    const onFinish = async (type) => {
        showLoading("Saving");
        const data = { ...form.getFieldsValue() };
        console.log({ data });

        try {
            if (type === "signUp") {
                const result = await signUpService.emailSignUp(data);
                if (result) {
                    setIdOtp(result.otp.id);
                }
            } else if (type === "otp") {
                const result = await otpService.verify({ id: idOtp, otp: data.otp });
                if (result) {
                    console.log({ result });
                    setIdOtp();
                    success("Successful account registration");
                    navigate("/thank-you");
                }
            }
        } catch (err) {
            error(err);
        } finally {
            form.resetFields();
            dismissLoading();
        }
    };


    const googleLogin = useGoogleLogin({
        onSuccess: async codeResponse => {
            navigate(`/signupwithgoogle/${codeResponse.access_token}`)


        },
        flow: 'implicit',
    });


    return (
        <Card style={{ width: "500px", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
            <Flex vertical gap={14}>
                <Space direction="vertical" style={{ textAlign: "center" }}>
                    <img width={200} style={{ borderRadius: "8px" }} src="https://storage.googleapis.com/cubebio/data/4aa8c176-4c15-4a94-b6bf-83ac71462f10.png" />
                    <span style={{ fontSize: token.fontSizeHeading3, fontWeight: "500" }}>{`${idOtp ? `Confirm otp code` : `Never lose track of an item again.`}`}</span>
                    <span>{`${idOtp ? `The otp code has been sent to your registered email` : `Simple, fast, and powerful inventory software for businesses and teams to stay organized.`}`}</span>
                </Space>

                {!idOtp &&
                    <>

                        <Button type="primary" style={{ width: "100%" }} onClick={googleLogin} ghost>
                            <img width="26" height="26" src="https://img.icons8.com/color/48/google-logo.png" alt="google-logo" />  Sign up with Google
                        </Button>


                        <Divider orientation="center" style={{ opacity: "0.5" }}>
                            OR
                        </Divider>


                    </>
                }

                {idOtp ? (
                    <Form layout="vertical" form={form} initialValues={{}} onFinish={() => onFinish("otp")}>
                        <Form.Item
                            label="OTP"
                            name="otp"
                            rules={[
                                {
                                    required: true,
                                },
                                {
                                    pattern: /^[0-9]{6}$/,
                                    message: "OTP must be a 6-digit number",
                                },
                            ]}
                        >
                            <Input.OTP length={6} style={{ width: "100%" }} />
                        </Form.Item>

                        <Space direction="vertical" style={{ width: "100%" }} size={"large"}>
                            <Button type="primary" style={{ width: "100%" }} onClick={() => handleCreate()}>
                                Confirm
                            </Button>
                        </Space>
                    </Form>
                ) : (
                    <Form layout="vertical" form={form} initialValues={{}} onFinish={() => onFinish("signUp")}>
                        <Flex justify="space-between" gap={12}>
                            <Form.Item
                                label="First Name"
                                name="first_name"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                                style={{ flex: 1 }}
                            >
                                <Input placeholder="First Name"></Input>
                            </Form.Item>

                            <Form.Item
                                label="Last Name"
                                name="last_name"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                                style={{ flex: 1 }}
                            >
                                <Input placeholder="Last Name"></Input>
                            </Form.Item>
                        </Flex>

                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    type: "email",
                                    message: `${email} is not a valid email`,
                                },
                            ]}
                        >
                            <Input placeholder="Email"></Input>
                        </Form.Item>

                        <Form.Item
                            label="Password"
                            name="pass"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input.Password placeholder="Password" iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
                        </Form.Item>

                        <Form.Item
                            label="RePassword"
                            name="re_pass"
                            rules={[
                                {
                                    required: true,
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue("pass") === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error("The two passwords do not match!"));
                                    },
                                }),
                            ]}
                        >
                            <Input.Password placeholder="Password" iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
                        </Form.Item>

                        <Form.Item
                            label="Phone"
                            name="phone"
                            rules={[
                                {
                                    pattern: /^[0-9]{10}$/,
                                    message: "Phone number must be 10 digits",
                                },
                            ]}
                        >
                            <Input placeholder="Phone"></Input>
                        </Form.Item>
                        <Form.Item name="agree" valuePropName="checked" rules={[
                            {
                                validator: (_, value) =>
                                    value ? Promise.resolve() : Promise.reject(new Error('Should accept agreement')),
                            },
                        ]}>
                            <Checkbox > I agree with <a href="#" target="_blank">  Terms of Service</a> ,  <a href="#" target="_blank">  Privacy Policy</a> .</Checkbox>

                        </Form.Item>

                        <Space direction="vertical" style={{ width: "100%" }} size={"large"}>
                            <Button type="primary" style={{ width: "100%" }} onClick={() => handleCreate()} >
                                Create Account
                            </Button>




                            <Flex align="center" justify="center">
                                <span>Already have an account?</span>
                                <Button type="link" onClick={() => navigate("/login")}>
                                    Login
                                </Button>
                            </Flex>
                        </Space>
                    </Form>
                )}
            </Flex>
        </Card>
    );
}

export default Register;
