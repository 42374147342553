import React, { useState, memo, useEffect } from "react";
import { TreeSelect } from "antd";
import FolderService from "services/folder";
import * as Icon from "@ant-design/icons";
import { useAppContext } from "context/app";
import { useItemsScreenContext } from "pages/items";
const folderService = new FolderService();

const FolderPicker = ({ value, onChange, style, lstItemDisable = null }) => {
    const [expandedKeys, setExpandedKeys] = useState([]);
    const [treeData, setTreeData] = useState([]);

    var allFolder = useAppContext().folderList;

    useEffect(() => {
        loadTreeData();
    }, [allFolder, lstItemDisable]);

    useEffect(() => {
        // Trường hợp này là add item (expand tất cả thằng cha của value)
        if (value) {
            var tree = toTreeData(allFolder);
            const parentKeys = findParentKeys(tree, value);
            setExpandedKeys([...parentKeys, value]);
        }
        // Trường hợp này là move item (expand mỗi thằng root)
        else {
            const rootKey = allFolder.find((folder) => folder.is_root)?.id;
            console.log({ rootKey });
            if (rootKey) {
                setExpandedKeys([rootKey]);
            }
        }
    }, [value]);

    const loadTreeData = async () => {
        var tree = toTreeData(allFolder);
        setTreeData(tree);
    };

    const toTreeData = (lst) => {
        var root = lst.find((x) => x.is_root);
        if (!root) return [];
        var arrarLoop = [];
        var tree = { value: root.id, title: "All Folder", children: [], id: root.id, key: root.id };
        arrarLoop.push(tree);

        const lstFolderDisable = lstItemDisable ? lstItemDisable.filter((item) => item.entity_type === "folder").map((item) => item.id) : [];

        while (arrarLoop.length > 0) {
            const node = arrarLoop.pop();
            const disabledParent = lstFolderDisable.includes(node.id) || node.disabled;

            if (disabledParent) {
                node.disabled = true;
            }

            const children = lst
                .filter((x) => x.parent_id === node.id)
                .map((x) => {
                    const childNode = {
                        value: x.id,
                        title: x.name,
                        id: x.id,
                        children: [],
                        key: x.id,
                        sort_index: x.sort_index || "-1",
                        disabled: disabledParent,
                    };

                    return childNode;
                })
                .sort((a, b) => {
                    const aIndex = parseInt(a.sort_index, 10);
                    const bIndex = parseInt(b.sort_index, 10);
                    return aIndex - bIndex;
                });

                node.children = children;

            children.forEach((child) => arrarLoop.push(child));
        }

        return [tree];
    };

    const findParentKeys = (nodes, targetKey, parents = []) => {
        for (let node of nodes) {
            if (node.key === targetKey) {
                return parents;
            }
            if (node.children) {
                const foundParents = findParentKeys(node.children, targetKey, [...parents, node.key]);
                if (foundParents.length > 0) {
                    return foundParents;
                }
            }
        }
        return [];
    };
    const onTreeExpand = (keys) => {
        setExpandedKeys(keys);
    };

    return (
        <TreeSelect
            style={{
                width: 400,
                ...style,
            }}
            value={value}
            dropdownStyle={{
                maxHeight: 400,
                overflow: "auto",
            }}
            suffixIcon={<Icon.DownOutlined size={64} />}
            treeData={treeData}
            placeholder="Please select"
            treeLine={true}
            // treeDefaultExpandAll
            onChange={onChange}
            treeExpandedKeys={expandedKeys}
            onTreeExpand={onTreeExpand}
        />
    );
};
export default memo(FolderPicker);
