import { Modal, Button, Divider, Form, Flex, Select, Switch, Image, TextArea, Space, theme } from "antd";
import { useLoading } from "context/loading";
import { useEffect, useState } from "react";
import ExportAndLayoutType from "./ExportAndLayoutType";
import PreviewExport from "./PreviewExport";
import SettingsAndSelect from "./SettingsAndSelect";
import { useForm } from "antd/es/form/Form";
import ExportService from "services/export";
import * as exportConstants from "./constants";
import TagService from "services/tag";
import CustomFieldService from "services/custom_field";
import dayjs from "dayjs";
import FolderService from "services/folder";
import { useAppContext } from "context/app";
import { getQuantityItem } from "common/get_quantity_item";
import ItemService from "services/item";

const exportService = new ExportService();
const folderService = new FolderService();
const itemService = new ItemService();

function ExportModal({ items, folderId, visible, onClose }) {
    const { useToken } = theme;
    const { token } = useToken();

    const [lstItems, setLstItems] = useState([]);
    const { showLoading, dismissLoading } = useLoading();
    const { showPdfViewer, tagList, customFieldList } = useAppContext();
    const [form] = useForm();
    const fields_export = Form.useWatch("fields_export", { form });

    const [page, setPage] = useState(1);
    const [lstExportType, setLstExportType] = useState([]);
    const [selectedExportType, setSelectedExportType] = useState({});

    const [lstLayoutType, setLstLayoutType] = useState([]);
    const [selectedLayoutType, setSelectedLayoutType] = useState({});

    const [disabledExport, setDisabledExport] = useState(true);

    useEffect(() => {
        if (items) {
            const handleItems = async () => {
                showLoading();

                const itemPromises = items.map(async (item) => {
                    if (item.has_variants) {
                        const folderStockPromises = item.folder_stock.variant_stock.map(async (variantStock) => {
                            const result = await itemService.getByID(variantStock.item_id);
                            result.entity_type = "item";
                            return result;
                        });

                        return await Promise.all(folderStockPromises);
                    }

                    return item;
                });

                const result = await Promise.all(itemPromises);
                const lstItems = result.flat();
                setLstItems(lstItems);

                dismissLoading();
            };

            handleItems();
        }
    }, [items]);

    useEffect(() => {
        fetchExportsType();
        fetchLayoutsType();
    }, []);

    useEffect(() => {
        if (fields_export?.length === 0) {
            setDisabledExport(true);
        } else {
            setDisabledExport(false);
        }
    }, [fields_export]);

    const fetchExportsType = async () => {
        const result = await exportService.getExportsType();
        setLstExportType(result);
        setSelectedExportType(result[0]);
    };

    const fetchLayoutsType = async () => {
        const result = await exportService.getLayoutsType();
        setLstLayoutType(result);
        setSelectedLayoutType(result[0]);
    };

    const handleTags = async (item) => {
        // chuyển tag id thành tag name của item
        let tags = null;
        if (item.tags) {
            let lstNameTags = tagList.filter((tag) => item.tags.includes(tag.id)).map((tag) => tag.title);

            tags = lstNameTags;
        }

        return tags;
    };

    const handleCustomField = async (item) => {
        // chuyển custom_field: {id: value} thành custom_field: {id: {name, value}}
        let custom_field = {};
        if (item.custom_field) {
            Object.keys(item.custom_field).forEach((id) => {
                const result = customFieldList.find((customField) => customField.id === id);
                if (result) {
                    custom_field[id] = {
                        name: result.name,
                        value: result.field_type === "date" ? dayjs(item.custom_field[id]).format("DD/MM/YYYY") : item.custom_field[id],
                    };
                }
            });
        }
        return custom_field;
    };

    const handleItem = async (item, folderId) => {
        const quantity = item.entity_type === "item" ? getQuantityItem(item, folderId) : 0;
        const tags = await handleTags(item);
        const custom_field = await handleCustomField(item);

        return { ...item, quantity, tags, custom_field };
    };

    const handleFolder = async (listItems, folder, folderId) => {
        // Thêm các item của folder vào listItems
        for (const item of folder.items) {
            item.entity_type = "item";
            listItems.push({ ...(await handleItem(item, folderId)), sub_folder: folder.statistic.folders, total_value: folder.statistic.total_quantity });
        }

        // Nếu folder có các subfolder, gọi đệ quy handleFolder
        if (folder.statistic.folders > 0) {
            for (const subFolder of folder.folders) {
                // Thêm subFolder vào listItems trước khi xử lý các item và subFolder con
                subFolder.entity_type = "folder";
                listItems.push({ ...(await handleItem(subFolder, subFolder.id)), sub_folder: folder.statistic.folders, total_value: folder.statistic.total_quantity });
                const result = await folderService.childs({ folder_id: subFolder.id });
                await handleFolder(listItems, result, subFolder.id);
            }
        }

        return listItems;
    };

    const handleExport = async () => {
        showLoading();
        try {
            let exportType = "";
            switch (selectedExportType.type) {
                case exportConstants.SPREADSHEET:
                    exportType = selectedExportType.type;
                    break;
                case exportConstants.PAGE:
                    exportType = selectedLayoutType.type;
                    break;
                case exportConstants.INTEGRATIONS:
                    exportType = selectedExportType.TextArea;
                    break;
                default:
                    break;
            }
            const options = { ...form.getFieldsValue(), export_type: exportType };

            const data = await Promise.all(
                lstItems.map(async (item) => {
                    // Trường hợp item này là folder
                    if (item.entity_type === "folder") {
                        const folder = await folderService.childs({ folder_id: item.id });

                        // Trường hợp chọn options: Include Sub-Folders and Items
                        if (options.has_subFolders_items) {
                            const listItems = [];
                            listItems.push({ ...(await handleItem(item, folderId)), sub_folder: folder.statistic.folders, total_value: folder.statistic.total_quantity });

                            return await handleFolder(listItems, folder, item.id);
                        }
                        // Trường hợp không chọn options: Include Sub-Folders and Items
                        else {
                            return { ...(await handleItem(item, folderId)), sub_folder: folder.statistic.folders, total_value: folder.statistic.total_quantity };
                        }
                    } else {
                        // xuất item
                        return { ...(await handleItem(item, folderId)) };
                    }
                })
            );

            const flatData = data.flat();
            console.log({ flatData, options });
            const result = await exportService.export(flatData, options);

            // Trường hợp trả về url file excel
            if (exportType === exportConstants.SPREADSHEET) {
                const a = document.createElement("a");
                a.href = result.url;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            } else {
                showPdfViewer(result.url);
            }
        } catch (error) {
            console.error("Error during export:", error);
        } finally {
            resetValue();
            dismissLoading();
        }
    };

    const resetValue = () => {
        form.resetFields();
        setPage(1);
        onClose();
    };

    return (
        <Modal width={1000} title={<p style={{ fontSize: token.fontSizeHeading4, textAlign: "center" }}>Export</p>} open={visible} onCancel={() => resetValue()} maskClosable={false} footer={null}>
            <Flex vertical>
                <Flex>
                    {page === 1 ? (
                        <div style={{ flex: 1 }}>
                            <ExportAndLayoutType
                                lstExportType={lstExportType}
                                selectedExportType={selectedExportType}
                                setSelectedExportType={setSelectedExportType}
                                lstLayoutType={lstLayoutType}
                                selectedLayoutType={selectedLayoutType}
                                setSelectedLayoutType={setSelectedLayoutType}
                            />
                        </div>
                    ) : (
                        <SettingsAndSelect items={lstItems} form={form} selectedExportType={selectedExportType} selectedLayoutType={selectedLayoutType} />
                    )}

                    <div style={{ flex: 1, alignSelf: "center", textAlign: "center" }}>
                        <PreviewExport selectedExportType={selectedExportType} selectedLayoutType={selectedLayoutType} />
                    </div>
                </Flex>

                <Divider />

                <Flex justify="space-between">
                    {page === 1 ? (
                        <>
                            <div></div>
                            <span>Step {page} of 2</span>
                            <Button
                                type="primary"
                                onClick={() => {
                                    setPage(2);
                                }}
                            >
                                Next
                            </Button>
                        </>
                    ) : (
                        <>
                            <Button
                                onClick={() => {
                                    setPage(1);
                                }}
                            >
                                Back
                            </Button>
                            <span>Step {page} of 2</span>
                            <Button type="primary" onClick={handleExport} disabled={disabledExport}>
                                Export
                            </Button>
                        </>
                    )}
                </Flex>
            </Flex>
        </Modal>
    );
}

export default ExportModal;
