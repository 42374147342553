import { memo, useEffect, useState } from "react";
import { Button, Divider, Flex, Input, Switch, Table, Image, Dropdown, Pagination, Card } from "antd";
import * as Icon from "@ant-design/icons";
import * as Number from "common/number_format";
import { getAlertItem } from "common/get_alert_item";
import Tags from "components/tag-list";
import { useAppContext } from "context/app";
import { CustomField } from "pages";
import RenderCustomField from "pages/item-detail/RenderCustomField";
import ItemService from "services/item";
import { useNavigate } from "react-router-dom";
import * as Date from "../../common/date_format";

function TableInventory({ loading, items, paging, setPaging, statistic, setSorts }) {
    const navigate = useNavigate();

    useEffect(() => {
        setSorts({ create_on: -1 });
    }, []);

    const handleTableChange = (pagination, filters, sorter) => {
        const { field, order } = sorter;
        setSorts(order ? { [field]: order === "ascend" ? 1 : -1 } : {});
    };

    const columns = [
        {
            title: "Transaction Date",
            dataIndex: "create_on",
            key: "create_on",
            width: "50px",
            fixed: "left",
            sorter: true,
            defaultSortOrder: "descend",
            render: (text, record) => {
                return <div>{Date.formatDateTime(record.create_on)}</div>;
            },
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            width: "100px",
            fixed: "left",
            render: (text, record) => (
                <Flex vertical style={{ cursor: "pointer" }} onClick={() => navigate(`/item-detail/${record.item.id}`)}>
                    <span>{record.item?.name}</span>
                    <span>{record.item?.variant_title}</span>
                </Flex>
            ),
        },
        {
            title: "Folder",
            dataIndex: "folder",
            key: "folder",
            width: "100px",
            fixed: "left",
            render: (text, record) => (
                <div style={{ cursor: "pointer" }} onClick={() => navigate(`/folder/${record.folder.id}`)}>
                    {record.folder?.name}
                </div>
            ),
        },
        {
            title: "QTY Change",
            dataIndex: "quantity",
            key: "quantity",
            width: "50px",
            align: "right",
            sorter: true,
            render: (text, record) => {
                const quantity = record.quantity;
                return <span>{quantity > 0 ? `+${quantity}` : quantity}</span>;
            },
        },
        {
            title: "Transaction Type",
            dataIndex: "transaction_type",
            key: "transaction_type",
            width: "50px",
        },
        {
            title: "Transaction Note",
            dataIndex: "notes",
            key: "notes",
            width: "50px",
        },
        {
            title: "User",
            dataIndex: "create_by",
            key: "create_by",
            width: "50px",
            render: (text, record) => {
                const quantity = record.quantity;
                return <span>{record.user.first_name} {record.user.last_name} </span>;
            },
        },
    ];

    return (
        <Card>
            <Table
                loading={loading}
                dataSource={items}
                columns={columns}
                onChange={handleTableChange}
                pagination={{
                    current: paging.page,
                    pageSize: paging.pageSize,
                    total: statistic.rows,
                    onChange: (page, pageSize) => {
                        setPaging({ page, pageSize });
                    },
                    showSizeChanger: true,
                    pageSizeOptions: ["10", "20", "50", "100"],
                }}
            />
        </Card>
    );
}

export default memo(TableInventory);
