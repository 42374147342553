import React, { useState, useEffect } from "react";
import { Modal, Button, Divider, Form, Flex, Select, Switch, Image, TextArea, theme } from "antd";
import barcodeDemo from "assets/images/barcode_demo.png";
import qrCodeDemo from "assets/images/qrcode_demo.png";
import photoDisplayHere from "assets/images/photo_display_here.png";
import OptionsAndSettingsLabel from "./OptionsAndSettingsLabel";
import PreviewPrintLabel from "./PreviewPrintLabel";
import OptionsAndInformation from "./OptionsAndInfomationLabel";
import { useCreateLabel } from "context/label";
import { useForm } from "antd/es/form/Form";
import LabelService from "services/label";
import { useLoading } from "context/loading";
import TagService from "services/tag";
import CustomFieldService from "services/custom_field";
import dayjs from "dayjs";
import FolderService from "services/folder";
import { useAppContext } from "context/app";
import ItemService from "services/item";
import { getQuantityItem } from "common/get_quantity_item";

const labelServices = new LabelService();
const tagsService = new TagService();
const customFieldService = new CustomFieldService();
const folderService = new FolderService();
const itemService = new ItemService();

function CreateLabelsModal({ items, folderId, visible, onClose }) {
    const { useToken } = theme;
    const { token } = useToken();

    const [lstItems, setLstItems] = useState([]);
    const { showLoading, dismissLoading } = useLoading();
    const { showPdfViewer, tagList, customFieldList } = useAppContext();
    const [page, setPage] = useState(1);
    const [selectedLabelSize, setSelectedLabelSize] = useState({});
    const { dataLabel, setDataLabel } = useCreateLabel();
    const [form] = useForm();

    useEffect(() => {
        if (items) {
            const handleItems = async () => {
                showLoading();

                const itemPromises = items.map(async (item) => {
                    if (item.has_variants) {
                        const folderStockPromises = item.folder_stock.variant_stock.map(async (variantStock) => {
                            const result = await itemService.getByID(variantStock.item_id);
                            result.entity_type = "item";
                            return result;
                        });

                        return await Promise.all(folderStockPromises);
                    }

                    return item;
                });

                const result = await Promise.all(itemPromises);
                const lstItems = result.flat();
                setLstItems(lstItems);

                dismissLoading();
            };

            handleItems();
        }
    }, [items]);

    const handleTags = async (lstIdTag) => {
        // chuyển tag id thành tag name của item
        if (lstIdTag) {
            let lstNameTags = tagList.filter((tag) => lstIdTag.includes(tag.id)).map((tag) => tag.title);

            console.log({ lstNameTags });
            return lstNameTags;
        }

        return null;
    };

    const handleCustomField = async (customField) => {
        // chuyển custom_field: {id: value} thành custom_field: {id: {name, value}}
        let customFieldArray = Object.keys(customField).map((id) => {
            let result = customFieldList.find((customField) => customField.id === id);
            if (result.field_type === "date") {
                return dayjs(customField[id]).format("DD/MM/YYYY");
            }

            return customField[id];
        });

        console.log({ customFieldArray });
        return customFieldArray;
    };

    const handleQuantityLabel = (item, options) => {
        switch (options.label_quantity) {
            case "1_per_item":
                return 1;
            case "custom":
                return options.amount;
            case "as_per_current_item_quantity":
                if (item.entity_type === "folder") {
                    return 1;
                }
                return item.quantity || 1;
            default:
                console.log("Không tìm thấy options.label_quantity");
                break;
        }
    };

    const handleItem = async (item, options, folderId) => {
        const quantity = item.entity_type === "item" ? getQuantityItem(item, folderId) : 0;
        item.quantity = quantity;
        const labels = handleQuantityLabel(item, options);
        item.labels = labels;

        let tags = null;
        let custom_field = null;
        if (options.has_detail) {
            if (options.detail.value === "tags" && item.tags) {
                tags = await handleTags(item.tags);
            }

            if (options.detail.value === "custom_field" && Object.keys(item.custom_field).length > 0) {
                custom_field = await handleCustomField(item.custom_field);
            }
        }

        if (tags == null && custom_field == null) {
            return { ...item };
        } else if (tags == null && custom_field != null) {
            return { ...item, custom_field };
        } else if (tags != null && custom_field == null) {
            return { ...item, tags };
        } else {
            return { ...item, tags, custom_field };
        }
    };

    const handleFolder = async (listItems, folder, options, folderId) => {
        // Thêm các item của folder vào listItems
        for (const item of folder.items) {
            item.entity_type = "item";
            listItems.push({ ...(await handleItem(item, options, folderId)), labels: handleQuantityLabel(item, options) });
        }

        // Nếu folder có các subfolder, gọi đệ quy handleFolder
        if (folder.statistic.folders > 0) {
            for (const subFolder of folder.folders) {
                // Thêm subFolder vào listItems trước khi xử lý các item và subFolder con
                subFolder.entity_type = "folder";
                listItems.push({ ...(await handleItem(subFolder, options, subFolder.id)) });
                const result = await folderService.childs({ folder_id: subFolder.id });
                await handleFolder(listItems, result, options, subFolder.id);
            }
        }

        return listItems;
    };

    const handleCreateLabel = async () => {
        showLoading();
        try {
            const options = { ...dataLabel, ...form.getFieldsValue() };

            const data = await Promise.all(
                lstItems.map(async (item) => {
                    if (item.entity_type === "folder") {
                        const folder = await folderService.childs({ folder_id: item.id });

                        // Trường hợp chọn options: Include Sub-Folders and Items
                        if (options.has_subFolders_items) {
                            const listItems = [];
                            listItems.push({ ...(await handleItem(item, options, folderId)) });
                            return await handleFolder(listItems, folder, options, item.id);
                        }
                        // Trường hợp không chọn options: Include Sub-Folders and Items
                        else {
                            return { ...(await handleItem(item, options, folderId)) };
                        }
                    } else {
                        // tạo label cho item
                        return { ...(await handleItem(item, options, folderId)) };
                    }
                })
            );

            const flatData = data.flat();
            console.log({ flatData, options });

            const result = await labelServices.print(flatData, options);
            const pdfUrl = result.url;
            showPdfViewer(pdfUrl);
        } catch (error) {
            console.error("Error print label:", error);
        } finally {
            resetValue();
            dismissLoading();
        }
    };

    const resetValue = () => {
        setPage(1);
        setDataLabel({});
        form.resetFields();
        onClose();
    };

    return (
        <Modal
            width={1000}
            title={<p style={{ fontSize: token.fontSizeHeading4, textAlign: "center" }}>Create Label</p>}
            open={visible}
            onCancel={() => resetValue()}
            maskClosable={false}
            footer={null}
        >
            <Flex vertical>
                <Flex>
                    <Form form={form} layout="vertical" style={{ flex: 1, marginRight: token.margin, paddingRight: token.padding, maxHeight: "568px", overflowY: "scroll" }}>
                        {page === 1 ? <OptionsAndSettingsLabel items={lstItems} form={form} setSelectedLabelSize={setSelectedLabelSize} /> : <OptionsAndInformation form={form} />}
                    </Form>

                    <PreviewPrintLabel form={form} selectedLabelSize={selectedLabelSize} />
                </Flex>
                <Divider />

                <Flex justify="space-between">
                    {page === 1 ? (
                        <>
                            <div></div>
                            <span>Step {page} of 2</span>
                            <Button
                                type="primary"
                                onClick={() => {
                                    setDataLabel({ ...form.getFieldsValue(), label_size: selectedLabelSize });
                                    setPage(2);
                                }}
                            >
                                Next
                            </Button>
                        </>
                    ) : (
                        <>
                            <Button
                                onClick={() => {
                                    setDataLabel({});
                                    setPage(1);
                                }}
                            >
                                Back
                            </Button>
                            <span>Step {page} of 2</span>
                            <Button type="primary" onClick={handleCreateLabel}>
                                Create Label
                            </Button>
                        </>
                    )}
                </Flex>
            </Flex>
        </Modal>
    );
}

export default CreateLabelsModal;
