import { ColorPicker, Flex, Form, Input, Modal, Space, theme } from "antd";
import { generate, green, presetPalettes, red } from "@ant-design/colors";
import React, { useState, useMemo, useEffect, useContext } from "react";
import TagService from "services/tag";
import { useNotification } from "context/notification";
import { useLoading } from "context/loading";
import ColorsPicker from "components/pickers/color/ColorPicker";

function AddOrEditTagModal({ visible, onClose, onChange, onChangeItemAddTag = null, value = null }) {
    const { useToken } = theme;
    const { token } = useToken();

    const [form] = Form.useForm();
    const { success, error } = useNotification();
    const { showLoading, dismissLoading } = useLoading();

    useEffect(() => {
        if (!visible) return;

        if (value) {
            form.setFieldsValue(value);
        } else {
            form.resetFields();
        }
    }, [visible]);

    const onOk = () => {
        form.submit();
    };

    const onFinish = async () => {
        showLoading("Saving");
        const data = { ...form.getFieldsValue() };
        const tagService = new TagService();

        let result;
        if (value) {
            result = await tagService.edit(data);
            if (result) {
                success(`Tag ${data.title} has been successfully updated`);
            } else {
                error("Failed to update the tag. Please try again later.");
            }
        } else {
            result = await tagService.add(data);
            if (result) {
                success(`Tag ${data.title} has been successfully created`);
            } else {
                error("Failed to add the tag. Please try again later.");
            }
        }

        // onChangeItemAddTag là trường hợp thêm tag ở item
        if (onChangeItemAddTag) {
            onChangeItemAddTag(result);
        } else {
            onChange();
        }
        dismissLoading();
    };

    return (
        <Modal
            title={<p style={{ fontSize: token.fontSizeHeading4, textAlign: "center" }}>{value ? "Update Tag" : "Add Tag"}</p>}
            open={visible}
            onOk={onOk}
            onCancel={onClose}
            maskClosable={false}
            okText={value ? "Update" : "Add"}
        >
            <Form layout="vertical" form={form} initialValues={{}} onFinish={onFinish}>
                <Form.Item name="id" hidden>
                    <Input></Input>
                </Form.Item>

                <Form.Item
                    label="Title"
                    name="title"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input placeholder="Title"></Input>
                </Form.Item>

                <Form.Item
                    label="Color"
                    name="color"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <ColorsPicker />
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default AddOrEditTagModal;
