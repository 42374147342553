import { useEffect, memo } from "react";
import { Button, Flex, Space, Divider, Input, Switch, Dropdown, theme } from "antd";
import { SearchOutlined, BarcodeOutlined, ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import * as Icon from "@ant-design/icons";
import { useItemsScreenContext } from "pages/items";
import { CustomButtonLink } from "components/Buttons";

const Filter = () => {
    const { token } = theme.useToken();
    const { displayOption, setDisplayOption, filter, setFilter, showFolderList, setShowFolderList, sort, setSort } = useItemsScreenContext();
    const groupItemChange = (checked) => {
        setDisplayOption({ ...displayOption, group_item: checked });
    };

    const iconStyle = { fontSize: 14, color: token.colorPrimary };
    const renderIconSort = (currentKey) => {
        if(!sort) {
            return null;
        }

        // type = 1 || -1
        const renderSort = (type) => {
            if (type === 1) {
                return <ArrowUpOutlined style={{ ...iconStyle }} />;
            } else if (type === -1) {
                return <ArrowDownOutlined style={{ ...iconStyle }} />;
            }
        };

        const key =  Object.keys(sort)[0];
        if (key === currentKey) {
            return renderSort(Object.values(sort)[0]);
        }

        return null;
    };

    const items = [
        {
            key: "sort_quantity",
            label: (
                <Flex gap={10}>
                    <span>Quantity</span>
                    {renderIconSort("folder_stock.quantity")}
                </Flex>
            ),
        },
        {
            key: "sort_name",
            label: (
                <Flex gap={10}>
                    <span>Name</span>
                    {renderIconSort("name")}
                </Flex>
            ),
        },
        {
            key: "sort_price",
            label: (
                <Flex gap={10}>
                    <span>Price</span>
                    {renderIconSort("price")}
                </Flex>
            ),
        },
    ];

    const menuClick = ({ item, key, keyPath, domEvent }) => {
        console.log({ item, key, keyPath });

        const currentSortKey = Object.keys(sort)[0];
        const currentSortValue = Object.values(sort)[0];

        switch (key) {
            case "sort_quantity":
                setSort({
                    ["folder_stock.quantity"]: currentSortKey === "folder_stock.quantity" && currentSortValue === 1 ? -1 : 1,
                });
                break;
            case "sort_name":
                setSort({
                    name: currentSortKey === "name" && currentSortValue === 1 ? -1 : 1,
                });
                break;
            case "sort_price":
                setSort({
                    price: currentSortKey === "price" && currentSortValue === 1 ? -1 : 1,
                });
                break;
            default:
                break;
        }
    };

    return (
        <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ flexGrow: 1, maxWidth: 500 }}>
                <Input
                    value={filter.info}
                    onChange={(e) => setFilter({ ...filter, info: e.target.value })}
                    addonBefore={<SearchOutlined />}
                    placeholder="Search All Items"
                    addonAfter={
                        <CustomButtonLink size="small">
                            <BarcodeOutlined />
                        </CustomButtonLink>
                    }
                />
            </div>
            <div style={{ alignContent: "center", paddingLeft: 16 }}>
                <Space size="large">
                    <Dropdown
                        menu={{ items: [] }}
                        dropdownRender={(menu) => (
                            <div
                                style={{
                                    backgroundColor: token.colorBgElevated,
                                    borderRadius: token.borderRadiusLG,
                                    boxShadow: token.boxShadowSecondary,
                                    padding: token.padding,
                                }}
                            >
                                <Space direction="vertical">
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <Switch size="small" title="Group Items" onChange={groupItemChange} value={displayOption?.group_item}></Switch>
                                        <div style={{ marginLeft: 8 }}>Group Items</div>
                                    </div>

                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <Switch
                                            size="small"
                                            title="Group Items"
                                            onChange={(checked) => {
                                                setShowFolderList(checked);
                                            }}
                                            value={showFolderList}
                                        ></Switch>
                                        <div style={{ marginLeft: 8 }}>Show Folder</div>
                                    </div>
                                </Space>
                            </div>
                        )}
                    >
                        <CustomButtonLink onClick={(e) => e.preventDefault()}>
                            <Space>
                                Options
                                <Icon.DownOutlined />
                            </Space>
                        </CustomButtonLink>
                    </Dropdown>

                    <Dropdown
                        menu={{
                            items: items,
                            onClick: menuClick,
                        }}
                    >
                        <CustomButtonLink onClick={(e) => e.preventDefault()}>
                            <Space>
                                Sort
                                <Icon.DownOutlined />
                            </Space>
                        </CustomButtonLink>
                    </Dropdown>
                    {/* <Dropdown
                        trigger="click"
                        menu={{ items: [] }}
                        dropdownRender={(menu) => (
                            <div
                                style={{
                                    backgroundColor: token.colorBgElevated,
                                    borderRadius: token.borderRadiusLG,
                                    boxShadow: token.boxShadowSecondary,
                                    padding: token.padding,
                                }}
                            >
                                <Space direction="vertical">
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <Switch size="small" title="Group Items" onChange={groupItemChange}></Switch>
                                        <div style={{ marginLeft: 8 }}>Group Items</div>
                                    </div>

                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <Switch size="small" title="Group Items" onChange={groupItemChange}></Switch>
                                        <div style={{ marginLeft: 8 }}>Show Folder</div>
                                    </div>
                                </Space>
                            </div>
                        )}
                    >
                        <a onClick={(e) => e.preventDefault()}>
                            <Space>
                                Display
                                <Icon.DownOutlined />
                            </Space>
                        </a>
                    </Dropdown> */}
                </Space>
            </div>
        </div>
    );
};

export default memo(Filter);
