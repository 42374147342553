import { useNavigate } from "react-router-dom";
import { Button, Card, Divider, Flex, Form, Image, Input, Space, theme } from "antd";

function ThankYou() {
    const { token } = theme.useToken();
    const navigate = useNavigate();

    return (
        <Card style={{ width: "500px", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
            <Flex vertical gap={14}>
                <Space direction="vertical" align="center">
                    <img width={200} style={{ borderRadius: "8px" }} src="https://storage.googleapis.com/cubebio/data/4aa8c176-4c15-4a94-b6bf-83ac71462f10.png" />
                    <span style={{ fontSize: token.fontSizeHeading3, fontWeight: "500" }}>Thank you for registering an account</span>
                    <Flex align="center" justify="center">
                        <span>Please log in to use the application</span>
                        <Button type="link" onClick={() => navigate("/login")}>
                            Login
                        </Button>
                    </Flex>
                </Space>
            </Flex>
        </Card>
    );
}

export default ThankYou;
