import { useEffect, memo, useState, useMemo, useContext, createContext, useRef, useCallback } from "react";
import { Button, Flex, Space, Divider, Row, Col, Input, Skeleton, Breadcrumb, theme, Dropdown, Card } from "antd";
import Filter from "./Filter"
import Statistic from "./Statistic"
import List from "./List"
import ItemSelectedMenuBar from "./ItemSelectedMenuBar"
import FolderTree from "components/pickers/folder/FolderTree";
import { BrowserRouter, useNavigate, useParams } from "react-router-dom";
import ItemService from "services/item";
import FolderService from "services/folder";
import * as FolderList from "components/folder-list"
import _ from "lodash"
import { useAppContext } from "context/app";
import * as Icon from "@ant-design/icons";
import { Empty } from 'antd';
import ListOptionFolder from "pages/folder/ListOptionFolder";
import UserSettingService from "services/user_setting";
const { Search } = Input;
const itemService = new ItemService();
const folderService = new FolderService();
const userSettingServices = new UserSettingService();

const ItemsScreenContext = createContext();
const { useToken } = theme;


const ItemsPage = () => {
    const navigate = useNavigate();
    var { eventBus, showExportModal, showCreateLabelModal, showMoveFolderModal } = useAppContext();
    const { token } = useToken();
    const [folderList, setFolderList] = useState([])
    const [loading, setLoading] = useState(true);
    const [statistic, setStatistic] = useState({});
    const [data, setData] = useState({})

    const loadItems = async (query = {}, sort = {}, option = { group_item: false, paging: { page: 1, page_size: 10 }, only_items: false }, keepCurrentPage = false) => {
        if (!query.folder_id) {
            return;
        }
        setSelectedItems([])
        setLoading(true);
        var data = await folderService.childs(query, sort, option);
        setItems(data.items);
        setFolderList(data.folders)
        setStatistic(data.statistic)
        setLoading(false);

        if (keepCurrentPage) {

            setPaging({ ...pagingRef.current, total_row: data.statistic.rows });
        } else {
            setPaging({ ...paging, page: 1, total_row: data.statistic.rows });
        }


    }


    //const params = useParams();
    const loadDelay = useMemo(() => _.debounce(loadItems, 500), []);

    const { items, setItems, currentFolder, showFolderList, currentFolderPath, paging, displayOption, setPaging, filter, setSelectedItems, showTreeList, setShowTreeList, sort } = useItemsScreenContext();
    const pagingRef = useRef(paging);
    useEffect(() => {


        var query = { ...filter, folder_id: currentFolder };

        var option = { group_item: displayOption?.group_item, paging: { ...paging, page: 1 } };
        loadDelay(query, sort, option);
        const off = eventBus.on("item_changed", payload => {

            var option = { group_item: displayOption.group_item, paging: { ...pagingRef.current } };
            loadDelay(query, sort, option, true)
        })
        return off;

    }, [currentFolder, displayOption, filter, sort])

    useEffect(() => {
        const setOptionAndSortSetting = async () => {
            try {
                await userSettingServices.save({ key: "options_sort_setting", value: { showFolderList, displayOption, sort } });
            } catch (error) {
                console.error("Failed to save user settings:", error);
            }
        };

        if(showFolderList || displayOption || sort) {
            setOptionAndSortSetting();
        }
        
    }, [showFolderList, displayOption, sort]);


    useEffect(() => {

        if (currentFolder) {

            fetchFolder();
            navigate(`/folder/${currentFolder}`)
        }

    }, [currentFolder]);


    const fetchFolder = async () => {
        if (currentFolder) {
            const result = await folderService.getByID(currentFolder);
            setData(result);
        }
    };



    useEffect(() => {

        if (pagingRef.current.page != paging.page || pagingRef.current.page_size != paging.page_size)
            loadPage();
        pagingRef.current = paging;
    }, [paging])




    const loadPage = async () => {
        setLoading(true);

        var data = await folderService.childs({ folder_id: currentFolder }, sort, { group_item: displayOption.group_item, paging, only_items: true });
        setItems(data.items);
        setLoading(false);

    }

    return (
        <Row gutter={16}>

            <Col span={showTreeList ? 6 : 0} style={{ transition: `width 0.2s`, background: token.colorBgContainer }}>

                <div style={{ position: "sticky", top: 0, zIndex: 3, height: "100vh" }}>


                    <FolderTree ></FolderTree>

                </div>

            </Col>
            <Col span={showTreeList ? 18 : 24} style={{ transition: `width 0.2s` }}>
                <div>
                    <div style={{ position: "sticky", top: 0, zIndex: 3, background: token.colorBgLayout }}>
                        <div style={{ flexDirection: "row", display: "flex", height: 54 }}>
                            <div style={{ alignContent: "center", marginRight: token.margin}} >
                                {showTreeList ?
                                    <Button type="link" onClick={e => setShowTreeList(false)} style={{color: token.colorPrimary}}><Icon.MenuFoldOutlined /></Button>
                                    :
                                    <Button type="link" onClick={e => setShowTreeList(true)} style={{color: token.colorPrimary}}><Icon.MenuUnfoldOutlined /></Button>
                                }

                            </div>
                            <div style={{ flexGrow: 1 }}>
                                <Breadcrumb
                                    style={{
                                        margin: '16px 0'                  
                                    }}
                                >
                                    {currentFolderPath.map(x => {
                                        return (
                                            <Breadcrumb.Item >
                                                <a href="" onClick={e => { e.preventDefault(); navigate(`/folder/${x.id}`) }}>
                                                    {x.name}
                                                </a>
                                            </Breadcrumb.Item>
                                        )

                                    })}



                                </Breadcrumb>

                                <Space>

                                </Space>
                            </div>

                            <div style={{ alignContent: "center" }}>
                                <Space>
                                    <ListOptionFolder data={data} />
                                    <Button type="primary" onClick={e => navigate(`/item/add/${currentFolder}`)}>ADD ITEM</Button>
                                    <Button type="primary" onClick={e => navigate(`/folder/add/${currentFolder}`)}>ADD FOLDER</Button>
                                </Space>
                            </div>
                        </div>
                        <Divider style={{ margin: 0, marginBottom: token.marginSM }}></Divider>
                        <ItemSelectedMenuBar></ItemSelectedMenuBar>
                    </div>



                    <Space direction="vertical" style={{ width: "100%" }} size="large" >
                        <Card>


                            <Filter ></Filter>
                            <div style={{ marginTop: token.margin }} >


                                {loading ?

                                    <Skeleton active paragraph={{ rows: 1 }} title={null} />

                                    :

                                    <Statistic data={statistic}  ></Statistic>


                                }
                            </div>
                        </Card>
                        <div>


                            {loading ?
                                <div>
                                    <Skeleton active title={null} paragraph={{ rows: 3 }} />
                                </div>
                                :
                                <>
                                    {(statistic.folders == 0 && statistic.rows == 0) ?

                                        <>
                                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                        </>
                                        :
                                        <>
                                            {showFolderList &&
                                                <>
                                                    <FolderList.Gird data={folderList}></FolderList.Gird>
                                                    <div style={{ height: 16 }}>

                                                    </div>
                                                </>
                                            }

                                            <List items={items} loading={loading}></List>
                                        </>



                                    }


                                </>

                            }
                        </div>
                    </Space>

                </div>
            </Col>
        </Row>

    )
}
const ItemsScreen = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [items, setItems] = useState([]);
    const [sort, setSort] = useState()
    var folders = useAppContext().folderList;
    const [currentFolder, setCurrentFolder] = useState(params.id);
    const [currentFolderPath, setCurrentFolderPath] = useState([])
    const [paging, setPaging] = useState({ page: 1, page_size: 10, total_row: 0, });
    const [displayOption, setDisplayOption] = useState();

    const [filter, setFilter] = useState({ info: "" });
    const [selectedItems, setSelectedItems] = useState([]);

    const [showTreeList, setShowTreeList] = useState(true)

    const [showFolderList, setShowFolderList] = useState();

    useEffect(() => {
        const getOptionAndSortSetting = async () => {
            try {
                const getSetting = await userSettingServices.get({});
                const screenSetting = getSetting?.options_sort_setting || null;
                if (!screenSetting || Object.keys(screenSetting).length === 0) {
                    setDisplayOption({ group_item: false })
                    setShowFolderList(true)
                    setSort({ "folder_stock.quantity": -1 })
                } 
                else {
                    setDisplayOption({...screenSetting.displayOption})
                    setShowFolderList(screenSetting.showFolderList)
                    setSort(screenSetting.sort)
                }
            } catch (error) {
                console.error("Failed to save user settings:", error);
            }
        };

        getOptionAndSortSetting();
    }, [])

    console.log({displayOption, showFolderList, sort})


    useEffect(() => {
        var path = getTreePath(currentFolder);
        setCurrentFolderPath(path);

    }, [folders, currentFolder])



    useEffect(() => {
        if (params.id != currentFolder) {
            setCurrentFolder(params.id);
        }

    }, [params])

    useEffect(() => {
        if (!params.id) {
            var root = folders.find(x => x.is_root);
            console.log("root", root);
            if (root) {
                navigate(`/folder/${root.id}`)
            }
        }

    }, [params, folders])




    const getTreePath = (id) => {
        var path = [];
        var currentID = id;
        while (true) {
            var folder = folders.find(x => x.id == currentID);
            if (folder) {
                path.push(folder);
                if (folder.parent_id) {
                    currentID = folder.parent_id;
                } else {
                    break;
                }
            } else {
                break;
            }
        }

        return _.reverse(path);

    }

    const selectItem = (item, checked) => {

        if (checked) {
            var old = selectedItems.find(x => x.id == item.id);
            if (!old) {
                var newSelect = [...selectedItems];
                newSelect.push(item);
                setSelectedItems(newSelect);
            }
        } else {
            var newSelect = selectedItems.filter(x => x.id != item.id);
            setSelectedItems([...newSelect]);
        }
    }



    return (
        <ItemsScreenContext.Provider value={{
            items, setItems,
            currentFolder, setCurrentFolder, currentFolderPath, setCurrentFolderPath,
            paging, setPaging, displayOption, setDisplayOption, filter, setFilter,
            selectedItems, setSelectedItems, selectItem,
            showTreeList, setShowTreeList,
            showFolderList, setShowFolderList,
            sort, setSort

        }}>
            <ItemsPage></ItemsPage>
        </ItemsScreenContext.Provider>
    )
}

const useItemsScreenContext = () => {
    const context = useContext(ItemsScreenContext);
    return context;
}


export { useItemsScreenContext }

export default memo(ItemsScreen);