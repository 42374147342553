import React, { memo, useEffect, useState } from "react";
import { Select, Divider, Button, Space, Tag, Card } from "antd";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import TagService from "services/tag";
import AddOrEditTagModal from "components/Tag/AddOrEditTagModal";
import { useAppContext } from "context/app";
import { CustomButtonLink } from "components/Buttons";
const TagPicker = ({ onChange, value, placeholder = "Add a tag", hiddenButtonAdd = false }) => {
  const [options, setOptions] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const { tagList } = useAppContext();

  useEffect(() => {
    loadDataTag(tagList);
  }, [tagList]);

  const loadDataTag = async (dataTag) => {

    const dataOptions = dataTag.map((item) => ({
      value: item.id,
      label: item.title,
      color: item.color,
    }));

    setOptions(dataOptions);
  };

  const onChangeItemAddTag = (result) => {
    const newOption = {
      value: result.id,
      label: result.title,
      color: result.color,
    };
    setOptions([...options, newOption]);
    onChange([...(value || []), result.id]);
    setModalOpen(false);
  };

  const getContrastYIQ = (hexColor) => {
    hexColor = hexColor.replace("#", "");
    const r = parseInt(hexColor.substr(0, 2), 16);
    const g = parseInt(hexColor.substr(2, 2), 16);
    const b = parseInt(hexColor.substr(4, 2), 16);
    const yiq = (r * 299 + g * 587 + b * 114) / 1000;
    return yiq >= 128 ? "black" : "white";
  };

  const tagRender = (props) => {
    const { label, value, closable, onClose } = props;
    const tagColor = options.find((option) => option.value === value)?.color || "default";
    const textColor = getContrastYIQ(tagColor);

    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        closeIcon={<CloseOutlined style={{ color: textColor }} />}
        style={{
          marginInlineEnd: 4,
          backgroundColor: tagColor,
          color: textColor,
        }}
      >
        {label}
      </Tag>
    );
  };

  return (
    <>
      <AddOrEditTagModal visible={isModalOpen} onClose={() => setModalOpen(false)} onChangeItemAddTag={onChangeItemAddTag} />
      <Select
        mode="multiple"
        style={{ width: "100%" }}
        placeholder={placeholder}
        onChange={onChange}
        options={options}
        value={value}
        tagRender={tagRender}
        dropdownRender={(menu) => (
          <>
            {options.length == 0 &&
              <Card bordered={null}>
                <Space direction="vertical">
                  Apply tags to categorize items further and make them easier to search and filter.
                  <div>
                    e.g. Electronics, Mobile Devices, Furniture, Kitchen Essentials
                  </div>
                  <div>
                    Click "Add Tag" to create a new tag.
                  </div>
                </Space>

              </Card>
            }
            {options.length > 0 &&
              <div>
                {menu}
              </div>

            }

            {
              !hiddenButtonAdd &&
              <>
                <Divider style={{ margin: "8px 0" }} />
                <Space style={{ padding: "0 8px 4px" }}>
                  <CustomButtonLink type="link" icon={<PlusOutlined />} onClick={() => setModalOpen(true)}>
                    Add tag
                  </CustomButtonLink>
                </Space>
              </>
            }
          </>
        )}
      />
    </>
  );
};

export default memo(TagPicker);
