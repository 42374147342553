import { Button, Table, Popconfirm, Card, Flex, theme, Divider } from "antd";
import AddCustomFieldModal from "components/CustomField/AddOrEditCustomFieldModal";
import { useEffect, useState } from "react";
import CustomFieldService from "services/custom_field";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

const customFieldService = new CustomFieldService();

function CustomFieldPage() {
    const { useToken } = theme;
    const { token } = useToken();

    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [listFieldCustom, setListFieldCustom] = useState([]);
    const [editField, setEditField] = useState(null);

    useEffect(() => {
        fetchCustomField();
    }, []);

    const fetchCustomField = async () => {
        setLoading(true);

        const result = await customFieldService.get();
        setListFieldCustom(result);

        setLoading(false);
    };

    const onChange = () => {
        fetchCustomField();
        setShowModal(false);
        setEditField(null);
    };

    const confirm = async (data) => {
        const result = await customFieldService.remove(data.id);

        if (result) {
            fetchCustomField();
        }
    };

    const cancel = (e) => {
        console.log(e);
    };

    const handleEdit = (field) => {
        setEditField(field);
        setShowModal(true);
    };

    const columns = [
        {
            title: "NAME",
            dataIndex: "name",
        },
        {
            title: "FIELD TYPE",
            dataIndex: "field_type",
        },
        // {
        //   title: "DEFAULT VALUE",
        //   dataIndex: "default_value",
        // },
        // {
        //   title: "PLACEHOLDER",
        //   dataIndex: "placeholder",
        // },
        // {
        //     title: "APPLICABLE TO",
        //     dataIndex: "applicable",
        //     render: (text, record, index) => {
        //         const applicableTo = [];

        //         if (text.item) {
        //             applicableTo.push("item");
        //         }
        //         if (text.folder) {
        //             applicableTo.push("folder");
        //         }

        //         return <div>{applicableTo.join(" & ")}</div>;
        //     },
        // },
        {
            title: "ACTIONS",
            dataIndex: "action",
            render: (text, record, index) => (
                <>
                    <div>
                        <EditOutlined style={{ cursor: "pointer", marginRight: token.margin }} onClick={() => handleEdit(record)} />
                        <Popconfirm
                            title="Delete the custom field"
                            description="Are you sure to delete this custom field?"
                            onConfirm={() => confirm(record)}
                            onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <DeleteOutlined style={{ cursor: "pointer" }} />
                        </Popconfirm>
                    </div>
                </>
            ),
        },
    ];

    return (
        <>
            <AddCustomFieldModal
                visible={showModal}
                onClose={() => {
                    setShowModal(false);
                    setEditField(null);
                }}
                onChange={onChange}
                value={editField}
            />

            <Flex justify="space-between" align="center">
                <h1>Custom Field</h1>

                <div>
                    <Button type="primary" onClick={() => setShowModal(true)}>
                        ADD CUSTOM FIELD
                    </Button>
                </div>
            </Flex>

            <Divider />

            <Card>
                <Table loading={loading} dataSource={listFieldCustom} columns={columns} />
            </Card>
        </>
    );
}

export default CustomFieldPage;
