import BaseService from "services/base";
import _ from "lodash";

class DashBoardService extends BaseService {
    get = async () => {
        var data = await this._api.get("dashboard/get");
        return data;
    };


}
export default DashBoardService;
