import React, { useState } from "react";
import { DownOutlined, UserOutlined } from "@ant-design/icons";
import { Breadcrumb, Button, Layout, Menu, theme, Avatar, Flex, Dropdown, Space, Divider } from "antd";
import { BrowserRouter, useNavigate } from "react-router-dom";

import _ from "lodash";
import { useAppLayoutContext } from "./index";
import { useAuth } from "context/auth";
import { CustomButtonLink } from "components/Buttons";

const Account = ({ menuCollapsed }) => {
    const { token } = theme.useToken();

    const { user, logout } = useAuth();

    const navigate = useNavigate();

    const items = [
        {
            label: "My Profile",
            key: "my_profile",
        },
        {
            label: "Logout",
            key: "logout",
        },
    ];

    const menuClick = ({ item, key, keyPath, domEvent }) => {
        console.log({ item, key, keyPath });

        switch (key) {
            case "my_profile":
                navigate("/profile");
                break;
            case "logout":
                logout();
                break;
            default:
                break;
        }
    };

    return (
        <>
            {menuCollapsed ? (
                <Flex justify="center" style={{ margin: "16px 12px" }}>
                    <Dropdown
                        menu={{
                            items,
                            onClick: menuClick,
                        }}
                    >
                        <Avatar size={32} src={user.avatar} icon={<UserOutlined />} style={{ cursor: "pointer" }} />
                    </Dropdown>
                </Flex>
            ) : (
                <Flex justify="space-between" align="center" style={{ margin: "16px 12px" }}>
                    <Flex align="center" gap={8}>
                        <Avatar size={32} src={user.avatar} icon={<UserOutlined />} />

                        <span
                            style={{
                                color: token.colorPrimary,
                                fontSize: "16px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                maxWidth: "120px",
                            }}
                        >{`${user.first_name} ${user.last_name}`}</span>
                    </Flex>

                    <Dropdown
                        menu={{
                            items,
                            onClick: menuClick,
                        }}
                        placement="bottomRight"
                    >
                        <CustomButtonLink>
                            <DownOutlined />
                        </CustomButtonLink>
                    </Dropdown>
                </Flex>
            )}

            <Divider style={{ margin: 0 }} />
        </>
    );
};

export default Account;
