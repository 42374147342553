import { ColorPicker, Flex, Form, Input, Modal, Select, Space, Typography } from "antd";
import { EyeTwoTone, EyeInvisibleOutlined } from "@ant-design/icons";
import React, { useState, useMemo, useEffect, useContext } from "react";
import { useNotification } from "context/notification";
import { useLoading } from "context/loading";
import UserService from "services/user";
import { useAuth } from "context/auth";
const { Paragraph, Text } = Typography;

const userService = new UserService();

function LginUrlModal({ visible, onClose }) {


    var { user } = useAuth();
    console.log("user", user)


    return (
        <Modal
            title={<p style={{ fontSize: "18px", textAlign: "center" }}>Your Workspace ID</p>}
            open={visible}

            onCancel={onClose}
            maskClosable={false}
            footer={null}
        >
            <Space direction="vertical">


            <div>
                Share your Workspace ID   with your team for access to workspace's account
            </div>
            <Paragraph >Workspace ID : {user.work_space.w_id}</Paragraph>
            </Space>
        </Modal>
    );
}

export default LginUrlModal;
