import { useEffect, useState } from "react";
import { Divider, Flex, Collapse, Button, Form, Switch, InputNumber } from "antd";
import FilterFolder from "./FilterFolders";
import { useForm, useWatch } from "antd/es/form/Form";

function FilterNumber({ form, filterType }) {
    const checkedExact = useWatch(`${filterType}_checked_exact`, form);

    return (
        <>
            {checkedExact === true ? (
                <Form.Item name={`${filterType}_filter_exact`}>
                    <InputNumber placeholder="Quantity" style={{ width: "100%" }} />
                </Form.Item>
            ) : (
                <Flex gap={16}>
                    <Form.Item name={`${filterType}_filter_from`}>
                        <InputNumber placeholder="From" style={{ width: "100%" }} />
                    </Form.Item>
                    <Form.Item name={`${filterType}_filter_to`}>
                        <InputNumber placeholder="To" style={{ width: "100%" }} />
                    </Form.Item>
                </Flex>
            )}

            <Form.Item name={`${filterType}_checked_exact`} label="Exact value" style={{ marginBottom: "0" }} initialValue={false}>
                <Switch />
            </Form.Item>
        </>
    );
}

export default FilterNumber;
